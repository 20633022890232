export default function getReducerInfo(app) {
  switch (app) {
    case 'hac':
      return {
        name: 'results',
        include: ['inputs', 'manualInputs', 'overrides'],
      };
    case 'rc':
      return {
        name: 'refinanceCalculator',
        include: ['inputs', 'manualInputs', 'overrides'],
      };
    case 'mc':
    case 'ac':
    case 'mpc':
      return {
        name: 'mortgageCalculator',
        include: ['inputs', 'manualInputs', 'overrides'],
        exclude: ['manualInputs.interestRate'],
      };
    case 'hifc':
      return {
        name: 'hifcCalculator',
        include: ['inputs', 'manualInputs', 'overrides'],
      };
    case 'col':
      return {
        name: 'costOfLivingCalculator',
      };
    case 'rvb':
      return {
        name: 'rentvsbuyCalculator',
      };
    default:
      return null;
  }
}

// Utils
// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import { assign, assignInWith, cloneDeep, get, isEmpty } from 'lodash';

import { fromHomeCalculatorProfile } from '@nerdwallet/mortgage-utils/home-calculator-profile';

import defaultInputs from '../components/refinance-calculator/default-inputs';
import inputLibrary from '../components/refinance-calculator/input-library';
import {
  INPUT_CHANGE,
  OVERRIDES_CHANGE,
  OVERRIDES_CALCULATION,
  POST_ONBOARDING_CALCULATE,
  RESOLVE_CALCULATIONS,
  SAVE_HOME_CALCULATOR_ADDRESS,
  SIGN_IN,
  UPDATE_DEFAULT_CITY,
  UPDATE_DEFAULT_RATE,
} from '../lib/actions';
import { sanitizeInputs } from '../components/inputs/sanitize';
import calculateRefinance from '../lib/refi-calculator';

// Actions
import { SAVE_QUERY } from '../lib/refinance-actions';

// Configs and Data
/**
 * The initial state of the home affordability calculator reducer state.
 *
 */
export function getInitialState() {
  return {
    inputs: assign({}, defaultInputs),
    manualInputs: {},
    overrides: {},
    outputs: {},
    resolvedInputs: {},
    emailInfo: {},
  };
}

function resolveCalculations(newState, calculatorInputs) {
  const results = calculateRefinance(calculatorInputs);

  assign(newState.outputs, results);
  assign(
    newState.resolvedInputs.closingCosts,
    results.details.refinanceLoan.closingCosts
  );
}

export default (state = getInitialState(), action = {}) => {
  let newState = state;

  // Is the state an empty object ? The reducer is probably being added.
  if (isEmpty(state)) {
    return getInitialState();
  }

  switch (action.type) {
    case SIGN_IN: {
      newState = cloneDeep(state);
      const profile =
        get(action, 'payload.identity.profiles.home_calculator_profile') ||
        get(
          action,
          'payload.identity.visitorData.profiles.home_calculator_profile'
        );

      const formattedProfile = fromHomeCalculatorProfile(profile);
      const sanitizedInputs = sanitizeInputs(inputLibrary, formattedProfile);

      assign(formattedProfile, sanitizedInputs);

      const useDefaultIfNull = (defaultValue, profileValue) =>
        profileValue === null ? defaultValue : profileValue;

      assignInWith(newState.inputs, formattedProfile, useDefaultIfNull);
      // Leave manual inputs blank so they use placeholder
      assign(newState.manualInputs, sanitizedInputs);
      if (formattedProfile.refinanceAddress) {
        newState.manualInputs.refinanceAddress =
          formattedProfile.refinanceAddress;
      }

      break;
    }

    case POST_ONBOARDING_CALCULATE:
    // Fall through
    case INPUT_CHANGE: {
      newState = cloneDeep(state);

      if (!(isEmpty(action.manualInputs) && isEmpty(action.inputs))) {
        if (action.manualInputs.city) {
          newState.manualInputs.refinanceAddress =
            newState.manualInputs.refinanceAddress ?? action.manualInputs;
        }
        assign(newState.manualInputs, action.manualInputs, action.inputs);
        assign(newState.inputs, newState.manualInputs);
      }

      if (action.type === POST_ONBOARDING_CALCULATE) {
        const overrides = newState.overrides;
        resolveCalculations(
          newState,
          assign({}, newState.inputs, { ...overrides })
        );
        newState.completedOnboarding = action.isOnboardingCompleted;
      }
      // TODO stop overriding this or pull it from lenders config
      newState.inputs.loanPurpose = 'refinance';
      break;
    }

    case RESOLVE_CALCULATIONS: {
      newState = cloneDeep(state);
      const overrides = newState.overrides;
      resolveCalculations(
        newState,
        assign({}, newState.inputs, { ...overrides })
      );

      break;
    }

    case UPDATE_DEFAULT_CITY: {
      const { city } = action;

      newState = cloneDeep(state);
      assign(newState.inputs.refinanceAddress, { city, state: action.state });

      break;
    }

    case UPDATE_DEFAULT_RATE: {
      if (action.annualInterestRates.fixed_30) {
        newState = cloneDeep(state);

        const { inputs } = newState;
        // Don't overwrite whatever the user typed in before
        if (!newState.manualInputs.refinanceInterestRate)
          inputs.refinanceInterestRate =
            action.annualInterestRates.fixed_30 / 100;
        inputs.annualInterestRates = action.annualInterestRates;
      }

      break;
    }

    case OVERRIDES_CHANGE: {
      newState = cloneDeep(state);
      const newOverrides = newState.overrides;

      assign(newState.overrides, action.overrides);

      resolveCalculations(
        newState,
        assign({}, newState.inputs, { ...newOverrides })
      );
      break;
    }

    case OVERRIDES_CALCULATION: {
      newState = cloneDeep(state);
      const newOverrides = newState.overrides;

      resolveCalculations(
        newState,
        assign({}, newState.inputs, { ...newOverrides })
      );
      break;
    }

    case SAVE_HOME_CALCULATOR_ADDRESS:
      newState = cloneDeep(state);
      if (!isEmpty(action.address)) {
        assign(newState.inputs.refinanceAddress, action.address);
      }
      break;

    case SAVE_QUERY: {
      newState = cloneDeep(state);
      newState.query = action.query;
      break;
    }

    default:
  }

  // Return the new state.
  return newState;
};

import { defaultCreditScoreRange } from '@nerdwallet/mortgage-utils/rate-adjustment';

const inputs = {
  annualInterestRate: 0.04125,
  baseAnnualInterestRate: 0.04125,
  creditScoreRangeValue: defaultCreditScoreRange.value,
  monthlyDebt: 500,
  expenses: 0,
  income: 100000,
  loanTerm: 30,
  purchaseAddress: {
    city: 'San Francisco',
    state: 'CA',
  },
  savings: 200,
  useCreditScoreForInterestRate: true,
  firstVaLoan: true,
};

export default inputs;

const inputs = {
  creditScore: 0,
  homeImprovementCost: 0,
  mortgageAmount: 0,
  homeValue: 0,
  isRateLower: 0,
  revolvingLineOfCredit: 0,
  loanPayOffTime: 0,
};
export default inputs;

export const rentalPrice = {
  name: 'rentalPrice',
  type: 'integer',
  format: 'currency',
  minimum: 1,
  maximum: 10000000,
};

export const monthlyRent = {
  name: 'monthlyRent',
  type: 'integer',
  format: 'currency',
  minimum: 1,
  maximum: 10000000,
};

export const propertyTaxRate = {
  name: 'propertyTaxRate',
  type: 'float',
  format: 'percentage',
  minimum: 0,
  maximum: 1,
};

export const HOADues = {
  name: 'HOADues',
  type: 'integer',
  format: 'currency',
  minimum: 0,
  maximum: 10000000,
};

export const monthlyHoa = {
  name: 'monthlyHoa',
  type: 'integer',
  format: 'currency',
  minimum: 0,
  maximum: 10000000,
};

export const homeInsurance = {
  name: 'homeInsurance',
  type: 'float',
  format: 'percentage',
  minimum: 0,
  maximum: 1,
};

export const maintainanceFees = {
  name: 'maintainanceFees',
  type: 'float',
  format: 'percentage',
  minimum: 0,
  maximum: 1,
};

export const renovationFees = {
  name: 'renovationFees',
  type: 'float',
  format: 'percentage',
  minimum: 0,
  maximum: 1,
};

export const incomeTaxRate = {
  name: 'incomeTaxRate',
  type: 'float',
  format: 'percentage',
  minimum: 0,
  maximum: 1,
};

export const rentersInsurance = {
  name: 'rentersInsurance',
  type: 'float',
  format: 'percentage',
  minimum: 0,
  maximum: 1,
};

export const buyingCosts = {
  name: 'buyingCosts',
  type: 'float',
  format: 'percentage',
  minimum: 0,
  maximum: 1,
};

export const sellingCosts = {
  name: 'sellingCosts',
  type: 'float',
  format: 'percentage',
  minimum: 0,
  maximum: 1,
};

export const rentSecurityDeposit = {
  name: 'rentSecurityDeposit',
  type: 'integer',
  format: 'currency',
  minimum: 0,
  maximum: 10000000,
};

export const homeAppreciation = {
  name: 'homeAppreciation',
  type: 'float',
  format: 'percentage',
  minimum: 0,
  maximum: 1,
};

export const homeValueAppreciation = {
  name: 'homeValueAppreciation',
  type: 'float',
  format: 'percentage',
  minimum: 0,
  maximum: 1,
};

export const rentAppreciation = {
  name: 'rentAppreciation',
  type: 'float',
  format: 'percentage',
  minimum: 0,
  maximum: 1,
};

export const inflationRate = {
  name: 'inflationRate',
  type: 'float',
  format: 'percentage',
  minimum: 0,
  maximum: 1,
};

export const discountRate = {
  name: 'discountRate',
  type: 'float',
  format: 'percentage',
  minimum: 0,
  maximum: 1,
};

export const generalInflation = {
  name: 'generalInflation',
  type: 'float',
  format: 'percentage',
  minimum: 0,
  maximum: 1,
};

export const annualReturnCash = {
  name: 'annualReturnCash',
  type: 'float',
  format: 'percentage',
  minimum: 0,
  maximum: 1,
};

export const taxStatus = {
  name: 'taxStatus',
  type: 'array',
  whitelist: ['individual', 'joint'],
};

export const taxFilingStatus = {
  name: 'taxFilingStatus',
  type: 'string',
  whitelist: ['individual', 'joint'],
};

export const householdIncome = {
  name: 'householdIncome',
  type: 'integer',
  format: 'currency',
  minimum: 0,
  maximum: 10000000,
};

export const income = {
  name: 'income',
  type: 'integer',
  format: 'currency',
  minimum: 0,
  maximum: 10000000,
};

export const capitalGainsTaxRate = {
  name: 'capitalGainsTaxRate',
  type: 'float',
  format: 'percentage',
  minimum: 0,
  maximum: 1,
};

export const howLongStay = {
  name: 'howLongStay',
  type: 'integer',
  minimum: 0,
  maximum: 30,
};

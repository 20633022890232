// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import { get } from 'lodash';

// Reducer
import identityReducer from '@nerdwallet/js-auth-tools/reducers';

// Actions
import { SIGN_IN } from '../lib/actions';

export default (state = {}, action) => {
  if (action.type === SIGN_IN) {
    return get(action, 'payload.identity');
  }

  return identityReducer(state, action);
};

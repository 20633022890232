// Utils
import { setAppName } from './actions';
import { addAdditionalReducers } from './updateStore';

// Actions

export default function addRouteBasedReducers(store, reducers, app) {
  return () => {
    store.dispatch(setAppName(app));

    [].concat(Object.keys(reducers)).forEach((name) => {
      // Have we already added this reducer?
      if (!store.additionalReducers[name]) {
        // If not, lazy load this reducer.
        addAdditionalReducers(store, {
          [name]: reducers[name],
        });
      }

      // Else - don't reset it.
    });
  };
}

// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import { get } from 'lodash';
import nets from 'nets';
import qs from 'qs';

const api = `${process.env.HOMEOWNERSHIP_BASE_URL}/v1/geocoding/zip`;

const getStateFromZipCode = (zipCode: string): any => {
  return new Promise((resolve, reject) =>
    nets(
      {
        url: `${api}?${qs.stringify({
          zipCode,
          client_id: process.env.CLIENT_ID,
        })}`,
        json: true,
      },
      (err, res, body) => {
        if (err || get(res, 'statusCode') >= 400) {
          reject(err);
          return;
        }

        resolve(body.data[0] ?? {});
      }
    )
  );
};

export default getStateFromZipCode;

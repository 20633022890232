// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import { get, isEmpty } from 'lodash';

import { requestIdentity } from '@nerdwallet/js-auth-tools/dist/actions/auth';
import { getLogger } from '@nerdwallet/logging';

// Actions
import transformInputs from '../config/data/adapters';
// eslint-disable-next-line import/no-cycle
import { hasValidationsToFix } from '../reducers/validations';

import fetchLastMortgageRates from './actions/fetchLastMortgageRates';
// eslint-disable-next-line import/no-cycle
import getLocation from './actions/get-location';
import inputChange from './actions/inputChange';
import saveToProfile from './actions/save-to-profile';
// eslint-disable-next-line import/no-cycle
import getHifcLenders from './get-hifc-lenders';

// Utils
import { validate } from './validation/validate';
import getSavedProfile from './profiles/get-saved-profile';

// Search for "actions" to look for all actions related log messages
const rootLogger = getLogger('actions');

export { inputChange };

export const BOOTSTRAP_STATE_DATA = 'BOOTSTRAP_STATE_DATA';
export const BROWSER_MATCH_MEDIA = 'BROWSER_MATCH_MEDIA';
export const BROWSER_WINDOW_RESIZE = 'BROWSER_WINDOW_RESIZE';
export const CLEAR_INPUT_ERROR = 'CLEAR_INPUT_ERROR';
export const FETCH_CITY_DATA = 'FETCH_CITY_DATA';
export const FETCH_CITY_DATA_ERROR = 'FETCH_CITY_DATA_ERROR';
export const FETCH_CITY_DATA_SUCCESS = 'FETCH_CITY_DATA_SUCCESS';
export const FETCH_FEATURE_FLAGS_SUCCESS = 'FETCH_FEATURE_FLAGS_SUCCESS';
export const FILTER_HIFC_LENDERS = 'FILTER_HIFC_LENDERS';
export const FILTER_LENDERS = 'FILTER_LENDERS';
export const HIFC_RESULTS_CHANGE = 'HIFC_RESULTS_CHANGE';
export const INPUT_CHANGE = 'INPUT_CHANGE';
export const INPUT_ERROR = 'INPUT_ERROR';
export const INPUT_VALIDATION = 'INPUT_VALIDATION';
export const LENDERS_DISABLE_REQUEST = 'LENDERS_DISABLE_REQUEST';
export const LOAN_TERM_CHANGE = 'LOAN_TERM_CHANGE';
export const MC_SET_STATE_LIST = 'MC_SET_STATE_LIST';
export const NEW_REDUCERS_ADDED = 'NEW_REDUCERS_ADDED';
export const ONBOARDING_BYPASS = 'ONBOARDING_BYPASS';
export const OVERRIDES_CHANGE = 'OVERRIDES_CHANGE';
export const OVERRIDES_CALCULATION = 'OVERRIDES_CALCULATION';
export const POST_ONBOARDING_CALCULATE = 'POST_ONBOARDING_CALCULATE';
export const RESET_CALCULATIONS = 'RESET_CALCULATIONS';
export const RESET_VALIDATIONS = 'RESET_VALIDATIONS';
export const RESOLVE_CALCULATIONS = 'RESOLVE_CALCULATIONS';
export const SAVE_HOME_CALCULATOR_ADDRESS = 'SAVE_HOME_CALCULATOR_ADDRESS';
export const SAVE_HOME_CALCULATOR_PROFILE = 'SAVE_HOME_CALCULATOR_PROFILE';
export const SET_APP_NAME = 'SET_APP_NAME';
export const SIGN_IN = 'SIGN_IN';
export const TOGGLE_LOADING = 'TOGGLE_LOADING';
export const UPDATE_DEFAULT_CITY = 'UPDATE_DEFAULT_CITY';
export const UPDATE_DEFAULT_RATE = 'UPDATE_DEFAULT_RATE';
export const UPDATE_HOME_CALCULATOR_PROFILE = 'UPDATE_HOME_CALCULATOR_PROFILE';
export const UPDATE_LOAN_PURPOSE = 'UPDATE_LOAN_PURPOSE';
export const VALIDATE_INPUTS = 'VALIDATE_INPUTS';
export const ZOOM = 'ZOOM';

export const zoomActions = {
  ZOOM_IN: 'ZOOM_IN',
  ZOOM_OUT: 'ZOOM_OUT',
};

export const errorActions = {
  SET_INPUT_ERROR: 'SET_INPUT_ERROR',
  CLEAR_INPUT_ERROR: 'CLEAR_INPUT_ERROR',
};

export const loadingStates = {
  SHOW_LOADING: 'SHOW_LOADING',
  HIDE_LOADING: 'HIDE_LOADING',
};

export const preloadIdentity = (identity) => (dispatch, getState) => {
  dispatch({
    type: SIGN_IN,
    payload: { identity },
  });
  const data = getSavedProfile(getState(), identity);

  dispatch({
    type: RESOLVE_CALCULATIONS,
    data,
  });
};

export const hifcResultsChange =
  ({ results }) =>
  (dispatch) => {
    dispatch({
      type: HIFC_RESULTS_CHANGE,
      results,
    });

    dispatch(saveToProfile());
    dispatch(getHifcLenders());
  };

export const signIn =
  ({ isSignedIn } = {}) =>
  (dispatch, getState) => {
    if (isSignedIn) {
      dispatch(requestIdentity()).then(({ identity }) => {
        dispatch(
          preloadIdentity({
            isSignedIn,
            ...identity,
          })
        );

        if (!getSavedProfile(getState(), identity)) {
          dispatch(saveToProfile());
        }
      });
    }
  };

export const updateLoanPurpose = (loanPurpose) => ({
  type: UPDATE_LOAN_PURPOSE,
  loanPurpose,
});

export function bootstrapAppWithPromises({ promises, callback }) {
  return (dispatch, getState) => {
    const initApp = () => {
      const state = getState();
      const { annualInterestRates } = state.rates;

      dispatch({
        type: UPDATE_DEFAULT_RATE,
        annualInterestRates,
      });

      dispatch(preloadIdentity(state.identity, state.app));

      // Dispatch a input change calculation
      dispatch({
        type: INPUT_CHANGE,
      });

      callback();
    };

    if (promises) {
      Promise.all(promises)
        .then(initApp)
        .catch((err) => {
          // search for "actions > bootstrapAppWithPromises" to zero in on just this function
          const logger = getLogger('bootstrapAppWithPromises', rootLogger);
          logger.error('Error in bootstrap', err);

          callback();
        });
    } else {
      initApp();
    }
  };
}

export const overridesChange = (data, inputIds) => (dispatch, getState) => {
  dispatch({
    type: OVERRIDES_CHANGE,
    ...data,
  });
  // todo see if there's a more graceful way to do this
  const transformedData = transformInputs({
    data,
    ...data.overrides,
    ...get(data, 'overrides.monthlyPayments', {}),
  });

  validate(inputIds, transformedData).then(({ valid, validations }) => {
    dispatch({
      type: INPUT_VALIDATION,
      valid,
      validations,
    });
    // Don't save and filter if there are any outstanding validations to fix
    const state = getState();

    const needsValidationsFixed = hasValidationsToFix(state);

    const allValid = !needsValidationsFixed && valid;

    if (allValid) {
      dispatch(saveToProfile());

      dispatch({
        type: OVERRIDES_CALCULATION,
        ...data,
      });
    }
    return valid;
  });
};

export const onboardingBypass = () => ({
  type: ONBOARDING_BYPASS,
});

export function bootstrapApp(data, promises = []) {
  return (dispatch, getState) => {
    const { inputs } = getState()[data.name];
    const updateInputs = get(data, 'update.inputs');
    const updateOverrides = get(data, 'update.overrides');

    if (!isEmpty(updateInputs)) {
      dispatch(inputChange({ inputs: updateInputs }));
    }

    if (!isEmpty(updateOverrides)) {
      dispatch(overridesChange({ overrides: updateOverrides }));
    }

    dispatch(
      bootstrapAppWithPromises({
        promises: [
          new Promise((callback) => {
            dispatch(fetchLastMortgageRates({ callback }));
          }),
          dispatch(getLocation(data, inputs)),
          ...promises,
        ],
        ...data,
      })
    );
  };
}

export function postOnboardingCalculate(isOnboardingCompleted = false) {
  return {
    type: POST_ONBOARDING_CALCULATE,
    isOnboardingCompleted,
  };
}

export function resetCalculations() {
  return {
    type: RESET_CALCULATIONS,
  };
}

export function resetValidations() {
  return {
    type: RESET_VALIDATIONS,
  };
}

export function setAppName(app) {
  return {
    type: SET_APP_NAME,
    app,
  };
}

export function zoom(zoomAction) {
  return {
    type: ZOOM,
    zoomAction,
  };
}

export function toggleLoading(loadingState) {
  return {
    type: TOGGLE_LOADING,
    loadingState,
  };
}

export const setStateList = (stateList) => ({
  type: MC_SET_STATE_LIST,
  stateList,
});

export const resolveCalculations = (data) => ({
  type: RESOLVE_CALCULATIONS,
  data,
});

export const saveHomeCalculatorProfile = (data) => ({
  type: SAVE_HOME_CALCULATOR_PROFILE,
  data,
});

export const saveHomeCalculatorAddress = (address, addressType) => ({
  type: SAVE_HOME_CALCULATOR_ADDRESS,
  address,
  addressType,
});

// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import range from 'lodash/range';

import {
  LOAN_TYPE,
  LOAN_TYPE_CONVENTIONAL,
  LOAN_TYPE_FHA,
  LOAN_TYPE_VA,
  LOAN_TYPE_USDA,
  USER_GOALS,
  USER_GOAL_LOWEST_MONTHLY_PAYMENT,
  USER_GOAL_LOWEST_INTEREST_RATE,
} from './data/constants';

import {
  validateOriginationMonth,
  validateOriginationDate,
} from '../../lib/validation';

export const annualInterestRate = {
  name: 'annualInterestRate',
  type: 'float',
  format: 'percentage',
  minimum: 0.01,
  maximum: 1,
};

export const loanAmount = {
  name: 'loanAmount',
  type: 'integer',
  format: 'currency',
  minimum: 1,
};

export const loanType = {
  name: 'loanType',
  type: 'string',
  whitelist: LOAN_TYPE,
  viewConfig: {
    viewType: 'custom',
    componentName: 'inputGroup',
    type: 'radio',
    items: [
      {
        label: 'Conventional',
        value: LOAN_TYPE_CONVENTIONAL,
      },
      {
        label: 'VA',
        value: LOAN_TYPE_VA,
      },
      {
        label: 'FHA',
        value: LOAN_TYPE_FHA,
      },
      {
        label: 'USDA',
        value: LOAN_TYPE_USDA,
      },
    ],
  },
};

export const monthlyPayment = {
  name: 'monthlyPayment',
  type: 'integer',
  format: 'currency',
  minimum: 1,
};

export const originationMonth = {
  name: 'originationMonth',
  type: 'integer',
  whitelist: range(1, 13),
  viewConfig: {
    viewType: 'select',
    items: [
      {
        value: null,
        label: 'Select month',
      },
      {
        value: 1,
        label: 'January',
      },
      {
        value: 2,
        label: 'February',
      },
      {
        value: 3,
        label: 'March',
      },
      {
        value: 4,
        label: 'April',
      },
      {
        value: 5,
        label: 'May',
      },
      {
        value: 6,
        label: 'June',
      },
      {
        value: 7,
        label: 'July',
      },
      {
        value: 8,
        label: 'August',
      },
      {
        value: 9,
        label: 'September',
      },
      {
        value: 10,
        label: 'October',
      },
      {
        value: 11,
        label: 'November',
      },
      {
        value: 12,
        label: 'December',
      },
    ],
  },
  validators: [validateOriginationMonth],
};

export const originationYear = {
  name: 'originationYear',
  type: 'integer',
  minimum: 1980, // FIXME -30?
  maximum: new Date().getFullYear(),
  format: 'number',
};

export const originationDate = {
  name: 'originationDate',
  type: 'string',
  validators: [validateOriginationDate],
  viewConfig: {
    viewType: 'custom',
    componentName: 'formattedDateInput',
  },
};

export const userGoal = {
  name: 'userGoal',
  type: 'string',
  whitelist: USER_GOALS,
  viewConfig: {
    viewType: 'radio',
    inline: false,
    items: [
      {
        label: 'Lower monthly payments',
        value: USER_GOAL_LOWEST_MONTHLY_PAYMENT,
      },
      {
        label: 'Lower total cost of mortgage',
        value: USER_GOAL_LOWEST_INTEREST_RATE,
      },
    ],
  },
};

export const closingCosts = {
  name: 'closingCosts',
  type: 'integer',
  minimum: 1,
  maximum: 10000000,
};

export const newMortgageClosingCosts = {
  name: 'newMortgageClosingCosts',
  type: 'integer',
  minimum: 1,
  maximum: 10000000,
};

// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import { cloneDeep, filter, remove } from 'lodash';

import { ON_ERROR, errorActions } from '../lib/errors';

function matchError(baseError, errorToCompare) {
  return (
    baseError.errorType === errorToCompare.errorType &&
    baseError.source === errorToCompare.source
  );
}

function hasError(state, error) {
  return (
    filter(state, (errorEntry) => matchError(errorEntry, error)).length > 0
  );
}

function clearError(state, error) {
  const newState = cloneDeep(state);
  const removed = remove(newState, (errorEntry) =>
    matchError(errorEntry, error)
  );
  if (removed.length > 0) {
    return newState;
  }

  return state;
}

export default (state = [], action = {}) => {
  let newState = state;

  switch (action.type) {
    case ON_ERROR: {
      const { error } = action;

      switch (action.action) {
        case errorActions.SET: {
          if (hasError(newState, error)) {
            newState = clearError(newState, error);
          } else {
            newState = cloneDeep(newState);
          }

          newState.push(error);
          break;
        }

        case errorActions.CLEAR:
          newState = clearError(newState, error);
          break;

        default:
          break;
      }
      break;
    }

    default:
      break;
  }

  // Return the new state.
  return newState;
};

// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import { get } from 'lodash';

import { getLogger } from '@nerdwallet/logging';

import { fetch, HOMEOWNERSHIP_URL } from '../fetch';

export const UPDATE_RESOLVED_ZIP = 'UPDATE_RESOLVED_ZIP';

// Search for "actions" to look for all actions related log messages
const rootLogger = getLogger('actions');

export default ({ city, state, callback }) =>
  (dispatch) => {
    fetch(
      '/v1/geocoding/reverse',
      null,
      { city, state },
      'GET',
      (result) => {
        const data = get(result, 'data');

        if (data && data.zipCode) {
          dispatch({
            type: UPDATE_RESOLVED_ZIP,
            zip: data.zipCode,
          });
        }

        if (callback) {
          callback();
        }
      },
      (error) => {
        // search for "actions > fetchZipByCity" to zero in on just this function
        const logger = getLogger('fetchZipByCity', rootLogger);
        logger.error('fetchZipByCity', error);

        if (callback) {
          callback();
        }
      },
      HOMEOWNERSHIP_URL
    );
  };

// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import { get, isFinite, isString } from 'lodash';
import { formatCurrency, formatPercent } from '@nerdwallet/nw-client-lib/num';

import validationResult from './result';

const getValue = (data, { name }) => get(data, name);

function validateEnum(value, whitelist) {
  const valid = whitelist.indexOf(value) !== -1;
  return validationResult({
    valid,
    message: 'Please select an option',
  });
}

export function formatNumber(value, input) {
  const formatType = get(input, 'viewConfig.formatType');
  switch (formatType) {
    case 'currency':
      return formatCurrency(value);
    case 'percentage':
      return formatPercent(value);
    default:
      return value;
  }
}

export function validateBoolean(data, input) {
  const value = getValue(data, input);
  return validateEnum(value, [true, false]);
}

export function validateNumber(data, input) {
  const value = getValue(data, input);

  if (!isFinite(value)) {
    return validationResult({
      valid: false,
      message: 'This field is required',
    });
  }

  const { maximum, minimum } = input;
  if (value < minimum) {
    return validationResult({
      valid: false,
      message: `Value must be greater than ${formatNumber(minimum, input)}`,
    });
  }

  if (value > maximum) {
    return validationResult({
      valid: false,
      message: `Value must be less than ${formatNumber(maximum, input)}`,
    });
  }

  return validationResult({
    valid: true,
  });
}

export function validateString(data, input) {
  const value = getValue(data, input);

  if (!isString(value) || value === '') {
    return validationResult({
      valid: false,
      message: 'Please enter a value',
    });
  }

  if (isFinite(input.maxLength) && value.length > input.maxLength) {
    return validationResult({
      valid: false,
      message: `Value must be less than ${input.maxLength} characters`, // TODO format number?
    });
  }

  if (isFinite(input.minLength) && value.length < input.minLength) {
    return validationResult({
      valid: false,
      message: `Value must be more than ${input.minLength} characters`, // TODO format number?
    });
  }

  return validationResult({
    valid: true,
  });
}

export function validateArray(data, input) {
  const values = get(data, input.name, []);

  if (Array.isArray(values) && values.length === 0 && !input.required) {
    return validationResult({
      valid: true,
    });
  }

  if (Array.isArray(values) && values.length) {
    const invalidValues = values
      .map((value) => ({
        value,
        valid: validateEnum(value, input.whitelist).valid,
      }))
      .filter(({ valid }) => !valid);

    return validationResult({
      valid: invalidValues.length === 0,
      message: `Invalid value ${invalidValues
        .map(({ value }) => value)
        .join(', ')}`,
    });
  }

  return validationResult({
    valid: false,
    message: 'Please select an option',
  });
}

export function validateWhitelist(data, input) {
  return validateEnum(getValue(data, input), input.whitelist);
}

export const validateType = {
  array: validateArray,
  boolean: validateBoolean,
  float: validateNumber,
  integer: validateNumber,
  string: validateString,
};

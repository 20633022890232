// eslint-disable-next-line lodash/import-scope
import { isEmpty, find, get } from 'lodash';

import { getLogger } from '@nerdwallet/logging';

// Utils

// Actions
import { cityChange } from '../cl-actions';

// Constants
const appName = 'cityLife';

// Search for "actions" to look for all actions related log messages
const rootLogger = getLogger('ci');

export default function handleNewCity(
  newParams,
  dispatch,
  getState,
  res,
  callback
) {
  if (res) {
    // We are on the server
    // Since we have the res object, set the meta data
    const { citySlug } = newParams;
    const currentState = get(getState(), appName);
    const cities = get(currentState, 'cities');
    const cityData = find(cities, { slug: citySlug });

    if (!cityData) {
      // search for "ci > handleSlug" to zero in on just this function
      const logger = getLogger('handleSlug', rootLogger);
      logger.warn('404 no cityData', citySlug);
      res.sendStatus(404);
      return;
    }
  }

  if (!isEmpty(newParams)) {
    // received url params, so dispatch city change with this slug
    const { citySlug } = newParams;
    const args = {
      name: appName,
      inputs: {},
      slug: citySlug,
    };
    dispatch(cityChange(args, callback));
  }
}

// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import { get } from 'lodash';

import {
  preloadIdentity,
  readVisitorProfile,
} from '@nerdwallet/js-auth-tools/actions';
import { getLogger } from '@nerdwallet/logging';
import NwApiSDK from '@nerdwallet/nw-api-sdk';
import getFeatureFlag, {
  FEATURE_FLAGS,
} from '../../config/inputs/feature-flags';

const VISITOR_DATA_PREFIX = 'visitor-data-read';

// Search for "actions" to look for all actions related log messages
const rootLogger = getLogger('actions');

// search for "actions > fetchZipByCity" to zero in on just this function
const logger = getLogger('load-identity', rootLogger);

export default (req, res, callback) => (dispatch) => {
  if (get(res, 'locals.data.IdentityStore.currentIdentity')) {
    dispatch(preloadIdentity(res.locals.data, req, res));
    callback();
  } else {
    if (getFeatureFlag(FEATURE_FLAGS.VISITOR_DATA_READ) !== true) {
      logger.info(
        VISITOR_DATA_PREFIX,
        'disabled',
        getFeatureFlag(FEATURE_FLAGS.VISITOR_DATA_READ)
      );
      callback();
      return;
    }

    dispatch(
      readVisitorProfile({
        profileType: NwApiSDK.IDENTITY_PROFILES.HOME_CALCULATOR_PROFILE,
        serverRequest: req,
      })
    )
      .then(() => {
        logger.info(VISITOR_DATA_PREFIX, 'ok');
        callback();
      })
      .catch((e) => {
        logger.warn(VISITOR_DATA_PREFIX, get(e, 'data', '[no data?]'));
        callback();
      });
  }
};

import _ from 'lodash';

import config from '@nerdwallet/app-config';
import initNwApiSdk from '@nerdwallet/nw-api-sdk';

const nwApiSdk = initNwApiSdk({
  applicationID: process.env.APP_ID,
  clientID: process.env.CLIENT_ID,
  cookieDomain: process.env.COOKIE_DOMAIN,
  identifyUrl: process.env.IDENTIFY_URL,
  identityBaseUrl: process.env.IDENTITY_BASE_URL,
  diyServicesBaseUrl: process.env.DIYSERVICES_BASE_URL,
  abUrl: process.env.AB_BASE_URL,
  userDataPlatformBaseUrl: config?.SERVICE_URLS?.USER_DATA,
  shimUDP: _.get(config, 'IDENTITY.SDK.UDP_PROFILE_SHIM.ENABLED'),
  UDPMemberProfilesList: _.get(
    config,
    'IDENTITY.SDK.UDP_PROFILE_SHIM.PROFILES'
  ),
  transunionBaseUrl: config.API.BASE_URLS.TRANSUNION,
  TUProfilesList: config.TRANSUNION.DATA_API_PROFILES,
});

export default nwApiSdk;

export const identityProfilesToInclude = [
  nwApiSdk.IdentitySDK.PROFILES.GLOBAL_PROFILE,
  nwApiSdk.IdentitySDK.PROFILES.HOME_CALCULATOR_PROFILE,
  nwApiSdk.IdentitySDK.PROFILES.TU_CREDIT_REPORT_PROFILE,
];

/*
  Fetches a list of counties and housing characteristics for given state
  @param {String} state state abbreviation i.e. NY
*/
// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import { get } from 'lodash';

import { getLogger } from '@nerdwallet/logging';
import { formatCurrency, formatPercent } from '@nerdwallet/nw-client-lib/num';

import { fetch, HOMEOWNERSHIP_URL } from '../fetch';

// Search for "actions" to look for all actions related log messages
const rootLogger = getLogger('actions');

const transformCounties = (counties) =>
  counties.map((county) => ({
    key: county.name,
    name: county.name,
    medianHousingValue: formatCurrency(county.medianHousingValue),
    propertyTaxRate: formatPercent(county.propertyTaxRate),
  }));

export const SET_HOUSING_CHARACTERISTICS = 'SET_HOUSING_CHARACTERISTICS';

export default ({ state, callback }) =>
  (dispatch) => {
    fetch(
      '/v1/home/housing-characteristics/counties',
      null,
      { state },
      'GET',
      (result) => {
        const data = get(result, 'counties');
        if (data) {
          dispatch({
            type: SET_HOUSING_CHARACTERISTICS,
            counties: transformCounties(data),
          });
        }

        if (callback) {
          callback();
        }
      },
      (error) => {
        // search for "actions > fetchHousingCharacteristics" to zero in on just this function
        const logger = getLogger('fetchHousingCharacteristics', rootLogger);
        logger.error('fetchHousingCharacteristics', error);

        if (callback) {
          callback();
        }
      },
      HOMEOWNERSHIP_URL
    );
  };

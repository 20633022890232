// Utils
// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import { has, get, find, isEmpty } from 'lodash';

// Actions
import { bootstrapAppWithPromises } from '../actions';
import {
  fetchCityData,
  fetchSuggestedCities,
  fetchCities,
  FETCH_CITIES,
  FETCH_COMPARE_CITY_DATA,
  FETCH_SUGGESTED_CITIES,
} from '../actions/fetchColData';

export const CITY_CHANGE = 'CITY_CHANGE';
export const CALCULATE_COMPARE_DATA = 'CALCULATE_COMPARE_DATA';

// eslint-disable-next-line func-names -- directive added automatically by Shepherd migration
export default function (cache, data) {
  return (dispatch) => {
    const promises = [];

    promises.push(
      new Promise((callback) => {
        Promise.all([
          new Promise((resolve) =>
            dispatch(fetchCities({ callback: resolve }))
          ),
          new Promise((resolve) =>
            dispatch(fetchSuggestedCities({ callback: resolve }))
          ),
        ]).then(([cities, suggestedCities]) => {
          dispatch({
            type: FETCH_CITIES,
            data: cities,
          });
          dispatch({
            type: FETCH_SUGGESTED_CITIES,
            data: suggestedCities,
          });
          callback();
        });
      })
    );

    dispatch(
      bootstrapAppWithPromises({
        promises,
        ...data,
      })
    );
  };
}

export function cityChange({ name, inputs, slugs }, callback = () => {}) {
  return (dispatch, getState) => {
    const currentState = get(getState(), name);
    const { cities } = currentState;
    let modifiedInputs = inputs;
    if (slugs) {
      const sourceCityData = find(cities, {
        slug: slugs.sourceSlug,
      });
      const destinationCityData = find(cities, {
        slug: slugs.destinationSlug,
      });
      if (sourceCityData && destinationCityData) {
        modifiedInputs = {
          sourceCity: sourceCityData.id,
          destinationCity: destinationCityData.id,
        };
      }
    }

    // if we have not fetched the data yet, do not do anything
    if (!isEmpty(modifiedInputs) && !isEmpty(cities)) {
      // Dispatch the input change.
      dispatch({
        type: CITY_CHANGE,
        inputs: modifiedInputs,
      });

      const idsToFetch = [];

      if (get(modifiedInputs, 'destinationCity')) {
        // destination city changed and its not blank
        const destinationCity = modifiedInputs.destinationCity;
        const destinationCityData = find(cities, {
          id: destinationCity,
        });

        // the population field would only be there if this city's data is cached
        if (!has(destinationCityData, 'ranking')) {
          idsToFetch.push(destinationCity);
        }
      }
      if (has(modifiedInputs, 'sourceCity')) {
        // source city changed, there is no blank option for this input
        const sourceCity = modifiedInputs.sourceCity;
        const sourceCityData = find(cities, {
          id: sourceCity,
        });

        // the population field would only be there if this city's data is cached
        if (!has(sourceCityData, 'ranking')) {
          idsToFetch.push(sourceCity);
        }
      }

      if (idsToFetch.length) {
        // fetch data because it is not in the cache
        dispatch(
          fetchCityData({
            ids: idsToFetch,
            callback: (data) => {
              dispatch({
                type: FETCH_COMPARE_CITY_DATA,
                data,
              });
              dispatch({
                type: CALCULATE_COMPARE_DATA,
              });
              callback();
            },
          })
        );
      } else {
        // don't need to fetch new data but need to recalculate results
        dispatch({
          type: CALCULATE_COMPARE_DATA,
        });
        callback();
      }
    } else {
      callback();
    }
  };
}

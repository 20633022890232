export const ON_ERROR = 'ON_ERROR';

export const errorActions = {
  SET: 'SET',
  CLEAR: 'CLEAR',
};

export const errorTypes = {
  INPUT: 'INPUT',
};

export const errorSubtypes = {
  CITY_NOT_FOUND: 'CITY_NOT_FOUND',
  INVALID_ZIP_CODE: 'INVALID_ZIP_CODE',
};

function getErrorAction(actionType, error) {
  return {
    type: ON_ERROR,
    action: actionType,
    error,
  };
}

export function setError(error) {
  return getErrorAction(errorActions.SET, error);
}

export function clearError(error) {
  return getErrorAction(errorActions.CLEAR, error);
}

/** @module sanitize */

// eslint-disable-next-line lodash/import-scope
import {
  get,
  includes,
  intersection,
  isMatch,
  isNaN,
  isNil,
  set,
  values,
} from 'lodash';
import Input from './input';

/**
 * Ensure a given input is the correct type.  Particularly useful if data comes from
 * a query string and all incoming values are Strings
 * @private
 * @param  {String} schema integer, float, boolean, string or array
 * @param  {Mixed}  value  the raw value of the input
 * @return {Mixed}         the value of the input converted to the correct type
 */
function sanitizeType(schema, value) {
  switch (schema.type) {
    case 'integer':
      if (value === '') {
        return null;
      }

      return parseInt(Number(value) as unknown as string, 10);

    case 'float':
      if (value === '') {
        return null;
      }

      return Number(value);

    case 'boolean':
      if (typeof value === 'boolean') {
        return value;
      }

      if (typeof value === 'string') {
        return value === 'true';
      }

      if (Number.isFinite(value)) {
        return value !== 0;
      }

      return null;

    case 'array':
      return Array.isArray(value) ? value : [value];

    case 'string':
    default:
      return String(value);
  }
}

/**
 * Check if an input has range boundaries and then conforms its's value to the matching range
 * @private
 * @param  {Input} input  The input
 * @param  {Mixed} value  The value of the input
 * @return {Mixed}        The value bound to the lower end of matching range
 */
function checkRange({ range }, value) {
  if (range) {
    const foundValue = range.find(
      ({ 0: min, 1: max }) =>
        value >= min &&
        value < (typeof max === 'undefined' ? Number.MAX_VALUE : max + 1)
    );

    return foundValue ? foundValue[0] : undefined;
  }

  return value;
}

function setValueIfWhitelisted(input, result, key, rawValue) {
  if (rawValue === undefined) {
    return;
  }

  if (rawValue === null) {
    if (input.nullable) {
      // eslint-disable-next-line no-param-reassign
      result[key] = rawValue;
    }

    return;
  }

  const value = sanitizeType(input, rawValue) as string[];

  if (!Array.isArray(input.whitelist)) {
    const boundValue = checkRange(input, value);
    if (!isNaN(boundValue) && !isNil(boundValue)) {
      set(result, key, boundValue);
    }
  } else if (input.type === 'array') {
    set(result, key, intersection(value, input.whitelist));
  } else if (includes(input.whitelist, value)) {
    set(result, key, value);
  }
}

/**
 * Ensure inputs conform to their definition and drops any input that does not conform
 * @example
 * import { sanitizeInputs } from '../../components/inputs/sanitize';
 * // ...
 * const sanitizedInputs = sanitizeInputs(inputDefinitions, req.body);
 * @function
 * @param  {Array|Object} inputs     Constructed input definitions, each input should be an instance of `Input`
 * @param  {Object} data           Input data
 * @param  {Object} [filters={}]   Further filter inputs. Passed directly to `_.isMatch`
 * @return {Object}       Sanitized `values`
 */
export const sanitizeInputs = (
  inputs: { [key: string]: Input } | Input[],
  data: any,
  filters = {}
): any => {
  return values(inputs).reduce((result, input) => {
    if (input && isMatch(input, filters)) {
      setValueIfWhitelisted(input, result, input.name, get(data, input.name));
    }

    return result;
  }, {});
};

// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import { map } from 'lodash';

import { getLogger } from '@nerdwallet/logging';

import { fetch, HOMEOWNERSHIP_URL } from '../fetch';

export const FETCH_CITIES_BY_ZIP = 'FETCH_CITIES_BY_ZIP';

// Search for "actions" to look for all actions related log messages
const rootLogger = getLogger('actions');

export default ({ zip, callback }) =>
  () => {
    fetch(
      '/v1/geocoding/zip',
      null,
      {
        zipCode: zip,
      },
      'GET',
      (result) => {
        const { data } = result;

        callback(map(data, (item) => `${item.city}, ${item.state}`));
      },
      (error) => {
        // search for "actions > fetchCitiesByZipCode" to zero in on just this function
        const logger = getLogger('fetchCitiesByZipCode', rootLogger);
        logger.error('fetchCitiesByZipCode', JSON.stringify(error), 'zip', zip);
        callback();
      },
      HOMEOWNERSHIP_URL
    );
  };

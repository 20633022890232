// Utils
import _ from 'lodash';

import { fromHomeCalculatorProfile } from '@nerdwallet/mortgage-utils/home-calculator-profile';
import { getAnnualInterestRateForCreditScore } from '@nerdwallet/mortgage-utils/rate-adjustment';

// Local utils
import { sanitizeInputs } from '../components/inputs/sanitize';
import getDefaultInputs from '../components/mortgage-calculator/shared/default-inputs';
import defaultOutputs from '../components/mortgage-calculator/shared/default-outputs';
import inputLibrary from '../components/mortgage-calculator/shared/input-library';
import costModel from '../config/data/costs-model.json';

// Actions
import {
  BOOTSTRAP_STATE_DATA,
  INPUT_CHANGE,
  MC_SET_STATE_LIST,
  OVERRIDES_CHANGE,
  OVERRIDES_CALCULATION,
  RESOLVE_CALCULATIONS,
  SAVE_HOME_CALCULATOR_ADDRESS,
  SIGN_IN,
  UPDATE_DEFAULT_CITY,
  UPDATE_DEFAULT_RATE,
  ZOOM,
  zoomActions,
} from '../lib/actions';
import { SET_HOUSING_CHARACTERISTICS } from '../lib/actions/fetchHousingCharacteristics';
import { UPDATE_RESOLVED_ZIP } from '../lib/actions/fetchZipByCity';

// Configs and Data
import mortgageCalculator from '../lib/mortgage-calculator';

export function getInitialState() {
  return {
    inputs: _.assign({}, getDefaultInputs()),
    manualInputs: {},
    overrides: {},
    outputs: defaultOutputs,
    loanPurpose: 'purchase',
    zoomed: false,
    emailInfo: {},
    stateData: {},
    stateList: [],
  };
}

function calculateMortgage(state) {
  // For backup send 0 here so the calculator can provide us with good defaults.
  const purchaseState = _.get(state, 'inputs.purchaseAddress.state');
  const propertyTaxRate = _.get(
    costModel,
    `${purchaseState}[statePropertyTax]`,
    0
  );

  const overrides = state.overrides;

  return mortgageCalculator(
    _.assign({}, state.inputs, { propertyTaxRate }, { overrides })
  );
}

export default (state = getInitialState(), action = {}) => {
  let newState = state;

  // Is the state an empty object ? The reducer is probably being added.
  if (_.isEmpty(state)) {
    return getInitialState();
  }

  switch (action.type) {
    case SIGN_IN: {
      newState = _.cloneDeep(state);

      const creditScore = _.get(
        action,
        'payload.identity.profiles.tu_credit_report_profile.vantage_score_3'
      );

      const profile =
        _.get(action, 'payload.identity.profiles.home_calculator_profile') ||
        _.get(
          action,
          'payload.identity.visitorData.profiles.home_calculator_profile'
        );

      const formattedProfile = fromHomeCalculatorProfile(profile);
      const sanitizedInputs = sanitizeInputs(inputLibrary, formattedProfile);

      _.assign(formattedProfile, sanitizedInputs);

      const useDefaultIfNull = (defaultValue, profileValue) =>
        profileValue === null ? defaultValue : profileValue;

      _.assignInWith(newState.inputs, formattedProfile, useDefaultIfNull);
      // Leave manual inputs blank so they use placeholder
      _.assign(newState.manualInputs, sanitizedInputs);
      newState.inputs.downPaymentPercent =
        newState.inputs.downPayment !== null &&
        newState.inputs.purchasePrice !== null
          ? _.round(
              newState.inputs.downPayment / newState.inputs.purchasePrice,
              3
            )
          : getDefaultInputs().downPaymentPercent;

      if (formattedProfile.purchaseAddress) {
        newState.manualInputs.purchaseAddress =
          formattedProfile.purchaseAddress;
      }

      if (creditScore) {
        const interestRate = getAnnualInterestRateForCreditScore({
          ...state.inputs,
          creditScore,
        });

        // Clone the current state to build the new state
        newState.inputs.creditScore = creditScore;
        newState.inputs.interestRate = interestRate;

        _.assign(newState.outputs, calculateMortgage(newState));
      }

      break;
    }

    case INPUT_CHANGE: {
      newState = _.cloneDeep(state);

      if (!(_.isEmpty(action.manualInputs) && _.isEmpty(action.inputs))) {
        if (action.manualInputs.city) {
          newState.manualInputs.purchaseAddress =
            newState.manualInputs.purchaseAddress ?? action.manualInputs;
        }
        _.assign(newState.manualInputs, action.manualInputs, action.inputs);
        _.assign(newState.inputs, newState.manualInputs);

        // Calculate downPayment percent if downPayment OR purchase price was modified
        if (
          action.manualInputs.downPayment !== undefined ||
          action.manualInputs.purchasePrice !== undefined
        ) {
          const downPaymentPercent =
            newState.inputs.downPayment / newState.inputs.purchasePrice;
          if (newState.inputs.downPaymentPercent !== undefined) {
            // downPaymentPercent value was already modified
            newState.inputs.downPaymentPercent = _.round(downPaymentPercent, 3);
          } else {
            // only modify the placeholder
            newState.manualInputs.downPaymentPercent = _.round(
              downPaymentPercent,
              3
            );
          }
        } else if (
          // update downPayment if downPaymentPercent was modified
          action.manualInputs.downPaymentPercent !== undefined
        ) {
          const downPayment =
            newState.inputs.purchasePrice * newState.inputs.downPaymentPercent;
          newState.inputs.downPayment = downPayment;
          newState.manualInputs.downPayment = downPayment;
        }
      }

      break;
    }

    case SAVE_HOME_CALCULATOR_ADDRESS:
      newState = _.cloneDeep(state);
      if (!_.isEmpty(action.address)) {
        _.assign(newState.inputs.purchaseAddress, action.address);
      }
      break;

    case RESOLVE_CALCULATIONS: {
      newState = _.cloneDeep(state);
      _.assign(newState.outputs, calculateMortgage(newState));

      break;
    }

    case UPDATE_DEFAULT_RATE:
      // Clone the current state to build the new state
      newState = _.cloneDeep(state);

      if (action.annualInterestRates.fixed_30) {
        const defaultRate = action.annualInterestRates.fixed_30 / 100;

        newState.inputs.annualInterestRate = defaultRate;
        newState.inputs.baseAnnualInterestRate = defaultRate;
        newState.inputs.annualInterestRates = action.annualInterestRates;
      }

      break;

    case OVERRIDES_CHANGE: {
      newState = _.cloneDeep(state);

      _.merge(newState.overrides, action.overrides);
      break;
    }

    case OVERRIDES_CALCULATION: {
      newState = _.cloneDeep(state);

      _.assign(newState.outputs, calculateMortgage(newState));
      break;
    }

    case UPDATE_DEFAULT_CITY: {
      const { city, zipCode } = action;

      newState = _.cloneDeep(state);
      _.assign(newState.inputs.purchaseAddress, { city, state: action.state });
      newState.inputs.zipCode = zipCode;

      break;
    }

    case UPDATE_RESOLVED_ZIP:
      newState = _.cloneDeep(state);
      newState.outputs.zip = action.zip;
      break;

    case ZOOM:
      newState = _.cloneDeep(state);
      newState.zoomed = action.zoomAction === zoomActions.ZOOM_IN;
      break;

    case BOOTSTRAP_STATE_DATA:
      newState = _.cloneDeep(state);
      newState.stateData = action.stateData;
      break;

    case MC_SET_STATE_LIST:
      newState = _.cloneDeep(state);
      newState.stateList = action.stateList;
      break;

    case SET_HOUSING_CHARACTERISTICS:
      newState = _.cloneDeep(state);
      newState.counties = action.counties;
      break;

    default:
    // do nothing
  }

  // Return the new state.
  return newState;
};

// Utils
// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import { get, isEmpty, cloneDeep, findIndex, find } from 'lodash';

import { fromHomeCalculatorProfile } from '@nerdwallet/mortgage-utils/home-calculator-profile';

// Actions
import { SIGN_IN } from '../../lib/actions';
import {
  FETCH_COMPARE_CITY_DATA,
  FETCH_CITIES,
  FETCH_SUGGESTED_CITIES,
} from '../../lib/actions/fetchColData';
import { CITY_CHANGE, CALCULATE_COMPARE_DATA } from '../../lib/col/col-actions';
import calculateCompareData from '../../lib/col/col-calculator';

const DEFAULT_CITY_CODE = '06-41884-800';

/**
 * The initial state of the cost of living calculator reducer state.
 *
 */
function getInitialState() {
  return {
    cities: [],
    suggestedCityData: [],
    inputs: {
      income: 50000,
    },
    compareData: {},
  };
}

export default (state = getInitialState(), action = {}) => {
  let newState = state;

  // Is the state an empty object ? The reducer is probably being added.
  if (isEmpty(state)) {
    return getInitialState();
  }

  switch (action.type) {
    case SIGN_IN: {
      newState = cloneDeep(state);
      const profile =
        get(action, 'payload.identity.profiles.home_calculator_profile') ||
        get(
          action,
          'payload.identity.visitorData.profiles.home_calculator_profile'
        );

      const formattedProfile = fromHomeCalculatorProfile(profile);

      newState.inputs.income = formattedProfile.income || 50000;

      break;
    }

    case FETCH_CITIES:
      newState = cloneDeep(state);
      newState.cities = action.data;
      newState.inputs.sourceCity = get(
        find(action.data, { code: DEFAULT_CITY_CODE }),
        'id'
      );
      break;

    case FETCH_SUGGESTED_CITIES:
      newState = cloneDeep(state);
      newState.suggestedCityData = action.data;

      // also 'cache' this data in the reducer
      get(action, 'data', []).forEach((city) => {
        const cityIndex = findIndex(newState.cities, { id: city.id });
        newState.cities[cityIndex] = city;
      });

      break;

    case FETCH_COMPARE_CITY_DATA:
      newState = cloneDeep(state);

      get(action, 'data', []).forEach((city) => {
        const cityIndex = findIndex(newState.cities, { id: city.id });
        newState.cities[cityIndex] = city;
      });

      break;

    case CALCULATE_COMPARE_DATA: {
      newState = cloneDeep(state);
      const sourceCityData = find(newState.cities, {
        id: newState.inputs.sourceCity,
      });
      const destinationCityData = find(newState.cities, {
        id: newState.inputs.destinationCity,
      });
      if (!isEmpty(destinationCityData)) {
        newState.compareData = calculateCompareData(
          sourceCityData,
          destinationCityData,
          newState.inputs.income
        );
      }
      break;
    }

    case CITY_CHANGE:
      newState = cloneDeep(state);

      newState.inputs = {
        ...newState.inputs,
        ...action.inputs,
      };

      break;

    default:
      break;
  }

  // Return the new state.
  return newState;
};

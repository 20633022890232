/** @module validations */
// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import { get, isFinite, isEmpty, isString, map } from 'lodash';
import { formatCurrency } from '@nerdwallet/nw-client-lib/num';
import { getLogger } from '@nerdwallet/logging';

// Local utils
import isEmailValid from './validators/email';
import isPhoneNumberValid from './validators/phone-number';
import isUUIDValid from './validators/uuid';
import isZipCodeValid from './validators/zip-code';
import getStateFromZipCode from '../get-state-from-zip-code';
import { isPurchase } from '../mapping/helpers';
import validationResult from './result';
import { validateNumber, validateString, validateArray } from './types';

const rootLogger = getLogger('validation');

/**
 * The result of the validation
 * @typedef ValidationResult
 * @property {Boolean} valid
 * @property {String} message  The message
 */

export * from './types';

/**
 * If refinance, validates that cashout does not exceed their equity
 * @function
 * @param  {Object} data
 * @param  {external:home-constants.LOAN_PURPOSE} data.loanPurpose
 * @param  {Number} data.propertyValue
 * @param  {Number} data.mortgageBalance
 * @param  {Input} input
 * @return {ValidationResult}
 */
export const validateCashout = (data, input) => {
  // isCashOutRefinance is only used by MRT so it will not always be supplied when validating cashout
  // for this reason, we check that it is explicitly equal to false and not just undefined/null
  if (isPurchase(data) || get(data, 'isCashOutRefinance') === false) {
    return validationResult({ valid: true });
  }

  const propertyValue = get(data, 'propertyValue');
  const mortgageBalance = get(data, 'mortgageBalance');
  const equity = propertyValue - mortgageBalance;
  const cashout = get(data, 'cashout');

  if (equity <= 0 && cashout > 0) {
    return validationResult({
      valid: false,
      message: `For a cash-out refinance, mortgage balance needs to be no more than ${formatCurrency(
        0.8 * propertyValue
      )}`,
    });
  }

  return validateNumber(data, {
    ...input,
    maximum: equity > 0 ? equity : undefined,
  });
};

/**
 * Validates if autocompleteState is true
 * @function
 * @param  {Object} data
 * @param  {Boolean} data.autocompleteState
 * @return {ValidationResult}
 */
export const validateAutocompleteState = (data) =>
  validationResult({
    valid: data.autocompleteState,
    message: 'Please enter a valid location',
  });

/**
 * Validates that propertyValue is not 0 with a custom message
 * @function
 * @param  {Object} data
 * @param  {Number} data.propertyValue
 * @param  {Input} input
 * @return {ValidationResult}
 */
export const validatePropertyValue = (data, input) =>
  data.propertyValue === 0
    ? validationResult({
        valid: false,
        message: 'This field is required',
      })
    : validateNumber(data, input);

/**
 * Validates that purchasePrice is not 0 with a custom message
 * @function
 * @param  {Object} data
 * @param  {Number} data.purchasePrice
 * @param  {Input} input
 * @return {ValidationResult}
 */
export const validatePurchasePrice = (data, input) =>
  data.purchasePrice === 0
    ? validationResult({
        valid: false,
        message: 'This field is required',
      })
    : validateNumber(data, input);

/**
 * Validates that down payment is not greater than property value
 * @function
 * @param  {Object} data
 * @param  {Number} data.downPayment
 * @param  {Number} data.propertyValue
 * @return {ValidationResult}
 */
export const validateDownPayment = (data) =>
  !isPurchase(data)
    ? validationResult({ valid: true })
    : validationResult({
        valid:
          data.purchasePrice > 0
            ? Number(data.downPayment) < Number(data.purchasePrice)
            : true,
        message:
          data.downPayment >= 0
            ? 'Please enter an amount less than your home price'
            : 'This field is required',
      });

/**
 * Same as validateDownPayment except it has no message
 * @see {module:validations.validateDownPayment}
 * @function
 * @param  {Object} data
 * @param  {Number} data.downPayment
 * @param  {Number} data.propertyValue
 * @return {ValidationResult}
 */
export const validateDownPaymentPercent = (data) => ({
  ...validateDownPayment(data),
  message: '',
});

/**
 * Validates that mortgage balance does not exceed property value
 * @function
 * @param  {Object} data
 * @param  {external:home-constants.LOAN_PURPOSE} data.loanPurpose
 * @param  {Number} data.propertyValue
 * @return {ValidationResult}
 */
export const validateMortgageBalance = (data, input) =>
  isPurchase(data)
    ? validationResult({ valid: true })
    : validateNumber(data, {
        ...input,
      });

/**
 * Validates loanPrograms using validateArray with a custom error message
 * @function
 * @param  {Object} data
 * @param  {Input} input
 * @return {ValidationResult}
 */
export const validateLoanPrograms = (data, input) => {
  const result = validateArray(data, input);

  if (!result.valid && isEmpty(data.loanPrograms)) {
    result.message = 'Please choose at least one';
  }

  return result;
};

/**
 * Validates originationMonth is not in the past
 * @function
 * @param  {Object} data
 * @return {ValidationResult}
 */
export const validateOriginationMonth = (data) => {
  if (!data.originationMonth) {
    return validationResult({
      valid: false,
      message: 'Please select an option',
    });
  }

  if (data.originationYear !== new Date().getFullYear()) {
    return validationResult({ valid: true });
  }

  return validationResult({
    // getMonth is zero indexed and originationMonth is not so add 1
    valid: data.originationMonth <= new Date().getMonth() + 1,
    message: 'Are you a time traveler? Origination dates must be in the past.',
  });
};

/**
 * Validates originationDate is valid
 * @function
 * @param  {Object} data
 * @return {ValidationResult}
 */
export const validateOriginationDate = (data) => {
  if (!data.originationDate || !isString(data.originationDate)) {
    return {
      valid: false,
      message: 'This field is required',
    };
  }

  if (data.originationDate.length !== 7) {
    return {
      valid: false,
      message: 'Please enter a valid date',
    };
  }

  // convert to numbers and then check below if either are NaN
  const [month, year] = map(data.originationDate.split('/'), Number);

  if (!month || !year || month < 1 || month > 12) {
    return {
      valid: false,
      message: 'Please enter a valid date',
    };
  }

  if (year < 1980) {
    return {
      valid: false,
      message: 'Year must be greater than 1980',
    };
  }

  if (year < new Date().getFullYear()) {
    return { valid: true };
  }

  return {
    // getMonth is zero indexed and originationMonth is not so add 1
    valid:
      year <= new Date().getFullYear() && month <= new Date().getMonth() + 1,
    message: 'Are you a time traveler? Origination dates must be in the past.',
  };
};

/**
 * String validation with custom message
 * @function
 * @param  {Object} data
 * @return {ValidationResult}
 */
export const validateFirstName = (data, input) =>
  validationResult({
    ...validateString(data, input),
    message: 'Please enter your first name',
  });

/**
 * String validation with custom message
 * @function
 * @param  {Object} data
 * @return {ValidationResult}
 */
export const validateLastName = (data, input) =>
  validationResult({
    ...validateString(data, input),
    message: 'Please enter your last name',
  });

/**
 * Validates an e-mail address is correct format
 * @todo doesnt need to hard-code input name, see validateUuid
 * @function
 * @param  {Object} data
 * @param  {String} data.emailAddress
 * @return {ValidationResult}
 */
export const validateEmailAddress = (data) =>
  validationResult({
    valid: isEmailValid(data.emailAddress),
    message: 'Please enter a valid email address',
  });

/**
 * Validates a phone number is 10 digits long
 * @todo hard-coded input name, see validateUuid
 * @function
 * @param  {Object} data
 * @param  {String} data.phoneNumber
 * @return {ValidationResult}
 */
export const validatePhoneNumber = (data) =>
  validationResult({
    valid: isPhoneNumberValid(data.phoneNumber),
    message: 'Please enter a valid U.S. number',
  });

/**
 * this validator is used is forms where there is a property value input but
 * not a mortgage balance input
 * @todo clarify, document what & why?
 * @function
 * @param  {Object} data
 * @param  {external:home-constants.LOAN_PURPOSE} data.loanPurpose
 * @param  {Number} data.mortgageBalance
 * @param  {Input} input
 * @return {ValidationResult}
 */
export const validatePropertyValueToMortgageBalance = (data, input) => {
  const mortgageBalance = get(data, 'mortgageBalance');

  if (isPurchase(data)) {
    return validationResult({ valid: true });
  }

  if (!isFinite(mortgageBalance)) {
    return validateNumber(data, input);
  }

  const minimum = mortgageBalance;
  const value = get(data, get(input, 'name'));
  const validation = validateNumber(data, {
    ...input,
    minimum,
  });

  if (!validation.valid && isFinite(value) && value < minimum) {
    // custom error message if value < minimum
    return validationResult({
      ...validation,
      message: `Property value must be greater than mortgage balance, calculated at ${formatCurrency(
        minimum
      )}, to refinance.`,
    });
  }

  return validation;
};

/**
 * Conditionally validates input as a number if hasBankruptcy is true
 * @function
 * @param  {Object} data
 * @param  {Boolean} data.hasBankruptcy
 * @param  {Input} input
 * @return {ValidationResult}
 * @function
 */
export const validateSinceLastBankruptcy = (data, input) =>
  !data.hasBankruptcy
    ? validationResult({ valid: true })
    : validateNumber(data, input);

/**
 * Conditionally validates input as a number if hasForeclosure is true
 * @function
 * @param  {Object} data
 * @param  {Boolean} data.hasForeclosure
 * @param  {Input} input
 * @return {ValidationResult}
 */
export const validateSinceLastForeclosure = (data, input) =>
  !data.hasForeclosure
    ? validationResult({ valid: true })
    : validateNumber(data, input);

/**
 * Validates given uuid is in the correct format
 * @function
 * @param  {Object} data
 * @param  {Input}  input
 * @param  {String} input.name
 * @return {ValidationResult}
 */
export const validateUuid = (data, input) =>
  validationResult({
    valid: isUUIDValid(get(data, get(input, 'name'))),
    message: 'Invalid UUID',
  });

/**
 * Validates that the zipCode is 5 digits long and then checks against a service
 * @function
 * @param  {Object} data
 * @param  {String} data.zipCode
 * @return {ValidationResult}
 */
export const validateZipCode = ({ zipCode }) => {
  const message = 'Please enter a valid U.S. ZIP code';

  if (!isZipCodeValid(zipCode)) {
    return validationResult({
      valid: false,
      message,
    });
  }

  return new Promise((resolve) => {
    getStateFromZipCode(zipCode)
      .then((res) => {
        const valid = !!res.state;
        resolve(
          validationResult({
            valid,
            message,
          })
        );
      })
      .catch((err) => {
        const logger = getLogger('validateZipCode', rootLogger);

        logger.error('validateZipCode', err);

        resolve(
          validationResult({
            valid: true,
          })
        );
      });
  });
};

/**
 * Validates loanStartDate is valid.
 *
 * @function
 * @param  {Object} data
 * @return {ValidationResult}
 */
export const validateLoanStartDate = (data) => {
  // empty dates are valid, since they will reset to the default values
  if (!data.loanStartDate) {
    return {
      valid: true,
    };
  }
  // If the date is incomplete it will contain placeholder texts e.g. 01 / 2D / YYYY
  const CONTAIN_LETTER = new RegExp(/[a-zA-Z]/);
  if (
    CONTAIN_LETTER.test(data.loanStartDate) ||
    data.loanStartDate.length < 14
  ) {
    return {
      valid: false,
      message: 'Please enter the full date.',
    };
  }

  // convert to numbers and then check below if either are NaN
  const [month, day, year] = map(data.loanStartDate.split('/'), Number);

  if (!day || !month || !year || day > 31 || month < 1 || month > 12) {
    return {
      valid: false,
      message: 'Please enter a valid date.',
    };
  }

  return { valid: true };
};

import { amortizationSchedule } from 'amortization';
// eslint-disable-next-line lodash/import-scope
import { assign, range, times, constant } from 'lodash';

import defaultInputs from '../components/rentvsbuy-calculator/control-panel/shared/default-inputs';

import {
  TAX_STATUS,
  HOUSEHOLD_INCOME,
  CAPITAL_GAINS,
  MAX_INTEREST_DEDUCTION_MORTGAGE,
  SCHEDULE_DURATION,
} from './rentvsbuy-constants';

function rent({
  securityDeposit,
  monthlyRent,
  rentersInsurance,
  rentAppreciation,
}) {
  const appreciationFactor = 1.0 + rentAppreciation;
  const monthlyForYear = (year) =>
    monthlyRent * Math.pow(appreciationFactor, year);

  const insuranceCost = (rentForThisYear) => rentForThisYear * rentersInsurance;

  const totalCashOutflow = (year) => {
    const rentForThisYear = monthlyForYear(year);
    return rentForThisYear + insuranceCost(rentForThisYear);
  };

  const cumulativeCashOutflow = (years) => {
    let cashOutflow = 0;
    let currentYear;
    for (currentYear = 0; currentYear < years; currentYear += 1) {
      cashOutflow += 12 * totalCashOutflow(currentYear);
    }
    return cashOutflow;
  };

  const cumulativeOpportunityCost = (annualReturnCash, years) => {
    const discount = 1 + annualReturnCash / 12;
    const months = years * 12;
    let year = 0;
    let opportunityCost = securityDeposit;
    let cashOutflow = totalCashOutflow(year);
    let currentMonth;
    for (currentMonth = 2; currentMonth <= months; currentMonth += 1) {
      opportunityCost = opportunityCost * discount + cashOutflow;
      // starting of new year therefore new rent and insurance costs
      if (currentMonth % 12 === 1) {
        year += 1;
        cashOutflow = totalCashOutflow(year);
      }
    }

    return (
      opportunityCost -
      (securityDeposit + (cumulativeCashOutflow(years) - cashOutflow))
    );
  };

  return {
    monthlyForYear,
    insuranceCost,
    totalCashOutflow,
    cumulativeCashOutflow,
    cumulativeOpportunityCost,
  };
}

function buy({
  downPaymentPercent,
  principal,
  annualInterestRate,
  purchasePrice,
  downPayment,
  annualPMIFee,
  propertyTaxRate,
  HOADues,
  homeInsurance,
  maintainanceFees,
  renovationFees,
  incomeTaxRate,
  buyingCosts,
  sellingCosts,
  homeAppreciation,
  taxFilingStatus,
  generalInflation,
  income,
  loanTerm,
}) {
  const initialCost = downPayment + purchasePrice * buyingCosts;
  const propertyTax = (purchasePrice * propertyTaxRate) / 12;
  const taxSavingsFromPropertyTax = -propertyTax * incomeTaxRate;

  let maxInterestDedMortgage = MAX_INTEREST_DEDUCTION_MORTGAGE.INDIVIDUAL;
  if (taxFilingStatus === TAX_STATUS.JOINT) {
    maxInterestDedMortgage = MAX_INTEREST_DEDUCTION_MORTGAGE.JOINT;
  }

  // need mortgage schedule based on tax status for maximum tax deduction from interest calculation.
  const taxMortgageSchedule = amortizationSchedule(
    maxInterestDedMortgage,
    parseInt(loanTerm, 10),
    annualInterestRate * 100
  );

  // get monthly mortgage schedule
  let mortgageSchedule;
  if (downPaymentPercent < 1 && principal > 0) {
    mortgageSchedule = amortizationSchedule(
      principal,
      parseInt(loanTerm, 10),
      annualInterestRate * 100
    );
  } else {
    mortgageSchedule = times(
      loanTerm * 12,
      constant({
        payment: 0,
        principalBalance: 0,
        principalPayment: 0,
        interestPayment: 0,
      })
    );
  }

  const insurancePayment = (year) =>
    Math.pow(1 + generalInflation, year) *
    ((purchasePrice * homeInsurance) / 12);

  const hoaPayment = (year) => Math.pow(1 + generalInflation, year) * HOADues;

  const maintenancePayment = (year) =>
    Math.pow(1 + generalInflation, year) *
    ((purchasePrice * maintainanceFees) / 12);

  const renovationPayment = (year) =>
    Math.pow(1 + generalInflation, year) *
    ((purchasePrice * renovationFees) / 12);

  const pmiPayment = (monthIndex, currentYear) =>
    currentYear < loanTerm
      ? (annualPMIFee / 12) *
        (mortgageSchedule[monthIndex].principalBalance +
          mortgageSchedule[monthIndex].principalPayment)
      : 0;

  const taxSavingsFromInterest = (monthIndex, currentYear) =>
    currentYear < loanTerm
      ? -Math.min(
          mortgageSchedule[monthIndex].interestPayment,
          taxMortgageSchedule[monthIndex].interestPayment
        ) * incomeTaxRate
      : 0;

  const taxSavingsFromPMI = (monthIndex, currentYear) => {
    if (
      (taxFilingStatus === TAX_STATUS.INDIVIDUAL &&
        income <= HOUSEHOLD_INCOME.INDIVIDUAL) ||
      (taxFilingStatus === TAX_STATUS.JOINT && income <= HOUSEHOLD_INCOME.JOINT)
    ) {
      return -pmiPayment(monthIndex, currentYear) * incomeTaxRate;
    }
    return 0.0;
  };

  const totalCashOutflow = (monthIndex) => {
    const currentYear = Math.ceil((monthIndex + 1) / 12 - 1);
    const payment =
      currentYear < loanTerm ? mortgageSchedule[monthIndex].payment : 0;

    return (
      payment +
      pmiPayment(monthIndex, currentYear) +
      insurancePayment(currentYear) +
      hoaPayment(currentYear) +
      maintenancePayment(currentYear) +
      renovationPayment(currentYear) +
      propertyTax +
      taxSavingsFromInterest(monthIndex, currentYear) +
      taxSavingsFromPMI(monthIndex, currentYear) +
      taxSavingsFromPropertyTax
    );
  };

  const cumulativeCashOutflow = (months) => {
    let cashOutflow = 0;
    let currentMonth;
    for (currentMonth = 0; currentMonth <= months; currentMonth += 1) {
      cashOutflow += totalCashOutflow(currentMonth);
    }

    return cashOutflow;
  };

  const cumulativeOpportunityCost = (annualReturnCash, years) => {
    const discount = 1 + annualReturnCash / 12;
    const plannedStayIndex = years * 12 - 1;
    let opportunityCost = initialCost;
    let currentMonth = 0;
    let cashOutflow = totalCashOutflow(currentMonth);
    for (
      currentMonth = 1;
      currentMonth <= plannedStayIndex;
      currentMonth += 1
    ) {
      // starting of new year therefore new rent and insurance costs
      opportunityCost = opportunityCost * discount + cashOutflow;
      cashOutflow = totalCashOutflow(currentMonth);
    }

    return (
      opportunityCost -
      (initialCost + (cumulativeCashOutflow(currentMonth - 1) - cashOutflow))
    );
  };

  const cumulativeRenovationCosts = (years) => {
    let renovationCosts = 0;
    let currentYear;
    for (currentYear = 0; currentYear < years; currentYear += 1) {
      renovationCosts += 12 * renovationPayment(currentYear);
    }

    return renovationCosts;
  };

  const homeValue = (year) =>
    Math.pow(1 + homeAppreciation, year) * purchasePrice;

  const netProceeds = (years, capitalGainsTaxRate) => {
    const endHomeValue = homeValue(years);
    const monthIndex = years * 12 - 1;
    const endDebt =
      years < loanTerm ? mortgageSchedule[monthIndex].principalBalance : 0;

    const homeEquity = endHomeValue - endDebt;
    const costOfSelling = endHomeValue * sellingCosts;
    const netProceedsFromSale = homeEquity - costOfSelling;
    const taxBasisCapitalGains =
      netProceedsFromSale - cumulativeRenovationCosts(years);

    let capitalGainsTax = 0;
    if (taxFilingStatus === TAX_STATUS.INDIVIDUAL) {
      if (taxBasisCapitalGains > CAPITAL_GAINS.INDIVIDUAL) {
        capitalGainsTax =
          (taxBasisCapitalGains - CAPITAL_GAINS.INDIVIDUAL) *
          -capitalGainsTaxRate;
      }
    } else if (taxFilingStatus === TAX_STATUS.JOINT) {
      if (taxBasisCapitalGains > CAPITAL_GAINS.JOINT) {
        capitalGainsTax =
          (taxBasisCapitalGains - CAPITAL_GAINS.JOINT) * -capitalGainsTaxRate;
      }
    }
    return netProceedsFromSale + capitalGainsTax;
  };

  return {
    initialCost,
    cumulativeCashOutflow,
    cumulativeOpportunityCost,
    netProceeds,
  };
}

export default (
  {
    // Primary Inputs
    purchasePrice,
    downPayment,
    downPaymentPercent,
    annualInterestRate,
    loanTerm,
    monthlyRent,
    // Ongoing Costs
    propertyTaxRate,
    HOADues,
    homeInsurance,
    maintainanceFees,
    renovationFees,
    incomeTaxRate,
    rentersInsurance,
    // One Time Costs
    buyingCosts,
    sellingCosts,
    rentSecurityDeposit,
    // Economic Assumptions
    homeAppreciation,
    rentAppreciation,
    generalInflation,
    annualReturnCash,
    taxFilingStatus,
    income,
    capitalGainsTaxRate,
  } = assign({}, defaultInputs)
) => {
  const securityDeposit = rentSecurityDeposit * monthlyRent;
  const rentingScenario = rent({
    securityDeposit,
    monthlyRent,
    rentersInsurance,
    rentAppreciation,
  });

  const rentingSchedule = range(0, SCHEDULE_DURATION).map((year) => {
    const yearArg = year + 1;
    const rInitialCost = securityDeposit;
    const rOngoingCosts = rentingScenario.cumulativeCashOutflow(yearArg);
    const rOpportunityCost =
      rentingScenario.cumulativeOpportunityCost(annualReturnCash, yearArg) *
      (1 - capitalGainsTaxRate);
    const rNetProceeds = -rInitialCost;
    const rTotalCost =
      rInitialCost + rOngoingCosts + rOpportunityCost + rNetProceeds;
    const rTotalCostNPV = rTotalCost / Math.pow(1 + generalInflation, yearArg);
    const rMonthlyCostNPV = rTotalCostNPV / (yearArg * 12);

    return {
      year: yearArg,
      initialCost: rInitialCost,
      ongoingCosts: Math.round(rOngoingCosts),
      opportunityCost: Math.round(rOpportunityCost),
      netProceeds: rNetProceeds,
      totalCost: Math.round(rTotalCost),
      totalCostNPV: Math.round(rTotalCostNPV), // net present value of total cost
      monthlyCostNPV: Math.round(rMonthlyCostNPV), // net present value of monthly cost
    };
  });

  const principal = purchasePrice - downPayment;
  const annualPMIFee = downPaymentPercent < 0.2 ? 0.01 : 0.0;

  const buyingScenario = buy({
    downPaymentPercent,
    principal,
    annualInterestRate,
    purchasePrice,
    downPayment,
    annualPMIFee,
    propertyTaxRate,
    HOADues,
    homeInsurance,
    maintainanceFees,
    renovationFees,
    incomeTaxRate,
    buyingCosts,
    sellingCosts,
    homeAppreciation,
    taxFilingStatus,
    generalInflation,
    income,
    loanTerm,
  });

  let initialSign = null;
  let breakevenYear = null;
  let signChanged = false;

  const buyingSchedule = range(0, SCHEDULE_DURATION).map((year) => {
    const yearArg = year + 1;
    const bInitialCost = buyingScenario.initialCost;
    const bOngoingCosts = buyingScenario.cumulativeCashOutflow(
      yearArg * 12 - 1
    );
    const bOpportunityCost =
      buyingScenario.cumulativeOpportunityCost(annualReturnCash, yearArg) *
      (1 - capitalGainsTaxRate);
    const bNetProceeds = -buyingScenario.netProceeds(
      yearArg,
      capitalGainsTaxRate
    );
    const bTotalCost =
      bInitialCost + bOngoingCosts + bOpportunityCost + bNetProceeds;
    const bTotalCostNPV = bTotalCost / Math.pow(1 + generalInflation, yearArg);
    const bMonthlyCostNPV = bTotalCostNPV / (yearArg * 12);

    const difference = rentingSchedule[year].totalCostNPV - bTotalCostNPV;
    const sign = difference < 0 ? 0 : 1;

    // record whether the difference was positive or negative in the very first year.
    if (initialSign === null) {
      initialSign = sign;
    }

    if (!signChanged && initialSign !== sign) {
      signChanged = true;
      // make breakeven year the year of the sign change.
      breakevenYear = yearArg;
    }

    return {
      year: yearArg,
      initialCost: bInitialCost,
      ongoingCosts: Math.round(bOngoingCosts),
      opportunityCost: Math.round(bOpportunityCost),
      netProceeds: Math.round(bNetProceeds),
      totalCost: Math.round(bTotalCost),
      totalCostNPV: Math.round(bTotalCostNPV), // net present valye of total cost
      monthlyCostNPV: Math.round(bMonthlyCostNPV), // net present value of monthly cost
    };
  });

  return {
    breakevenYear,
    rentingSchedule,
    buyingSchedule,
  };
};

/*
  Mapping for nwjs home app lib since it's easier than rewrite.
  This smooths over inconsistencies between key names in calcs.
  'home-app-lib-inputId': 'diy-tools-hac-keyname'
  ie downPayment: downpayment
*/
import { LOAN_PURPOSE_PURCHASE } from '@nerdwallet/home-constants';

/* Allow zero values but not '' */
const pickZeroes = (values) => {
  let res;
  values.forEach((value) => {
    if (typeof value === 'number' && value >= 0) res = value;
  });
  return res;
};

const transformInputs = (inputs) => ({
  ...inputs,
  annualInterestRate: pickZeroes([
    inputs.currentMortgageInterestRate,
    inputs.annualInterestRate,
  ]),
  downPayment: pickZeroes([
    inputs.downpayment,
    inputs.downPayment,
    inputs.fundsNeeded,
  ]),
  householdIncome: pickZeroes([inputs.income, inputs.householdIncome]),
  purchasePrice: pickZeroes([inputs.homePrice, inputs.purchasePrice]),
  cashout: inputs.cashout,
  loanPurpose: inputs.loanPurpose || LOAN_PURPOSE_PURCHASE,
  loanTerm: Number(inputs.loanTerm),
  mortgageBalance: pickZeroes([inputs.mortgageBalance]),
  propertyTaxRate: pickZeroes([inputs.propertyTax, inputs.propertyTaxRate]),
  propertyTax: pickZeroes([inputs.propertyTax, inputs.propertyTax]),
  HOADues: pickZeroes([inputs.hoa, inputs.HOADues]),
  mortgageInsurance: pickZeroes([inputs.mortgageInsurancePayment]),
  firstVaLoan:
    typeof inputs.firstVaLoan === 'boolean'
      ? inputs.firstVaLoan
      : inputs.firstVaLoan !== 'false',
});

export default transformInputs;

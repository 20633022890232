// Utils
// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import { isEmpty, cloneDeep, findIndex, find, get } from 'lodash';

// Actions
import compareFieldsConfig from '../../config/city-life/compare-fields-config';
import {
  FETCH_CITIES,
  FETCH_COMPARE_CITY_DATA,
  FETCH_FIELD_DETAILS,
  FETCH_CITY_LIFE_CITIES,
} from '../../lib/actions/fetchColData';
import { CITY_CHANGE, COMPARE_FIELD_CHANGE } from '../../lib/cl/cl-actions';

// Config

// Const
export const ACTIVE_TAB_CHANGE = 'ACTIVE_TAB_CHANGE';

/**
 * The initial state of the cost of living calculator reducer state.
 *
 */
function getInitialState() {
  return {
    cities: [],
    totalNumCities: 0,
    inputs: {
      city: 38, // Default - San Francisco
      compareField: 'median_salary',
    },
    compareFieldConfig: find(compareFieldsConfig, { value: 'median_salary' }),
    fieldDetails: [],
    activeTabIndex: 0,
  };
}

export default (state = getInitialState(), action = {}) => {
  let newState = state;

  // Is the state an empty object ? The reducer is probably being added.
  if (isEmpty(state)) {
    return getInitialState();
  }

  switch (action.type) {
    case FETCH_CITY_LIFE_CITIES:
      newState = cloneDeep(state);
      newState.cities = action.data;
      break;

    case FETCH_CITIES:
      newState = cloneDeep(state);
      // only store length, don't want to send all this data back to the client
      newState.totalNumCities = action?.data?.length;
      break;

    case FETCH_COMPARE_CITY_DATA:
      newState = cloneDeep(state);

      get(action, 'data', []).forEach((city) => {
        const cityIndex = findIndex(newState.cities, { id: city.id });
        newState.cities[cityIndex] = city;
      });

      break;

    case FETCH_FIELD_DETAILS:
      newState = cloneDeep(state);
      newState.fieldDetails = action.data;
      break;

    case COMPARE_FIELD_CHANGE: {
      // need to also set the compareFieldConfig
      newState = cloneDeep(state);
      const compareFieldConfig = find(compareFieldsConfig, {
        value: action.inputs.compareField,
      });

      newState = {
        ...newState,
        inputs: {
          ...newState.inputs,
          ...action.inputs,
        },
        compareFieldConfig,
      };

      break;
    }

    case CITY_CHANGE:
      newState = cloneDeep(state);

      newState.inputs = {
        ...newState.inputs,
        ...action.inputs,
      };

      break;

    case ACTIVE_TAB_CHANGE:
      newState = cloneDeep(state);
      newState.activeTabIndex = action.activeTabIndex;
      break;

    default:
      break;
  }

  // Return the new state.
  return newState;
};

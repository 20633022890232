/** @module validate */
// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import { map, pickBy } from 'lodash';

import type Input from '../../components/inputs/input';

import ValidationError from './errors';

const formatter = (results) =>
  results.reduce(
    (acc, input) => {
      const { name, errors } = input;

      acc.valid = acc.valid === false ? false : errors.length === 0;
      acc.validations[name] = Array.isArray(acc.validations[name])
        ? acc.validations[name].concat(errors)
        : errors;
      return acc;
    },
    {
      valid: true,
      validations: {},
    }
  );

/**
 * @typedef {Object} ValidationResult
 * @property {Boolean} valid  Were all inputs valid?
 * @property {Object}  validations  Keys represent input name and values error messages
 * @example
 * // Example result with validation errors
 * {
 *   valid: true,
 *   validations: {
 *     validInput: [],
 *     validInputTwo: [],
 *   }
 * }
 *
 * @example
 * // Example result with validation errors
 * {
 *   valid: false,
 *   validations: {
 *     invalidInput: ['Must be something'],
 *     invalidInputTwo: ['Must contain uppercase letter', 'Must contain a symbol'],
 *     validInput: []
 *   }
 * }
 */

/**
 * @example
 * import validate from '../validation/validate';
 *
 * validate(myInputs, data)
 *   .then(({ valid, validations }) => {
 *     console.log('Is valid?', valid);
 *     console.log('All inputs validated and error messages', validations);
 *     // do something
 *   });
 *
 * @function
 * @param  {Array} inputs  Constructed inputs
 * @param  {Object} data   To validate against
 * @return {Promise<ValidationResult>}       Resolves into an object with the input `name` as key and error messages as value
 */
export const validate = (inputs, data) =>
  Promise.all(
    map(inputs, (input) =>
      input.validate(data).then((errors) => ({
        name: input.name,
        errors,
      }))
    )
  ).then(formatter);

/**
 * Validation that rejects on error. Useful in express middleware
 * @example
 * import validate from '../validation/validate';
 *
 * export function validate(req, res, next) {
 *   validate(myInputs, req.body)
 *    .then(next)
 *    .catch(next);
 * }
 *
 * @param  {Object|Array} inputs  Constructed Input defintions
 * @param  {Mixed} data   [description]
 * @return {Promise}    Will reject on error and resolve with no value on success
 */
export default (
  inputs: { [key: string]: Input } | Input[],
  data
): Promise<void> =>
  new Promise((resolve, reject) =>
    validate(inputs, data).then(({ valid, validations }) => {
      if (!valid) {
        reject(
          new ValidationError(
            'Invalid inputs',
            pickBy(validations, (input) => input.length !== 0)
          )
        );
        return;
      }

      resolve();
    })
  );

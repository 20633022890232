import nets from 'nets';
// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import { get } from 'lodash';
import config from '@nerdwallet/app-config';
import transform from '@nerdwallet/transform';

const BASE_URL_CONFIG_KEY = 'SERVICE_URLS.MARKETPLACE';
const baseUrl = get(config, BASE_URL_CONFIG_KEY);

interface Program {
  name: string;
  description: string;
  designation: string;
  ctaText: string;
  states: string;
  cta: string;
  bestFor: string;
  disclaimer: string;
  id: string;
  productSlug: string;
}

const requestFromMarketplace = (productId: string): Promise<Program> => {
  const url = `${baseUrl}/marketplace/products/v1/first_time_home_buyer_programs/search?client_id=${config.CLIENT_ID}`;

  return new Promise((resolve, reject) => {
    if (!baseUrl) {
      reject(new Error(`Missing baseUrl ${BASE_URL_CONFIG_KEY}`));
    }

    if (!productId) {
      reject(new Error('Missing productId'));
    }

    nets(
      {
        url,
        method: 'POST',
        encoding: 'utf8',
        json: true,
        body: {
          query: [
            {
              field: 'product.id',
              type: 'eq',
              value: productId,
            },
          ],
        },
      },
      (error, response, body) => {
        const statusCode = get(response, 'statusCode');

        if (error || statusCode !== 200) {
          reject(new Error(get(body, 'error', error)));
        }

        resolve(
          transform(
            {
              name: 'data.products[0].details.display_name.value',
              description: 'data.products[0].details.description.value',
              designation: 'data.products[0].details.designation.value',
              ctaText: 'data.products[0].details.cta_text.value',
              states: 'data.products[0].details.states.value',
              cta: 'data.products[0].details.cta.value',
              bestFor: 'data.products[0].details.best_for_bullets.value',
              disclaimer: 'data.products[0].details.cta_disclaimer.value',
              id: 'data.products[0].id',
              productSlug: 'data.products[0].institution.name',
            },
            body
          ) as Program
        );
      }
    );
  });
};

export default requestFromMarketplace;

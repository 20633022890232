/**
 * Dependencies
 */
import React from 'react';

import createBrowserHistory from 'history/lib/createBrowserHistory';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { syncReduxAndRouter } from 'redux-simple-router';
import initBaseStyles from '@nerdwallet/base-styles/init';
import { Tracking as nwTracking } from '@nerdwallet/nw-client-lib';
import { initBrowserEvents } from '@nerdwallet/analytics';
import {
  SCREEN_SMALL_MAX,
  SCREEN_MEDIUM,
  SCREEN_MEDIUM_MAX,
} from '@nerdwallet/react-media-queries';
import { Router } from '@nerdwallet/react-router';

import { matchMedia, windowResize } from './lib/actions/browser';
import routeDefinitions from './routes';

const MOBILE_ONLY = `(max-width: ${SCREEN_SMALL_MAX}px)`;
const TABLET_ONLY = `(min-width: ${SCREEN_MEDIUM}px) and (max-width: ${SCREEN_MEDIUM_MAX}px)`;

const history = createBrowserHistory();

initBaseStyles();
initBrowserEvents();

// Webpack lets us us embed styles and assets within the code.
require('@nerdwallet/base-styles/reset.css');
require('@nerdwallet/base-styles/base.css');
require('./styles/main.less');

export default class Main extends React.Component {
  constructor(props) {
    super(props);

    const store = props.store;
    syncReduxAndRouter(history, store);
    nwTracking.logPageView(store.getState().routing.path);

    if (window.matchMedia) {
      this.matchMediaMobile = window.matchMedia(MOBILE_ONLY);
      this.matchMediaTablet = window.matchMedia(TABLET_ONLY);
      this.onMatchMediaMobile = this.onMatchMedia.bind(this, 'isMobile');
      this.onMatchMediaTablet = this.onMatchMedia.bind(this, 'isTablet');
    } else {
      this.onResize = _.throttle(() => {
        store.dispatch(windowResize());
      }, 200);
    }
  }

  componentDidMount() {
    // set up throttled onresize actions
    if (this.onResize) {
      window.addEventListener('resize', this.onResize);
      this.onResize();
    } else {
      this.matchMediaMobile.addListener(this.onMatchMediaMobile);
      this.matchMediaTablet.addListener(this.onMatchMediaTablet);

      this.props.store.dispatch(
        matchMedia({
          isMobile: this.matchMediaMobile.matches,
          isTablet: this.matchMediaTablet.matches,
        })
      );
    }
  }

  componentWillUnmount() {
    if (this.onResize) {
      window.removeEventListener('resize', this.onResize);
    } else {
      this.matchMediaMobile.removeListener(this.onMatchMediaMobile);
      this.matchMediaTablet.removeListener(this.onMatchMediaTablet);
    }
  }

  onMatchMedia(type, mediaQueryList) {
    this.props.store.dispatch(
      matchMedia({
        [type]: mediaQueryList.matches,
      })
    );
  }

  render() {
    return (
      <Router history={history}>{routeDefinitions(this.props.store)}</Router>
    );
  }
}

// eslint-disable-next-line react/static-property-placement -- directive added automatically by Shepherd migration
Main.propTypes = {
  store: PropTypes.object.isRequired,
};

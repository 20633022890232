import { ONBOARDING_BYPASS } from '../lib/actions';

const defaultState = () => false;

export default (state = defaultState(), action = {}) => {
  switch (action.type) {
    case ONBOARDING_BYPASS:
      return true;

    default:
      return state;
  }
};

// Utils
// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import { assign, cloneDeep, isEmpty, get } from 'lodash';

import { getCreditScoreRangeForCreditScore } from '@nerdwallet/mortgage-utils/rate-adjustment';

// Actions
import defaultInputs from '../config/home-improvement-finance-calculator/default-inputs';
import {
  UPDATE_DEFAULT_RATE,
  INPUT_CHANGE,
  HIFC_RESULTS_CHANGE,
  SIGN_IN,
  FILTER_HIFC_LENDERS,
} from '../lib/actions';
// Configs and Data

export const getInitialState = () => {
  return {
    inputs: assign({}, defaultInputs),
    results: {},
    manualInputs: {},
    zoomed: false,
    emailInfo: {},
    rate: 4.5,
    resultConfig: {},
  };
};

export default (state = getInitialState(), action = {}) => {
  let newState = state;

  // Is the state an empty object ? The reducer is probably being added.
  if (isEmpty(state)) {
    return getInitialState();
  }

  switch (action.type) {
    case SIGN_IN: {
      const creditScore = get(
        action,
        'payload.identity.profiles.tu_credit_report_profile.vantage_score_3'
      );

      if (creditScore) {
        const creditScoreRangeValue =
          getCreditScoreRangeForCreditScore(creditScore).value;
        // Clone the current state to build the new state
        newState = cloneDeep(state);

        newState.inputs.creditScore = creditScoreRangeValue;
      }

      break;
    }
    case INPUT_CHANGE: {
      newState = cloneDeep(state);

      if (!(isEmpty(action.manualInputs) && isEmpty(action.inputs))) {
        assign(newState.manualInputs, action.manualInputs, action.inputs);
        assign(newState.inputs, newState.manualInputs);
      }

      break;
    }

    case FILTER_HIFC_LENDERS:
      newState = cloneDeep(state);
      newState.resultsConfig = action.resultsConfig;
      break;

    case HIFC_RESULTS_CHANGE: {
      newState = cloneDeep(state);
      if (!isEmpty(action.results)) {
        assign(newState.results, action.results);
      } else {
        newState.results = {};
        newState.resultsConfig = {};
      }
      break;
    }

    case UPDATE_DEFAULT_RATE: {
      if (action.annualInterestRates.fixed_30) {
        newState = cloneDeep(state);
        newState.rate = action.annualInterestRates.fixed_30;
        newState.annualInterestRates = action.annualInterestRates;
      }
      break;
    }

    default:
    // do nothing
  }

  // Return the new state.
  return newState;
};

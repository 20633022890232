// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import { get } from 'lodash';
import qs from 'qs';
import urlJoin from 'url-join';

import { onboardingBypass } from '../actions';

const checkProfiles = (store, appName) => {
  const state = store.getState();
  const completedRcOnboardingPath =
    'profiles.home_calculator_profile.has_completed_onboarding_rc';
  const completedHacOnboardingPath =
    'profiles.home_calculator_profile.has_completed_onboarding_hac';
  // If has_completed_onboarding_rc is null new profile and they should go through onboarding
  // If it's false they have exited and should not go through onboarding
  if (get(state, 'identity.isSignedIn')) {
    return (
      (appName === 'rc' &&
        get(state, `identity.${completedRcOnboardingPath}`) === true) ||
      (appName === 'hac' &&
        get(state, `identity.${completedHacOnboardingPath}`) === true)
    );
  }

  return (
    (appName === 'rc' &&
      get(state, `identity.visitorData.${completedRcOnboardingPath}`) ===
        true) ||
    (appName === 'hac' &&
      get(state, `identity.visitorData.${completedHacOnboardingPath}`) === true)
  );
};

export const handleMainRedirect =
  (store, appName, secondUrl, addReducers = () => {}) =>
  (nextState, replaceState) => {
    if (checkProfiles(store, appName)) {
      replaceState(null, urlJoin(nextState.location.pathname, secondUrl));
      return;
    }

    addReducers();
  };

export const handleResultsRedirect =
  (store, appName, addReducers = () => {}) =>
  (nextState, replaceState) => {
    const {
      location: { state },
    } = nextState;

    // If user is returning, ensure reducers are added and bypass onboarding
    if (checkProfiles(store, appName)) {
      addReducers();
      store.dispatch(onboardingBypass());

      return true;
    }

    // Are we forcing this redirect internaly from the app?
    if (get(state, 'postOnboarding')) {
      // Let it through!
      return true;
    }

    // Make a shallow copy of the routes.
    const routes = [...nextState.routes];

    // Pop the last route off.
    routes.pop();

    // Redirect the parent path of what got hit.
    replaceState(
      null,
      `${urlJoin(...routes.map((route) => route.path))}?${qs.stringify(
        nextState.location.query
      )}`
    );

    return false;
  };

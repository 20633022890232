/**
 * This is a container for all route handlers. It can be simple and
 * dumb as is (passes route params down to RouteHandler),
 * but allows for an extra layer of control over all nested routes in needed.
 */

/**
 * Dependencies
 */
import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Tracking as nwTracking } from '@nerdwallet/nw-client-lib';

class Container extends Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    // Log the page view when the route changes.
    if (this.props.routing.path !== nextProps.routing.path) {
      // TODO: Move the error handling on nw-client-lib.
      nwTracking.logPageView(nextProps.routing.path);
    }
  }

  render() {
    return <div className="route-container">{this.props.children}</div>;
  }
}

// eslint-disable-next-line react/static-property-placement -- directive added automatically by Shepherd migration
Container.displayName = 'RouteContainer';

// eslint-disable-next-line react/static-property-placement -- directive added automatically by Shepherd migration
Container.propTypes = {
  routing: PropTypes.object.isRequired,
  children: PropTypes.node,
};

// eslint-disable-next-line react/static-property-placement -- directive added automatically by Shepherd migration
Container.defaultProps = {
  children: null,
};

export default connect(({ routing }) => ({ routing }))(Container);

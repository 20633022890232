/** @module input */
// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import { assign, get, isNumber, filter, isEmpty } from 'lodash';

import { validateType, validateWhitelist } from '../../lib/validation/types';

/**
 * Helper to copy/rename size properties to viewConfig
 * @private
 * @param  {Object} inputProps
 * @return {Object}
 */
const getSizeProps = (inputProps) => {
  const map = {
    min: 'minimum',
    max: 'maximum',
    minLength: 'minLength',
    maxLength: 'maxLength',
  };

  return Object.keys(map).reduce((acc, key) => {
    const prop = get(inputProps, map[key]);
    if (isNumber(prop)) {
      acc[key] = prop;
    }

    return acc;
  }, {});
};

/**
 * Helper to get default viewConfig subType (type of what is passed in events as value)
 * @private
 * @return {String}
 */
const getSubType = ({ type }) => {
  switch (type) {
    case 'integer':
    case 'float':
      return 'number';

    case 'string':
    default:
      return 'text';
  }
};

/**
 * Helper to get default viewConfig type
 * @private
 * @return {String}
 */
const getType = ({ type }) => {
  switch (type) {
    case 'integer':
      return 'tel';

    default:
      return null;
  }
};

/**
 * Converts provided or default validators to function references
 * @private
 * @return {Object}
 */
const getValidators = ({
  type,
  whitelist = undefined,
  validators = undefined,
}) =>
  validators || [
    whitelist && type !== 'array' ? validateWhitelist : validateType[type],
  ];

/**
 * Helper to get default viewConfig value references
 * @private
 * @param  {String} viewType
 * @return {Object}
 */
const getValueProps = (viewType) => {
  switch (viewType) {
    case 'input':
      return {
        value: '{{1}}',
        placeholder: '{{0}}',
      };

    default:
    case 'radio':
    case 'select':
      return {
        value: '{{2}}',
      };
  }
};

/**
 * Helper to get default viewType
 * @private
 * @return {String} Resolved viewConfig.viewType
 */
const getViewType = ({ type, whitelist = undefined }) => {
  if (type === 'boolean' || whitelist) {
    if (whitelist && whitelist.length > 2) {
      return 'select';
    }

    return 'radio';
  }

  return 'input';
};

interface InputProps {
  name: string;
  type: string;
  format?: string;
  required?: boolean;
  nullable?: boolean;
  sensitive?: boolean;
  minimum?: number;
  maximum?: number;
  minLength?: number;
  maxLength?: number;
  validators?: any[];
  whitelist?: any[];
  viewConfig?: any;
}

/**
 * Wrapper to provide default validations and ConfigBuilder viewConfig.
 * @class
 * @example
 * import Input from '../../components/inputs/input';
 *
 * const myInput = new Input({
 *  name: 'path.to.my.input.data',
 *  type: 'string',
 * });
 */
export default class Input {
  name: any;

  viewConfig: any;

  required: boolean;

  nullable: boolean;

  validators: any;

  /**
   * @param {Object} properties
   * @param {String} properties.name Name of the input.  Must be unique
   * @param {String} properties.type Expected type of data; 'integer', 'float', 'boolean', 'string' or 'array'
   * @param {String} [properties.format] Format used for validation messages and `formatType` in ConfigBuilder; 'percentage' or 'currency'
   * @param {Boolean} [properties.required=true] Is the input required?
   * @param {Boolean} [properties.nullable] Input is nullable?
   * @param {Boolean} [properties.sensitive=false] Does the input contain PII?
   * @param {Number} [properties.minimum] Minimum allowed value. Numeric types only.
   * @param {Number} [properties.maximum] Maximum allowed value. Numeric types only.
   * @param {Number} [properties.minLength] Minimum length of string.
   * @param {Number} [properties.maxLength] Maximum length of string.
   * @param {Array} [properties.validators] Override built-in validators. Should be an array of functions.
   * @param {Array} [properties.whitelist] List of allowed values.
   * @param {Object} [properties.viewConfig={}]  Defaults for ConfigBuilder's viewConfig
   */
  constructor({ viewConfig = {}, ...inputProps }: InputProps) {
    const { name, format, sensitive } = inputProps;
    const viewType = viewConfig.viewType || getViewType(inputProps);

    assign(this, inputProps, {
      sensitive: sensitive === true, // default to false
      validators: getValidators(inputProps),
      viewConfig: {
        viewType,
        subType: getSubType(inputProps),
        type: getType(inputProps),
        trackingRedactValue: sensitive,
        trackingFieldName: name,
        formatType: format || 'none',
        inputId: name,
        keys: ['defaults', 'manual', 'resolved'].map((collection) => ({
          update: collection === 'manual',
          key: `${collection}.${name}`,
        })),
        getValidationConfig: this.getValidationConfig.bind(this),
        ...getSizeProps(inputProps),
        ...getValueProps(viewType),
        ...viewConfig,
      },
    });
  }

  /**
   * Helper to get validation status and messages.  Passed to `ConfigBuilder`
   * @param  {Object} data Object passed to ConfigBuilder as `data`
   * @return {Object}      Boolean `isValid` and array of `feedback`
   */
  getValidationConfig(data: any): { isValid: boolean; feedback: string[] } {
    const errors = data.validations ? data.validations[this.name] ?? [] : [];

    return {
      isValid: errors.length === 0,
      feedback: filter(errors, (errMessage) => !isEmpty(errMessage)),
    };
  }

  /**
   * Get and optionally override default viewConfig
   * @param  {Object} [overrides={}] Any viewConfig props you want to override for this instance
   * @return {Object} ConfigBuilder's viewConfig
   */
  getViewConfig(overrides = {}): { [key: string]: any } {
    return {
      ...this.viewConfig,
      ...overrides,
    };
  }

  /**
   * Runs all validators for the input and returns a {Promise}
   * that resolves with an array of error messages
   *
   * @example
   * myInput.validate(data).then(errorMessages => {
   *  const isValid = errorMessages.length === 0,
   * });
   *
   * @param  {Object} data Object passed to ConfigBuilder as `data`
   * @return {Promise<Array>}     Resolved with an array of error messages
   */
  validate(data = {}): Promise<string[]> {
    const value = get(data, this.name);
    if (this.required === false) {
      if (
        (this.nullable && value === null) ||
        value === '' ||
        value === undefined
      ) {
        return Promise.resolve([]);
      }
    }

    return Promise.all(
      this.validators.map((validator) => Promise.resolve(validator(data, this)))
    ).then((results) =>
      results.reduce((acc, { valid, message }) => {
        if (!valid) {
          acc.push(message);
        }

        return acc;
      }, [])
    );
  }
}

// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import { assign, get, has } from 'lodash';

import transformInputs from '../../config/data/adapters';
// eslint-disable-next-line import/no-cycle -- directive added automatically by Shepherd migration
import { hasValidationsToFix } from '../../reducers/validations';

import {
  resolveCalculations,
  saveHomeCalculatorProfile,
  INPUT_VALIDATION,
  INPUT_CHANGE,
  LOAN_TERM_CHANGE,
} from '../actions';

import saveToProfile from './save-to-profile';
import { validate } from '../validation/validate';

type Values = {
  name?: string;
  manualInputs?: any;
  inputs?: any;
  resolvedInputs?: any;
};

type InputIds = any[];

export default function inputChange(
  { name, manualInputs, inputs, resolvedInputs }: Values,
  inputIds?: InputIds
) {
  return (dispatch: any, getState: any): void => {
    if (has(inputs, 'loanTerm')) {
      const { annualInterestRates } = getState().rates;

      dispatch({
        type: LOAN_TERM_CHANGE,
        annualInterestRates,
        inputs,
      });
    }

    if (manualInputs || inputs || resolvedInputs) {
      dispatch({
        type: INPUT_CHANGE,
        manualInputs: assign({}, manualInputs, resolvedInputs),
        inputs,
      });

      const currentState = get(getState(), name);

      const data = transformInputs(
        assign(
          {},
          get(currentState, 'manualInputs'),
          get(currentState, 'inputs'),
          get(currentState, 'resolvedInputs')
        )
      );
      if (inputIds && inputIds.length) {
        validate(inputIds, data).then(({ valid, validations }) => {
          dispatch({
            type: INPUT_VALIDATION,
            valid,
            validations,
          });
          if (name === 'costOfLivingCalculator') return valid;
          const state = getState();

          // We want to make sure we don't save with outstanding validations to fix
          const needsValidationsFixed = hasValidationsToFix(state);

          const allValid = !needsValidationsFixed && valid;
          if (allValid) {
            // Get the new outputs before we save them
            const resolvers = assign({}, inputs, manualInputs, resolvedInputs);

            dispatch(saveHomeCalculatorProfile(resolvers));
            dispatch(saveToProfile());
            dispatch(resolveCalculations(resolvers));
          }

          return valid;
        });
      }
    }
  };
}

import { TAX_STATUS } from '../../../../lib/rentvsbuy-constants';

const inputs = {
  // Primary Inputs
  annualReturnCash: 0.04, // compound monthly
  annualInterestRate: 0.0375,
  buyingCosts: 0.04,
  capitalGainsTaxRate: 0.15,
  downPayment: 50000, // 20%
  downPaymentPercent: 0.2,
  generalInflation: 0.02, // yearly
  HOADues: 0,
  homeAppreciation: 0.03, // compound yearly
  homeInsurance: 0.0046,
  incomeTaxRate: 0.25,
  income: 80000,
  loanTerm: 30, // years
  // TMP for validations to work, change the validation
  loanPurpose: 'purchase',
  maintainanceFees: 0.005,
  monthlyRent: 1000, // per month
  purchaseAddress: {
    city: 'San Francisco',
    state: 'CA',
  },
  purchasePrice: 250000,
  propertyTaxRate: 0.0135,
  renovationFees: 0.005,
  rentersInsurance: 0.0132,
  rentSecurityDeposit: 1, // month
  rentAppreciation: 0.02, // compound yearly
  sellingCosts: 0.06,
  taxFilingStatus: TAX_STATUS.INDIVIDUAL, // individual or joint
};

export default inputs;

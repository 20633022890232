// LendingTree credit score ranges
export const creditScoreRange = [
  [760],
  [740, 759],
  [720, 739],
  [700, 719],
  [680, 699],
  [660, 679],
  [640, 659],
  [620, 639],
  [580, 619],
  [300, 579],
];

export const bankruptcyAndForeclosureRange = [
  [0, 0],
  [1, 12],
  [13, 24],
  [25, 36],
  [37, 48],
  [49, 60],
  [61, 72],
  [73, 84],
  [85],
];

import {
  TOGGLE_LOADING,
  loadingStates,
  FETCH_CITY_DATA,
  FETCH_CITY_DATA_SUCCESS,
  FETCH_CITY_DATA_ERROR,
} from '../lib/actions';

export default (state = false, action = {}) => {
  const newState = state;

  switch (action.type) {
    case TOGGLE_LOADING:
      return action.loadingState === loadingStates.SHOW_LOADING;

    case FETCH_CITY_DATA:
      return true;

    case FETCH_CITY_DATA_SUCCESS:
    case FETCH_CITY_DATA_ERROR:
      return false;

    default:
      break;
  }

  // Return the new state.
  return newState;
};

import { BROWSER_WINDOW_RESIZE, BROWSER_MATCH_MEDIA } from '../actions';

export const windowResize = (userAgent = '') => ({
  userAgent,
  type: BROWSER_WINDOW_RESIZE,
});

export const matchMedia = (matches) => ({
  ...matches,
  type: BROWSER_MATCH_MEDIA,
});

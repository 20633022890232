// Utils
// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import {
  assign,
  get,
  isFinite,
  range,
  isUndefined,
  isNull,
  isNaN,
} from 'lodash';

import getDefaultInputs from '../components/mortgage-calculator/shared/default-inputs';

import { Mortgage } from './refi-calculator';

const exists = (v) => !(isUndefined(v) || isNull(v));

const isValidNumber = (maybeNumber) =>
  exists(maybeNumber) && !isNaN(Number(maybeNumber));

// Configs and Data
function calculateOutputs({
  purchasePrice,
  downPayment,
  annualInterestRate,
  loanTerm,
  annualPropertyTax,
  annualHomeInsurance,
  hoa,
  annualPMIFee,
  overrides,
}) {
  let downPaymentPct = downPayment / purchasePrice;
  if (!isFinite(downPaymentPct)) downPaymentPct = 0;

  const principal = purchasePrice - downPayment;
  const propertyTax = annualPropertyTax / 12;
  const homeownersInsurance = annualHomeInsurance / 12;

  const mortgage = Math.max(
    0,
    (principal *
      ((annualInterestRate / 12) *
        Math.pow(1 + annualInterestRate / 12, loanTerm * 12))) /
      (Math.pow(1 + annualInterestRate / 12, loanTerm * 12) - 1)
  );

  let mortgageInsurancePayment = null;
  if (downPaymentPct < 0.2) {
    const mpiOverride = get(
      overrides,
      'monthlyPayments.mortgageInsurancePayment'
    );

    mortgageInsurancePayment = isValidNumber(mpiOverride)
      ? mpiOverride
      : (principal * annualPMIFee) / 12;
  }

  const total =
    mortgage +
    propertyTax +
    homeownersInsurance +
    hoa +
    (mortgageInsurancePayment || 0);
  const totalMortgageCost = total * loanTerm * 12;
  const totalInterestPaid =
    totalMortgageCost -
    (purchasePrice - downPayment) -
    annualPropertyTax * loanTerm -
    annualHomeInsurance * loanTerm -
    hoa * loanTerm * 12;
  const m = new Mortgage(loanTerm * 12, annualInterestRate / 12, principal);

  let halfway;

  const monthlySchedule = range(0, loanTerm * 12 + 1).map((month) => {
    const balance = m.balance(month);
    const principalPaid = principal - balance;
    const interestPayment =
      month === 0 ? 0 : m.cumulativeInterestPaid(month - 1, month);

    return {
      remaining: balance,
      principal: principalPaid,
      interest: m.cumulativeInterestPaid(0, month),
      payment: {
        interest: interestPayment,
        principal: month === 0 ? 0 : m.payment - interestPayment,
      },
    };
  });

  const schedule = monthlySchedule
    .filter((_, index) => index % 12 === 0)
    .map((year, index) => {
      const interestPayment =
        index === 0
          ? 0
          : m.cumulativeInterestPaid((index - 1) * 12, index * 12);

      halfway =
        typeof halfway === 'undefined' && year.remaining <= year.principal
          ? index
          : halfway;

      return {
        remaining: year.remaining,
        principal: year.principal,
        interest: year.interest,
        payment: {
          interest: interestPayment,
          principal: index === 0 ? 0 : m.payment * 12 - interestPayment,
        },
      };
    });

  return {
    monthlyPayments: {
      mortgage,
      propertyTax,
      homeownersInsurance,
      hoa,
      ...(isValidNumber(mortgageInsurancePayment) && {
        mortgageInsurancePayment,
      }),
      total,
    },
    schedule,
    monthlySchedule,
    halfway,
    totalInterestPaid,
    principal,
    downPaymentPct,
  };
}

function buildCompareData(annualInterestRates, inputs, loanTerm) {
  const annualInterestRate = annualInterestRates[`fixed_${loanTerm}`] / 100;

  const outputs = calculateOutputs({
    ...inputs,
    loanTerm,
    annualInterestRate,
  });

  return {
    monthlyPayment: outputs.monthlyPayments.total,
    annualInterestRate,
    totalInterestPaid: outputs.totalInterestPaid,
  };
}

export default (inputs = assign({}, getDefaultInputs())) => {
  const {
    HOADues,
    homeInsurance,
    purchasePrice,
    overrides,
    propertyTaxRate,
    propertyTax,
    annualInterestRates,
  } = inputs;

  const annualPropertyTax =
    get(
      overrides,
      'monthlyPayments.propertyTax',
      (propertyTax || purchasePrice * propertyTaxRate) / 12
    ) * 12;

  const hoa = get(overrides, 'monthlyPayments.hoa', HOADues);

  const annualHomeInsurance =
    get(overrides, 'monthlyPayments.homeownersInsurance', homeInsurance / 12) *
    12;

  const outputs = calculateOutputs({
    ...inputs,
    annualPropertyTax,
    annualHomeInsurance,
    hoa,
  });

  return {
    ...outputs,
    compare: {
      30: buildCompareData(
        annualInterestRates,
        {
          ...inputs,
          annualPropertyTax,
          annualHomeInsurance,
          hoa,
        },
        30
      ),
      15: buildCompareData(
        annualInterestRates,
        {
          ...inputs,
          annualPropertyTax,
          annualHomeInsurance,
          hoa,
        },
        15
      ),
    },
  };
};

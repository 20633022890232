// Dependencies
// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import { assign, get } from 'lodash';
import nets from 'nets';
import qs from 'qs';

export const BASE_URI = 'BASE_URI';
export const DATA_URL = 'DATA_URL';
export const MORTGAGES_URL = 'MORTGAGES_URL';
export const SERVICES_URL = 'SERVICES_URL';
export const HOMEOWNERSHIP_URL = 'HOMEOWNERSHIP_URL';

/*
 * Resolve up the url we need to hit. The node server cannot hit the public URL and customers can't
 * hit the private url.
 * The process.env variable only gets set when packaging the js bundle that runs in the browser
 */
function resolveURL(api = DATA_URL, inBrowser = false) {
  return {
    MORTGAGES_URL: inBrowser
      ? process.env.MORTGAGES_URL_PUBLIC
      : process.env.MORTGAGES_URL_PRIVATE,
    SERVICES_URL: process.env.SERVICES_URL_PRIVATE,
    HOMEOWNERSHIP_URL: process.env.HOMEOWNERSHIP_BASE_URL,
  }[api];
}
/**
 * Utility module for fetching data from the data URL
 *
 */
export function fetch(
  slug,
  requestParams = {},
  queryParams = {},
  method = 'POST',
  callback = () => {},
  callbackErr = () => {},
  api = HOMEOWNERSHIP_URL
) {
  const url = resolveURL(api, process.env.IN_BROWSER);

  // Set up the query params
  const params = {
    client_id: process.env.CLIENT_ID,
    ...queryParams,
  };

  const urlAndSlug = url + slug;
  const request = assign(
    {
      url: `${urlAndSlug}?${qs.stringify(params, { indices: false })}`,
      method,
      encoding: 'utf8',
      timeout: 10000,
      json: true,
    },
    requestParams
  );

  // Make a request with the given request params
  return nets(request, (err, res, body) => {
    if (err || (res && res.statusCode >= 400)) {
      callbackErr({
        url,
        statusCode: get(res, 'statusCode', 'unknown'),
        err,
        bodyError: get(body, 'error'),
        innerError: get(body, 'error.data.inner_error'),
      });
      return;
    }

    callback(body);
  });
}

// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import { get, isEmpty, omit, pick } from 'lodash';

// Utils
import getReducerInfo from './get-reducer-info';

const shouldLoadProfile = (resultsReducer) =>
  isEmpty(get(resultsReducer, 'manualInputs')) &&
  isEmpty(get(resultsReducer, 'overrides'));

export default function getSavedProfile(state, identity) {
  const app = get(state, 'app');
  const reducerInfo = getReducerInfo(app);
  if (!reducerInfo) {
    return null;
  }

  const results = get(state, reducerInfo.name);
  let profilePath = `profiles.home_calculator_profile`;

  if (!get(identity, 'isSignedIn')) {
    profilePath = `visitorData.${profilePath}`;
  }

  const savedProfile = omit(
    pick(get(identity, profilePath), reducerInfo.include),
    reducerInfo.exclude
  );
  if (savedProfile && shouldLoadProfile(results)) {
    return savedProfile;
  }

  return null;
}

import isMobile from 'ismobilejs';
import _ from 'lodash';

import { SCREEN_MEDIUM, SCREEN_LARGE } from '@nerdwallet/react-media-queries';

import { BROWSER_MATCH_MEDIA, BROWSER_WINDOW_RESIZE } from '../lib/actions';

export default (
  state = {
    isMobile: isMobile.phone,
    isTablet: isMobile.tablet,
  },
  action = {}
) => {
  switch (action.type) {
    case BROWSER_WINDOW_RESIZE: {
      // Do we have a user agent provided ?
      if (action.userAgent !== '') {
        // Use that to calculate the isMobile flag.
        const isMobileHelper = isMobile(action.userAgent);

        return {
          isMobile: isMobileHelper.phone,
          isTablet: isMobileHelper.tablet,
        };

        // If no user agent is provided, calculate it based on screen width.
      }
      if (typeof window !== 'undefined') {
        const { innerWidth } = window;

        return {
          isMobile: innerWidth < SCREEN_MEDIUM,
          isTablet: innerWidth >= SCREEN_MEDIUM && innerWidth < SCREEN_LARGE,
        };
      }

      // should be unreachable
      return state;
    }

    case BROWSER_MATCH_MEDIA:
      return {
        isMobile: !_.isUndefined(action.isMobile)
          ? action.isMobile
          : state.isMobile,
        isTablet: !_.isUndefined(action.isTablet)
          ? action.isTablet
          : state.isTablet,
      };

    default:
      return state;
  }
};

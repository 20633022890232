import { MONTHLY_PAYMENT_GOAL } from './goals';

const inputs = {
  mortgageBalance: 300000,
  currentMortgageInterestRate: 0.05125,
  currentLoanTerm: 30,
  loanPurpose: 'refinance',
  refinanceInterestRate: 0.04,
  refinanceLoanTerm: 30,
  closingCostsFraction: 0.03,
  cashout: 0,
  howLongStay: 5,
  costOfCapital: 0.0,
  originationYear: 2010,
  originationMonth: new Date().getMonth(),
  // Allow unit test to override current date
  currentYear: new Date().getFullYear(),
  currentMonth: new Date().getMonth(),
  refinanceAddress: {
    city: 'San Francisco',
    state: 'CA',
  },
  refinanceGoal: MONTHLY_PAYMENT_GOAL.value,
};

export default inputs;

export const TAX_STATUS = {
  INDIVIDUAL: 'individual',
  JOINT: 'joint',
};

export const HOUSEHOLD_INCOME = {
  INDIVIDUAL: 54000,
  JOINT: 109000,
};

export const CAPITAL_GAINS = {
  INDIVIDUAL: 250000,
  JOINT: 500000,
};

export const MAX_INTEREST_DEDUCTION_MORTGAGE = {
  INDIVIDUAL: 500000,
  JOINT: 1000000,
};

export const LOAN_TERM = {
  FIFTEEN: '15',
  THIRTY: '30',
};

export const LOAN_TERM_LABEL = {
  FIFTEEN: '15 years',
  THIRTY: '30 years',
};

export const TAX_STATUS_LABEL = {
  INDIVIDUAL: 'Individual',
  JOINT: 'Joint',
};

export const SCHEDULE_DURATION = 30;

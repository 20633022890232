// Actions
import { RECEIVE_PROGRAMS, REQUEST_ERROR } from '../lib/actions/fthb-program';

export default (state = {}, action = {}) => {
  let newState = state;

  switch (action.type) {
    case RECEIVE_PROGRAMS:
      newState = action.program;
      break;

    case REQUEST_ERROR:
    default:
      break;
  }

  // Return the new state.
  return newState;
};

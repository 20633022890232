import { getLogger } from '@nerdwallet/logging';

import { fetch, HOMEOWNERSHIP_URL } from '../fetch';

export const RECEIVE_LAST_MORTGAGE_RATES = 'RECEIVE_LAST_MORTGAGE_RATES';

// Search for "actions" to look for all actions related log messages
const rootLogger = getLogger('actions');

export default ({ callback }) =>
  (dispatch) => {
    const params = {
      number_of_weeks: 1,
      exclude_most_recent_weeks: 0,
    };

    const fetchCallback = (rates) => {
      // Dispatch the receive rates action so that the reducers can update their state.
      const { data } = rates;

      if (data && Object.keys(data).length > 0) {
        Object.keys(data).forEach((key) => {
          data[key] = parseFloat(data[key]);
        });

        dispatch({
          type: RECEIVE_LAST_MORTGAGE_RATES,
          mortgage_rates: data,
        });
      }

      callback();
    };

    const fetchCallbackErr = (error) => {
      // search for "actions > fetchLastMortgageRates" to zero in on just this function
      const logger = getLogger('fetchLastMortgageRates', rootLogger);
      logger.error('fetchLastMortgageRates', error);
      callback();
    };

    fetch(
      '/v1/mortgage-rates/current',
      null,
      params,
      'GET',
      fetchCallback,
      fetchCallbackErr,
      HOMEOWNERSHIP_URL
    );
  };

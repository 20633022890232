// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import get from 'lodash/get';

import {
  LOAN_PROGRAM,
  LOAN_PROGRAM_ARM_3,
  LOAN_PROGRAM_ARM_5,
  LOAN_PROGRAM_ARM_7,
  LOAN_PROGRAM_ARM_10,
  LOAN_PROGRAM_FIXED_10,
  LOAN_PROGRAM_FIXED_15,
  LOAN_PROGRAM_FIXED_20,
  LOAN_PROGRAM_FIXED_30,
  LOAN_PURPOSE,
  LOAN_PURPOSE_PURCHASE,
  LOAN_PURPOSE_REFINANCE,
  PROPERTY_TYPE,
  PROPERTY_TYPE_HOUSE,
  PROPERTY_TYPE_TOWNHOME,
  PROPERTY_TYPE_CONDO,
  PROPERTY_TYPE_MULTI_FAMILY,
  PROPERTY_USE,
  PROPERTY_USE_PRIMARY,
  PROPERTY_USE_SECONDARY,
  PROPERTY_USE_INVESTMENT,
} from './data/constants';

import { bankruptcyAndForeclosureRange, creditScoreRange } from './data/ranges';

import {
  validateCashout,
  validateDownPayment,
  validateDownPaymentPercent,
  validateMortgageBalance,
  validateSinceLastBankruptcy,
  validateSinceLastForeclosure,
  validatePropertyValue,
  validatePurchasePrice,
  validateLoanPrograms,
} from '../../lib/validation';

import { getLoanProgramName } from '../../lib/mapping/helpers';

const MAX_PURCHASE_PRICE = 10000000;

export const annualIncome = {
  name: 'annualIncome',
  type: 'integer',
  format: 'currency',
  minimum: 0,
  maximum: 10000000,
  viewConfig: {
    help: 'Combine your income if buying with a borrower',
  },
};

export const cashout = {
  name: 'cashout',
  type: 'integer',
  format: 'currency',
  validators: [validateCashout],
};

export const creditScore = {
  name: 'creditScore',
  type: 'integer',
  minimum: 300,
  maximum: 850,
  range: creditScoreRange,
  viewConfig: {
    viewType: 'select',
    help: 'Applying with a co-borrower? Select the lowest credit score between the two borrowers',
    items: creditScoreRange.map((r) => ({
      value: r[0],
      label: r[1] ? `${r[0]}-${r[1]}` : `${r[0]}+`,
    })),
  },
};

export const downPayment = {
  name: 'downPayment',
  type: 'integer',
  minimum: 0,
  format: 'currency',
  validators: [validateDownPayment],
};

export const downPaymentPercent = {
  name: 'downPaymentPercent',
  type: 'float',
  format: 'percentage',
  validators: [validateDownPaymentPercent],
  minimum: 0,
  maximum: 1,
};

export const fha = {
  name: 'fha',
  type: 'boolean',
  viewConfig: {
    items: [
      {
        label: 'Yes',
        value: true,
      },
      {
        label: 'No',
        value: false,
      },
    ],
  },
};

export const hasBankruptcy = {
  name: 'hasBankruptcy',
  type: 'boolean',
  viewConfig: {
    items: [
      {
        label: 'Yes',
        value: true,
      },
      {
        label: 'No',
        value: false,
      },
    ],
  },
};

export const hasForeclosure = {
  name: 'hasForeclosure',
  type: 'boolean',
  viewConfig: {
    items: [
      {
        label: 'Yes',
        value: true,
      },
      {
        label: 'No',
        value: false,
      },
    ],
  },
};

// Update this and use LOAN_PROGRAM INSTEAD WHEN CLEANING UP THE EXPERIMENT IMPLEMENTATION
const LOAN_PROGRAM_ARM_UPDATES = [
  LOAN_PROGRAM_FIXED_30,
  LOAN_PROGRAM_FIXED_20,
  LOAN_PROGRAM_FIXED_15,
  LOAN_PROGRAM_FIXED_10,
  LOAN_PROGRAM_ARM_7,
  LOAN_PROGRAM_ARM_5,
  LOAN_PROGRAM_ARM_3,
  LOAN_PROGRAM_ARM_10,
];

export const loanProgram = {
  name: 'loanProgram',
  type: 'string',
  whitelist: LOAN_PROGRAM,
  viewConfig: {
    viewType: 'select',
    items: LOAN_PROGRAM_ARM_UPDATES.map((value) => ({
      label: getLoanProgramName(value),
      value,
    })),
  },
};

export const loanPrograms = {
  name: 'loanPrograms',
  type: 'array',
  whitelist: LOAN_PROGRAM,
  viewConfig: {
    viewType: 'select',
    multiple: true,
    items: LOAN_PROGRAM_ARM_UPDATES.map((value) => ({
      label: getLoanProgramName(value),
      value,
    })),
  },
  validators: [validateLoanPrograms],
};

// export const loanProgramsARMUpdates = {
//   name: 'loanPrograms',
//   type: 'array',
//   whitelist: LOAN_PROGRAM,
//   viewConfig: {
//     viewType: 'select',
//     multiple: true,
//     items: LOAN_PROGRAM_ARM_UPDATES.map((value) => ({
//       label: getLoanProgramName(value),
//       value,
//     })),
//   },
//   validators: [validateLoanPrograms],
// };

export const loanPurpose = {
  name: 'loanPurpose',
  type: 'string',
  whitelist: LOAN_PURPOSE,
  viewConfig: {
    items: [
      {
        label: 'Purchase', // FIXME helper?
        value: LOAN_PURPOSE_PURCHASE,
      },
      {
        label: 'Refinance',
        value: LOAN_PURPOSE_REFINANCE,
      },
    ],
  },
};

export const mortgageBalance = {
  name: 'mortgageBalance',
  type: 'integer',
  format: 'currency',
  validators: [validateMortgageBalance],
};

export const propertyType = {
  name: 'propertyType',
  type: 'string',
  whitelist: PROPERTY_TYPE,
  viewConfig: {
    items: [
      {
        label: 'Single family home',
        value: PROPERTY_TYPE_HOUSE,
      },
      {
        label: 'Townhouse',
        value: PROPERTY_TYPE_TOWNHOME,
      },
      {
        label: 'Condo',
        value: PROPERTY_TYPE_CONDO,
      },
      {
        label: 'Multi-family home',
        value: PROPERTY_TYPE_MULTI_FAMILY,
      },
    ],
  },
};

export const propertyUse = {
  name: 'propertyUse',
  type: 'string',
  whitelist: PROPERTY_USE,
  viewConfig: {
    items: [
      {
        label: 'Primary residence',
        value: PROPERTY_USE_PRIMARY,
      },
      {
        label: 'Secondary residence',
        value: PROPERTY_USE_SECONDARY,
      },
      {
        label: 'Investment property',
        value: PROPERTY_USE_INVESTMENT,
      },
    ],
  },
};

export const propertyValue = {
  name: 'propertyValue',
  type: 'integer',
  format: 'currency',
  minimum: 1,
  maximum: MAX_PURCHASE_PRICE,
  validators: [validatePropertyValue],
};

export const purchasePrice = {
  name: 'purchasePrice',
  type: 'integer',
  format: 'currency',
  minimum: 1,
  maximum: MAX_PURCHASE_PRICE,
  validators: [validatePurchasePrice],
  // cant validate the number
  // normally purchase price >= loan amount but for VA loans with $0 down payment,
  // purchase price < loan amount because of fees
};

export const sinceLastBankruptcy = {
  name: 'sinceLastBankruptcy',
  type: 'integer',
  minimum: 0,
  maximum: 85,
  range: bankruptcyAndForeclosureRange,
  validators: [validateSinceLastBankruptcy],
  viewConfig: {
    isVisible(data) {
      return get(data, 'resolved.hasBankruptcy');
    },
    viewType: 'select',
    items: [
      {
        label: 'Not yet discharged',
        value: 0,
      },
      {
        label: '1 to 12 mos. ago',
        value: 1,
      },
      {
        label: '13 to 24 mos. ago',
        value: 13,
      },
      {
        label: '25 to 36 mos. ago',
        value: 25,
      },
      {
        label: '37 to 48 mos. ago',
        value: 37,
      },
      {
        label: '49 to 60 mos. ago',
        value: 49,
      },
      {
        label: '61 to 72 mos. ago',
        value: 61,
      },
      {
        label: '73 to 84 mos. ago',
        value: 73,
      },
      {
        label: 'Not in last 7 years',
        value: 85,
      },
    ],
  },
};

export const sinceLastForeclosure = {
  name: 'sinceLastForeclosure',
  type: 'integer',
  minimum: 0,
  maximum: 85,
  range: bankruptcyAndForeclosureRange,
  validators: [validateSinceLastForeclosure],
  viewConfig: {
    isVisible(data) {
      return get(data, 'resolved.hasForeclosure');
    },
    viewType: 'select',
    items: [
      {
        label: 'Not yet discharged',
        value: 0,
      },
      {
        label: '1 to 12 mos. ago',
        value: 1,
      },
      {
        label: '13 to 24 mos. ago',
        value: 13,
      },
      {
        label: '25 to 36 mos. ago',
        value: 25,
      },
      {
        label: '37 to 48 mos. ago',
        value: 37,
      },
      {
        label: '49 to 60 mos. ago',
        value: 49,
      },
      {
        label: '61 to 72 mos. ago',
        value: 61,
      },
      {
        label: '73 to 84 mos. ago',
        value: 73,
      },
      {
        label: 'Not in last 7 years',
        value: 85,
      },
    ],
  },
};

export const veteran = {
  name: 'veteran',
  type: 'boolean',
  viewConfig: {
    items: [
      {
        label: 'Yes',
        value: true,
      },
      {
        label: 'No',
        value: false,
      },
    ],
  },
};

// TODO used?
export const loanDuration = {
  name: 'loanDuration',
  type: 'integer',
  minimum: 1,
  maximum: 30,
  viewConfig: {
    viewType: 'stepper',
    editable: true,
    stepSize: 1,
    formatter: (val) => (val <= 1 ? `${val} year` : `${val} years`),
  },
};

// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import { assign } from 'lodash';

import transform from '@nerdwallet/transform';

/**
 * Grab and return inputs from query string with optional transform
 * @param  {Object} query        Object of query params; e.g. `req.query`
 * @param  {Array}  [allowed=[]] Names of inputs to pull off query
 * @param  {Object} [mapping={}] Transform schema @see @nerdwallet/transform
 * @return {Object}              Parsed inputs
 */
// eslint-disable-next-line func-names -- directive added automatically by Shepherd migration
export default function (query, allowed = [], mapping = {}) {
  return Object.keys(query).reduce((acc, key) => {
    if (allowed.indexOf(key) !== -1) {
      assign(acc, { [key]: query[key] });
    }

    return acc;
  }, transform(mapping, query));
}

export const MONTHLY_PAYMENT_GOAL = {
  label: 'Cutting monthly payments',
  value: 'monthly_payments',
};

export const TOTAL_MORTGAGE_INTEREST = {
  label: 'Reducing total mortgage interest',
  value: 'total_mortgage_interest',
};

export const goals = [MONTHLY_PAYMENT_GOAL, TOTAL_MORTGAGE_INTEREST];

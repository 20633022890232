export default [
  {
    label: 'Asian population',
    value: 'percent_asian',
    formatter: '0.0%',
    columnName: 'Percent',
  },
  {
    label: 'Average 6-pack Heineken beer cost',
    value: 'heineken_cost',
    formatter: '$0.00',
    columnName: 'Percent',
  },
  {
    label: 'Average annual income per capita',
    value: 'annual_per_capita_income',
    formatter: '$0,0',
    columnName: 'Cost',
  },
  {
    label: 'Average apartment rent cost',
    value: 'apt_rent_cost',
    formatter: '$0,0',
    columnName: 'Cost',
  },
  {
    label: 'Average home cost',
    value: 'home_cost',
    formatter: '$0,0',
    columnName: 'Cost',
  },
  {
    label: 'Average commute time to work',
    value: 'average_travel_time_to_work_min',
    formatter: '0.0',
    columnName: 'Minutes',
  },
  {
    label: 'Average milk cost',
    value: 'milk_cost',
    formatter: '$0.00',
    columnName: 'Cost',
  },
  {
    label: 'Average mortgage plus interest cost',
    value: 'mortgage_plus_interest_cost',
    formatter: '$0,0',
    columnName: 'Cost',
  },
  {
    label: 'Bars per 1000 people',
    value: 'bars_per_1000_people',
    formatter: '0.00',
    columnName: 'Amount',
  },
  {
    label: 'Black population',
    value: 'percent_black',
    formatter: '0.0%',
    columnName: 'Percent',
  },
  {
    label: 'Composite cost of living',
    value: 'composite_index',
    columnName: 'Composite index',
  },
  {
    label: 'Composite cost of food',
    value: 'grocery_index',
    columnName: 'Composite index',
  },
  {
    label: 'Composite cost of housing',
    value: 'housing_index',
    columnName: 'Composite index',
  },
  {
    label: 'Greatschools rating',
    value: 'greatschools_rating',
    columnName: 'Rating out of 10',
  },
  {
    label: 'Latino or Hispanic population',
    value: 'percent_latino_or_hispanic',
    formatter: '0.0%',
    columnName: 'Percent',
  },
  {
    label: 'Median age',
    value: 'median_age',
    formatter: '0.0',
    columnName: 'Amount',
  },
  {
    label: 'Median household income',
    value: 'median_salary',
    formatter: '$0,0',
    columnName: 'Cost',
  },
  {
    label: 'People per household',
    value: 'persons_per_household',
    formatter: '0.00',
    columnName: 'Amount',
  },
  {
    label: 'Percent Women',
    value: 'percent_women',
    formatter: '0.0%',
    columnName: 'Percent',
  },
  {
    label: 'Percent below poverty',
    value: 'percent_below_poverty',
    formatter: '0.0%',
    columnName: 'Percent',
  },
  {
    label: 'Percent foreign born',
    value: 'percent_foreign_born',
    formatter: '0.0%',
    columnName: 'Percent',
  },
  {
    label: 'Percent population change (2010-2011)',
    value: 'percent_population_change',
    formatter: '0.0%',
    columnName: 'Percent',
  },
  {
    label: "Percent population with a Bachelor's degree",
    value: 'percent_college',
    formatter: '0.0%',
    columnName: 'Percent',
  },
  {
    label: 'Precent home ownership rate',
    value: 'percent_home_ownership_rate',
    formatter: '0.0%',
    columnName: 'Percent',
  },
  {
    label: 'Public transit rating',
    value: 'public_transit_score',
    columnName: 'Amount',
  },
  {
    label: 'Ratio of unmarried men to women',
    value: 'ratio_unmarried_men_women',
    formatter: '0.0',
    columnName: 'Amount',
  },
  {
    label: 'Senior population (over 65)',
    value: 'age_65_plus',
    formatter: '0.0%',
    columnName: 'Percent',
  },
  {
    label: 'Unemployment rate',
    value: 'unemployment_rate',
    formatter: '0.0%',
    columnName: 'Percent',
  },
  {
    label: 'Violent crime rate per 100,000 inhabitants',
    value: 'violent_crime_rate',
    columnName: 'Amount',
  },
  {
    label: 'White population',
    value: 'percent_white',
    formatter: '0.0%',
    columnName: 'Percent',
  },
  {
    label: 'Youth population (under 18)',
    value: 'age_under_18',
    formatter: '0.0%',
    columnName: 'Percent',
  },
];

// Utils
import cityLife from '../../../reducers/cl/city-life';
import addRouteBasedReducers from '../../add-route-based-reducers';
import pluckFromQuery from '../../pluck-from-query';

// Reducers

// Actions
import clBootstrapApp from '../cl-actions';

import handleNewSlug from './handleSlug';

export default function bootstrapCityLife(store, req, res) {
  return (nextState, replaceState, callback) => {
    addRouteBasedReducers(
      store,
      {
        cityLife,
      },
      'cl'
    )();

    if (req && res) {
      // We are on the server, so bootstrap app
      store.dispatch(
        clBootstrapApp(req.cache, {
          name: 'cityLife',
          callback: () => {
            // pass in the callback here so we don't render the page before fetching all data
            handleNewSlug(
              nextState.params,
              store.dispatch,
              store.getState,
              res,
              callback
            );
          },
          update: {
            // TODO: support query params
            inputs: pluckFromQuery(req.query, ['']),
          },
          query: req.query,
          ip: req.ip,
        })
      );
    } else {
      // On the client, so don't do anything
      callback();
    }
  };
}

// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import { get } from 'lodash';

// eslint-disable-next-line import/no-cycle -- directive added automatically by Shepherd migration
import { UPDATE_DEFAULT_CITY } from '../actions';

import fetchCitiesByZipCode from './fetchCitiesByZipCode';
import fetchZipByCity, { UPDATE_RESOLVED_ZIP } from './fetchZipByCity';

export default function getLocation(data, inputs) {
  return (dispatch) =>
    new Promise((callback) =>
      new Promise((resolve) => {
        const zip = get(data, 'query.zip', get(data, 'query.zipCode'));

        if (zip) {
          dispatch(
            fetchCitiesByZipCode({
              zip,
              callback: (cities) => {
                resolve({
                  cities,
                  zip,
                });
              },
            })
          );
        } else {
          resolve({ cities: [get(data, 'query.city')] });
        }
      })
        .then(({ cities }) => {
          let { city: cityInput, state: stateInput } = inputs;

          if (cities && cities[0]) {
            const locationInfo = cities[0].split(',');

            if (locationInfo.length === 2) {
              cityInput = locationInfo[0].trim();
              stateInput = locationInfo[1].trim();

              dispatch({
                type: UPDATE_DEFAULT_CITY,
                city: cityInput,
                state: stateInput,
              });

              return Promise.resolve({ city: cityInput, state: stateInput });
            }
          }

          return new Promise((resolve) => {
            const headers = data?.headers;
            const city = headers?.['x-cf-request-city'];
            const state = headers?.['x-cf-request-region'];
            const zipCode = headers?.['x-cf-request-postalcode'];

            if (city || state || zipCode) {
              dispatch({
                type: UPDATE_DEFAULT_CITY,
                city,
                state,
                zipCode,
              });
              resolve({ city, state, zip: zipCode });
            } else {
              resolve();
            }
          });
        })
        .then((location) => {
          if (location) {
            const { city, state, zip } = location;

            if (zip) {
              dispatch({
                type: UPDATE_RESOLVED_ZIP,
                zip,
              });

              callback();
            } else {
              dispatch(fetchZipByCity({ city, state, callback }));
            }
          } else {
            callback();
          }
        })
    );
}

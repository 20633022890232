// Utils
// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import _, { assign, cloneDeep, isEmpty, merge, get } from 'lodash';

import calculateBudgetDelta from '@nerdwallet/calculate-home-affordability/calculate-budget-delta';
import calculateHouseValue from '@nerdwallet/calculate-home-affordability/calculate-house-value';
import { fromHomeCalculatorProfile } from '@nerdwallet/mortgage-utils/home-calculator-profile';
import {
  getAnnualInterestRateForCreditScoreRangeValue,
  getCreditScoreRangeForCreditScore,
} from '@nerdwallet/mortgage-utils/rate-adjustment';
import transform from '@nerdwallet/transform';

// Helpers
import defaultOutputs from '../components/home-affordability-calculator/default-outputs';
import inputLibrary from '../components/rentvsbuy-calculator/control-panel/shared/input-library';
import { sanitizeInputs } from '../components/inputs/sanitize';
// Actions

import {
  INPUT_CHANGE,
  LOAN_TERM_CHANGE,
  OVERRIDES_CHANGE,
  OVERRIDES_CALCULATION,
  POST_ONBOARDING_CALCULATE,
  RESET_CALCULATIONS,
  RESOLVE_CALCULATIONS,
  SAVE_HOME_CALCULATOR_ADDRESS,
  SIGN_IN,
  UPDATE_DEFAULT_CITY,
  UPDATE_DEFAULT_RATE,
} from '../lib/actions';
import { UPDATE_RESOLVED_ZIP } from '../lib/actions/fetchZipByCity';
import { calculateInputs } from '../lib/calculators/hac/inputs';

/**
 * The initial state of the home affordability calculator reducer state.
 *
 */
function getInitialState() {
  return {
    inputs: calculateInputs(),
    manualInputs: {},
    overrides: {},
    outputs: defaultOutputs,
    adjustedOutputs: {},
    delta: {},
    pastOnboarding: false,
    emailInfo: {},
  };
}

const removeEmptyStrings = (obj) =>
  _.pickBy(obj, (value) =>
    _.isObject(value) ? removeEmptyStrings(value) : value !== ''
  );

function calculationAdaptor(reducer) {
  return calculateHouseValue(
    removeEmptyStrings(
      transform(
        {
          creditScore: {
            value: 'inputs.creditScoreRangeValue',
            whitelist: {
              '720-850': 720,
              '690-719': 690,
              '630-689': 630,
              '350-629': 350,
            },
          },
          dti: 'overrides.dti',
          downPayment: 'overrides.downPayment',
          fundsNeeded: 'overrides.fundsNeeded',
          monthlyHoaDues: 'overrides.monthlyPayments.hoa' || 'inputs.HOADues',
          monthlyHomeownersInsurance:
            'overrides.monthlyPayments.homeownersInsurance',
          monthlyMortgage: 'overrides.monthlyPayments.mortgage',
          monthlyMortgageInsurance:
            'overrides.monthlyPayments.mortgageInsurancePayment',
          monthlyPropertyTax: 'overrides.monthlyPayments.propertyTax',
          annualIncome: 'inputs.income',
          monthlyDebt: 'inputs.monthlyDebt',
          loanTerm: 'inputs.loanTerm',
          annualInterestRate: 'inputs.annualInterestRate',
          veteranType: 'inputs.veteranType',
          firstVaLoan: 'inputs.firstVaLoan',
          state: 'inputs.purchaseAddress.state',
        },
        reducer
      )
    )
  );
}

function adjustInterestRateForLoanTerm(inputs, annualInterestRates) {
  if (annualInterestRates.fixed_30) {
    const loanTerm = (
      (inputs.loanTerm === 15 && inputs.loanTerm) ||
      (inputs.loanTerm === 30 && inputs.loanTerm) ||
      30
    ).toString();
    const defaultRate =
      Math.round(annualInterestRates[`fixed_${loanTerm}`] * 10) / 1000;
    return {
      annualInterestRate: defaultRate,
      baseAnnualInterestRate: defaultRate,
    };
  }
  return {
    annualInterestRate: inputs.annualInterestRate,
    baseAnnualInterestRate: inputs.baseAnnualInterestRate,
  };
}

function adjustInterestRateForCreditScore(inputs, creditScore) {
  const { baseAnnualInterestRate, loanTerm, purchaseAddress } = inputs;
  const creditScoreRangeValue = creditScore
    ? getCreditScoreRangeForCreditScore(creditScore).value
    : inputs.creditScoreRangeValue;

  const annualInterestRate = getAnnualInterestRateForCreditScoreRangeValue({
    creditScoreRangeValue,
    baseAnnualInterestRate,
    loanTerm,
    state: purchaseAddress.state,
  });

  return {
    creditScoreRangeValue,
    annualInterestRate,
    annualInterestRateForCreditScoreRange: annualInterestRate,
    useCreditScoreForInterestRate: true,
  };
}

export default (state = getInitialState(), action = {}) => {
  let newState = state;
  const { type } = action;

  // Is the state an empty object ? The reducer is probably being added.
  if (isEmpty(state)) {
    return getInitialState();
  }

  switch (type) {
    case SIGN_IN: {
      newState = cloneDeep(state);

      const profile =
        get(action, 'payload.identity.profiles.home_calculator_profile') ||
        get(
          action,
          'payload.identity.visitorData.profiles.home_calculator_profile'
        );

      const formattedProfile = fromHomeCalculatorProfile(profile);
      newState.pastOnboarding = formattedProfile.hasCompletedOnboardingHac;

      const sanitizedInputs = sanitizeInputs(inputLibrary, formattedProfile);

      _.assign(formattedProfile, sanitizedInputs);

      const useDefaultIfNull = (defaultValue, profileValue, key) => {
        // Users can save a loan term of 1-30 in other calcs so if it fits here we can use it otherwise
        // use default

        if (key === 'loanTerm') {
          if (profileValue === 15 || profileValue === 30) {
            return profileValue;
          }
          return defaultValue;
        }
        // Dont use the saved rate otherwise the calculation will be wrong and the UI will not reflect it
        if (key === 'annualInterestRate') return defaultValue;
        if (profileValue === null || profileValue === undefined) {
          return defaultValue;
        }
        return profileValue;
      };
      _.assignInWith(newState.inputs, formattedProfile, useDefaultIfNull);
      // Pick the correct base rate based on their loan term provided
      const newRates = adjustInterestRateForLoanTerm(
        newState.inputs,
        newState.annualInterestRates
      );
      _.assign(newState.inputs, newRates);
      // Then adjust the interest rate based on credit score
      _.assign(
        newState.inputs,
        adjustInterestRateForCreditScore(newState.inputs)
      );

      // Leave manual inputs blank so they use placeholder
      _.assign(newState.manualInputs, sanitizedInputs);

      if (formattedProfile.purchaseAddress) {
        newState.manualInputs.purchaseAddress =
          formattedProfile.purchaseAddress;
      }

      _.assign(
        newState.outputs,
        (newState.adjustedOutputs = calculationAdaptor(newState))
      );

      const creditScore = get(
        action,
        'payload.identity.profiles.tu_credit_report_profile.vantage_score_3'
      );

      if (creditScore) {
        _.assign(
          newState.inputs,
          adjustInterestRateForCreditScore(newState.inputs, creditScore)
        );
        newState.hasLinkedCreditScore = true;

        _.assign(
          newState.outputs,
          (newState.adjustedOutputs = calculationAdaptor(newState))
        );
      }

      break;
    }

    case POST_ONBOARDING_CALCULATE:
    // Fall through
    case RESET_CALCULATIONS:
    // Fall through
    case RESOLVE_CALCULATIONS:
    // Fall through
    case INPUT_CHANGE: {
      // Clone the current state to build the new state
      newState = cloneDeep(state);

      // We only want to reset overrides if there was an input change or whether we perform an
      // explicit reset.
      if (newState.pastOnboarding) {
        // Clear out the overrides and everything it affects
        newState.overrides = {};
        newState.delta = {};
      }

      // Is atleast one of the two objects not empty ?
      if (!(isEmpty(action.manualInputs) && isEmpty(action.inputs))) {
        // Append the new inputs to manual inputs
        assign(newState.manualInputs, action.manualInputs, action.inputs);

        // Merge the manual inputs into the inputs.
        assign(newState.inputs, newState.manualInputs);
        // Calculate the inputs
        newState.inputs = calculateInputs(
          newState.inputs,
          newState.manualInputs
        );
      }

      // Set the new flag for testing error scenarios if we're past onboarding.
      newState.pastOnboarding =
        newState.pastOnboarding || type === POST_ONBOARDING_CALCULATE;

      if (action.type !== INPUT_CHANGE) {
        assign(
          newState.outputs,
          (newState.adjustedOutputs = calculationAdaptor(newState))
        );
      }

      break;
    }

    case OVERRIDES_CHANGE: {
      // Clone the current state to build the new state
      newState = cloneDeep(state);

      // Merge since extend doesn't deep copy.
      merge(newState.overrides, action.overrides);

      break;
    }

    case OVERRIDES_CALCULATION: {
      newState = cloneDeep(state);
      // Update the adjusted outputs to reflect the new changes from the overrides.
      // Do not assign to outputs because these are reflective only of the overrides.
      newState.adjustedOutputs = calculationAdaptor(newState);

      // Append the delta to the state.
      newState.delta = calculateBudgetDelta(newState);

      break;
    }

    case UPDATE_RESOLVED_ZIP: {
      newState = cloneDeep(state);
      newState.outputs.zip = action.zip;

      break;
    }

    case UPDATE_DEFAULT_CITY: {
      const { city, zipCode } = action;

      newState = cloneDeep(state);

      newState.inputs.purchaseAddress = {
        city,
        state: action.state,
      };
      newState.inputs.zipCode = zipCode;

      break;
    }
    case SAVE_HOME_CALCULATOR_ADDRESS:
      newState = cloneDeep(state);
      if (!isEmpty(action.address)) {
        assign(newState.inputs.purchaseAddress, action.address);
      }
      break;

    case LOAN_TERM_CHANGE: {
      newState = cloneDeep(state);
      newState.annualInterestRates = action.annualInterestRates;

      newState.inputs.loanTerm = Number(action.inputs.loanTerm);

      const newRates = adjustInterestRateForLoanTerm(
        newState.inputs,
        action.annualInterestRates
      );
      _.assign(newState.inputs, newRates);
      break;
    }
    // Fall through
    case UPDATE_DEFAULT_RATE: {
      newState = cloneDeep(state);
      newState.annualInterestRates = action.annualInterestRates;
      break;
    }

    default:
    // Do nothing.
  }

  // Return the new state.
  return newState;
};

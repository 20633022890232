// Utils
// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import { get, find, has } from 'lodash';

// Actions
import { bootstrapAppWithPromises } from '../actions';
import {
  fetchCityData,
  fetchCityLifeCities,
  fetchFieldDetails,
  fetchCities,
  FETCH_COMPARE_CITY_DATA,
  FETCH_CITY_LIFE_CITIES,
  FETCH_CITIES,
  FETCH_FIELD_DETAILS,
} from '../actions/fetchColData';

export const CITY_CHANGE = 'CITY_CHANGE';
export const COMPARE_FIELD_CHANGE = 'COMPARE_FIELD_CHANGE';
export const CALCULATE_COMPARE_DATA = 'CALCULATE_COMPARE_DATA';

// eslint-disable-next-line func-names -- directive added automatically by Shepherd migration
export default function (cache, data) {
  return (dispatch) => {
    const promises = [];

    promises.push(
      new Promise((callback) => {
        Promise.all([
          new Promise((resolve) =>
            dispatch(fetchCityLifeCities({ callback: resolve }))
          ),
          new Promise((resolve) =>
            dispatch(fetchCities({ callback: resolve }))
          ),
          new Promise((resolve) =>
            dispatch(fetchFieldDetails({ callback: resolve }))
          ),
        ])
          .then(([cityLifeCities, cities, fieldDetails]) => {
            dispatch({
              type: FETCH_CITY_LIFE_CITIES,
              data: cityLifeCities,
            });
            dispatch({
              type: FETCH_CITIES,
              data: cities,
            });
            dispatch({
              type: FETCH_FIELD_DETAILS,
              data: fieldDetails,
            });
            callback();
          })
          .catch();
      })
    );

    dispatch(
      bootstrapAppWithPromises({
        promises,
        ...data,
      })
    );
  };
}

export function cityChange({ name, inputs, slug }, callback = () => {}) {
  return (dispatch, getState) => {
    const currentState = get(getState(), name);
    const { cities } = currentState;

    const modifiedInputs = inputs;
    if (slug) {
      const city = find(cities, { slug });
      modifiedInputs.city = get(city, 'id');
    }

    dispatch({
      type: CITY_CHANGE,
      inputs: modifiedInputs,
    });

    const cityData = find(cities, { id: modifiedInputs.city });

    if (!has(cityData, 'ranking')) {
      dispatch(
        fetchCityData({
          ids: [modifiedInputs.city],
          callback: (data) => {
            dispatch({
              type: FETCH_COMPARE_CITY_DATA,
              data,
            });
            callback();
          },
        })
      );
    } else {
      callback();
    }
  };
}

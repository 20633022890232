export const loanTerm = {
  name: 'loanTerm',
  type: 'integer',
  minimum: 1,
  maximum: 30,
  format: 'number',
};

export const refinanceLoanTerm = {
  name: 'refinanceLoanTerm',
  type: 'integer',
  minimum: 1,
  maximum: 30,
  format: 'number',
};

export const currentLoanTerm = {
  name: 'currentLoanTerm',
  type: 'integer',
  minimum: 1,
  maximum: 30,
  format: 'number',
};

export const monthlyDebt = {
  name: 'monthlyDebt',
  type: 'integer',
  format: 'currency',
  minimum: 0,
};

export const refinanceInterestRate = {
  name: 'refinanceInterestRate',
  type: 'float',
  minimum: 0.01,
  maximum: 1,
  format: 'percentage',
};

export const homeImprovementCost = {
  name: 'homeImprovementCost',
  type: 'integer',
  minimum: 0,
  maximum: 10000000,
  format: 'currency',
};

export const propertyTax = {
  name: 'propertyTax',
  type: 'integer',
  minimum: 0,
  maximum: 10000000,
  format: 'currency',
};

export const homeownersInsurance = {
  name: 'homeownersInsurance',
  type: 'integer',
  minimum: 0,
  maximum: 10000000,
  format: 'currency',
};

export const mortgageInsurance = {
  name: 'mortgageInsurance',
  type: 'integer',
  minimum: 0,
  maximum: 10000000,
  format: 'currency',
};

export const currentMortgageInterestRate = {
  name: 'currentMortgageInterestRate',
  type: 'float',
  format: 'percentage',
  minimum: 0.01,
  maximum: 1,
};

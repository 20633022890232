// Utils
// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import { assign, forEach } from 'lodash';

import { getAnnualInterestRateForCreditScoreRangeValue } from '@nerdwallet/mortgage-utils/rate-adjustment';
import { Num as num } from '@nerdwallet/nw-client-lib';

// Configs
import initialInputs from '../../../components/home-affordability-calculator/default-inputs';
// Data
import costsModel from '../../../config/data/costs-model.json';

/**
 * Returns the loan term if overriden by the user.
 *
 * @returns {number}
 */
export function getLoanTerm(state) {
  return state.inputs.loanTerm;
}

/**
 * Returns the number of monthly payments the user will have to make.
 *
 * It depends on the loanTerm which defaults to 30.
 *
 * @returns {number}
 * @default 360
 */
export function getMonthlyPaymentCount(state) {
  return getLoanTerm(state) * 12;
}

/**
 * Returns the annual interest rate used for the calculation of the the home-buying tool.
 *
 * @returns {number}
 */
export function getAnnualInterestRate(state) {
  return state.inputs.annualInterestRate;
}

/**
 * Returns the costs model from the data file and also maintains a local cache for performance
 * optimization.
 *
 * @param {String} stateAbbrv The state abbreviation as provided in the inputs.
 *
 * @returns {object}
 */
export function getCostsModelForState(stateAbbrv) {
  return costsModel[stateAbbrv];
}

export function calculateInputs(inputs = initialInputs, manualInputs = {}) {
  // Setup default values for some of the inputs.
  forEach(
    {
      expenses: () => 0,
      savings: () => 0,
    },
    (defaultResolveFn, key) => {
      assign(inputs, {
        [key]:
          manualInputs[key] === undefined
            ? defaultResolveFn()
            : num.value(manualInputs[key]),
      });
    }
  );

  // Interest rate / credit score handling
  const annualInterestRateForCreditScoreRange =
    getAnnualInterestRateForCreditScoreRangeValue({
      ...inputs,
      state: inputs.purchaseAddress.state,
    });

  assign(inputs, { annualInterestRateForCreditScoreRange });

  if (inputs.useCreditScoreForInterestRate) {
    assign(inputs, {
      annualInterestRate: annualInterestRateForCreditScoreRange,
    });
  }

  if (inputs.firstVaLoan) {
    // eslint-disable-next-line no-param-reassign
    inputs.firstVaLoan =
      typeof inputs.firstVaLoan === 'boolean'
        ? inputs.firstVaLoan
        : inputs.firstVaLoan !== 'false';
  }

  return inputs;
}

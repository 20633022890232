import {
  LOAN_PURPOSE_REFINANCE,
  OFFER_LOAN_TYPE_HEL,
  OFFER_LOAN_TYPE_HELOC,
} from '@nerdwallet/home-constants';

export default [
  {
    showLenderCards: true,
    loanPurpose: 'cashOutRefi',
    inputs: {
      loanPurpose: LOAN_PURPOSE_REFINANCE,
    },
  },
  {
    showLenderCards: true,
    loanPurpose: 'heloc',
    inputs: {
      loanType: OFFER_LOAN_TYPE_HELOC,
    },
  },
  {
    showLenderCards: true,
    loanPurpose: 'homeEquityLoan',
    inputs: {
      loanType: OFFER_LOAN_TYPE_HEL,
    },
  },
  {
    loanPurpose: 'personalLoan',
  },
  {
    loanPurpose: 'creditCard',
  },
  {
    loanPurpose: 'badCreditScore',
  },
];

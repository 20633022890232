import _ from 'lodash';

import homeImprovementLenders from '../config/home-improvement-finance-calculator/lender-cards';

// eslint-disable-next-line import/no-cycle
import { FILTER_HIFC_LENDERS } from './actions';

// eslint-disable-next-line func-names -- directive added automatically by Shepherd migration
export default function () {
  return (dispatch, getState) => {
    const results = _.get(getState(), 'hifcCalculator.results');
    if (!_.isEmpty(results)) {
      const resultsConfig = _.find(
        homeImprovementLenders,
        (lenderCard) => lenderCard.loanPurpose === results.id
      );
      if (resultsConfig) {
        return dispatch({
          type: FILTER_HIFC_LENDERS,
          resultsConfig,
        });
      }
    }

    return Promise.resolve();
  };
}

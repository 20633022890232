const outputs = {
  zip: '94102',
  principal: 400000,
  downPaymentPct: 0.2,
  monthlyPayments: {},
  totalInterestPaid: 282509,
  compare: {
    30: {
      monthlyPayment: 2558,
      interestRate: 0.0268,
      totalInterestPaid: 241201,
    },
    15: {
      monthlyPayment: 3134,
      interestRate: 0.0359,
      totalInterestPaid: 143265,
    },
  },
};

export default outputs;

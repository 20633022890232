import _ from 'lodash';

import { getLogger } from '@nerdwallet/logging';
import { fetchHistoricalRates } from '@nerdwallet/mortgage-rates-trend/actions';
import { getProgramFromMarketplace } from './actions/fthb-program';

import {
  bootstrapApp,
  bootstrapAppWithPromises,
  setStateList,
} from './actions';
import bootstrapStateData from './actions/bootstrap-state-data';
import fetchHousingCharacteristics from './actions/fetchHousingCharacteristics';
import fetchLastMortgageRates from './actions/fetchLastMortgageRates';
import getLocation from './actions/get-location';
import pluckFromQuery from './pluck-from-query';
import { bootstrapRC } from './refinance-actions';

// Search for "bootstrap" to look for all actions related log messages
const rootLogger = getLogger('bootstrap');

export const acBootstrap = (store, req, res, callback) => {
  // Bootstrap the app with initial data.
  store.dispatch(
    bootstrapApp({
      name: 'mortgageCalculator',
      callback,
      update: {
        inputs: pluckFromQuery(req.query, ['homePrice', 'downpayment'], {
          downpayment: 'downPayment',
          homePrice: 'purchasePrice',
        }),
      },
      query: req.query,
      ip: req.ip,
      headers: req?.headers,
    })
  );
};

export const hacBootstrap = (store, req, res, callback) => {
  // Bootstrap the app with initial data.
  store.dispatch(
    bootstrapApp({
      name: 'results',
      callback,
      update: {
        inputs: pluckFromQuery(req.query, ['householdIncome', 'debt'], {
          householdIncome: 'annualIncome',
        }),
        overrides: pluckFromQuery(req.query, ['downPayment']),
      },
      query: req.query,
      ip: req.ip,
      headers: req?.headers,
    })
  );
};

export const hifcBootstrap = (store, req, res, callback) => {
  // Bootstrap the app with initial data.
  const data = {
    name: 'hifcCalculator',
    callback,
    update: {},
    query: req.query,
    ip: req.ip,
    headers: req?.headers,
  };

  const inputs = store.getState()[data.name];
  const promises = [store.dispatch(getLocation(data, inputs))];

  promises.push(
    new Promise((resolve) => {
      store.dispatch(fetchLastMortgageRates({ callback: resolve }));
    })
  );

  store.dispatch(
    bootstrapAppWithPromises({
      promises,
      ...data,
    })
  );
};

export const mcBootstrap = (store, req, res, callback) => {
  const stateList = _.get(res, 'locals.stateList');

  store.dispatch(setStateList(stateList));
  // Bootstrap the app with initial data.
  store.dispatch(
    bootstrapApp({
      name: 'mortgageCalculator',
      callback,
      update: {
        inputs: pluckFromQuery(req.query, ['homePrice', 'downpayment'], {
          downpayment: 'downPayment',
          homePrice: 'purchasePrice',
        }),
      },
      query: req.query,
      ip: req.ip,
      headers: req?.headers,
    })
  );
};

export const mcStateBootstrap = (store, req, res, callback) => {
  const stateData = _.get(res, 'locals.stateData');
  const stateList = _.get(res, 'locals.stateList');

  store.dispatch(setStateList(stateList));
  if (stateData) {
    store.dispatch(bootstrapStateData(stateData));
  }

  const additionalPromises = [];

  const state = store.getState();
  const programId = _.get(
    state,
    'mortgageCalculator.stateData.fthbStateProgramProductId'
  );

  const stateAbbreviation = _.get(
    state,
    'mortgageCalculator.stateData.stateAbbreviation'
  );

  const stateName = _.get(state, 'mortgageCalculator.stateData.stateName');

  if (stateAbbreviation && stateName) {
    additionalPromises.push(
      store
        .dispatch(fetchHistoricalRates({ state: stateAbbreviation }))
        .catch((err) => {
          // search for "bootstrap > mcStateBootstrap" to zero in on just this function
          const logger = getLogger('mcStateBootstrap', rootLogger);
          logger.error('fetch-historical-data', err);
        })
    );
  }

  if (programId) {
    additionalPromises.push(
      store.dispatch(getProgramFromMarketplace(programId))
    );
  }

  if (stateAbbreviation) {
    additionalPromises.push(
      store.dispatch(fetchHousingCharacteristics({ state: stateAbbreviation }))
    );
  }
  // Bootstrap the app with initial data.
  store.dispatch(
    bootstrapApp(
      {
        name: 'mortgageCalculator',
        callback,
        update: {
          inputs: pluckFromQuery(req.query, ['homePrice', 'downpayment'], {
            downpayment: 'downPayment',
            homePrice: 'purchasePrice',
          }),
        },
        query: req.query,
        ip: req.ip,
        headers: req?.headers,
      },
      additionalPromises
    )
  );
};

export const mpcBootstrap = (store, req, res, callback) => {
  // Bootstrap the app with initial data.
  store.dispatch(
    bootstrapApp({
      name: 'mortgageCalculator',
      callback,
      update: {
        inputs: pluckFromQuery(req.query, ['homePrice', 'downpayment'], {
          downpayment: 'downPayment',
          homePrice: 'purchasePrice',
        }),
      },
      query: req.query,
      ip: req.ip,
      headers: req?.headers,
    })
  );
};

export const rcBootstrap = (store, req, res, callback) => {
  // Bootstrap the app with initial data.
  store.dispatch(
    bootstrapRC({
      name: 'refinanceCalculator',
      callback,
      update: {
        inputs: pluckFromQuery(req.query, [
          'mortgageBalance',
          'currentLoanTerm',
          'currentMortgageInterestRate',
          'originationYear',
          'refinanceLoanTerm',
          'refinanceInterestRate',
        ]),
      },
      query: req.query,
      ip: req.ip,
      headers: req?.headers,
    })
  );
};

export const rvbBootstrap = (store, req, res, callback) => {
  // Bootstrap the app with initial data.
  const data = {
    name: 'rentvsbuyCalculator',
    callback,
    update: {},
    query: req.query,
    ip: req.ip,
    headers: req?.headers,
  };

  const inputs = store.getState()[data.name];
  const promises = [];

  // no need to fetch rates if we are given one
  if (!inputs.annualInterestRate) {
    promises.push(
      new Promise((resolve) => {
        store.dispatch(fetchLastMortgageRates({ callback: resolve }));
      })
    );
  }

  promises.push(store.dispatch(getLocation(data, inputs)));

  store.dispatch(
    bootstrapAppWithPromises({
      promises,
      ...data,
    })
  );
};

import requestFromMarketplace from '../request-from-marketplace';

export const RECEIVE_PROGRAMS = 'RECEIVE_PROGRAMS';
export const REQUEST_ERROR = 'REQUEST_ERROR';

export const getProgramFromMarketplace =
  (productId: string) =>
  (dispatch: any): Promise<void> => {
    return requestFromMarketplace(productId)
      .then((data) => {
        dispatch({
          type: RECEIVE_PROGRAMS,
          program: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: REQUEST_ERROR,
          err,
        });
      });
  };

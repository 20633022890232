/**
 * Route definitions. Entry point to all routes.
 */

// Dependencies
import React from 'react';

// Utils
import universal from '@nerdwallet/coin/universal';
import { getLogger } from '@nerdwallet/logging';
import mortgageRates from '@nerdwallet/mortgage-rates-trend/reducer';
import { Route, IndexRoute, Redirect } from '@nerdwallet/react-router';
import fthbPrograms from '../reducers/fthb-program';

// Local utils

// Top level components

// Reducers
import { windowResize } from '../lib/actions/browser';
import loadIdentity from '../lib/actions/load-identity';
import addRouteBasedReducers from '../lib/add-route-based-reducers';
import {
  acBootstrap,
  hacBootstrap,
  hifcBootstrap,
  mcBootstrap,
  mcStateBootstrap,
  mpcBootstrap,
  rcBootstrap,
  rvbBootstrap,
} from '../lib/bootstrap';
import bootstrapCityLife from '../lib/cl/entry/bootstrap';
import bootstrapCol from '../lib/col/entry/bootstrap';
import {
  handleMainRedirect,
  handleResultsRedirect,
} from '../lib/entry/onboarding-redirect';
import hifcCalculator from '../reducers/hifc-calculator';
import mortgageCalculator from '../reducers/mortgage-calculator';
import refinanceCalculator from '../reducers/refi-calculator';
import rentvsbuyCalculator from '../reducers/rentvsbuy-calculator';
import results from '../reducers/results';
import validations from '../reducers/validations';

// Actions

// Bootstrap methods

import Container from './container';

const logger = getLogger('router');

const slugRegex = /^[a-z][a-z-]+[a-z]$/i;

// Routes
const ACResults = universal(import('./amortization/ac-results-wrapper'));
const CLResults = universal(import('./city-life/cl-results-wrapper'));
const COLResults = universal(import('./cost-of-living/col-results-wrapper'));
const COLCompareResults = universal(
  import('./cost-of-living/col-compare-results-wrapper')
);
const HACResults = universal(
  import('./home-affordability/hac-results-wrapper')
);
const HIFCResults = universal(
  import('./home-improvement/hifc-results-wrapper')
);
const MCResults = universal(import('./mortgage/mc-results-wrapper'));
const MCStateResults = universal(
  import('./mortgage-state/mc-state-results-wrapper')
);
const MPCResults = universal(import('./mortgage-payment/mpc-results-wrapper'));
const RCResults = universal(import('./rc-results/rc-results-wrapper'));
const RVBResults = universal(import('./rent-vs-buy/rvb-results-wrapper'));

// Universal import fix (static methods don't work the first time server side causing bootstrap to fail)
ACResults.bootstrap = acBootstrap;
HACResults.bootstrap = hacBootstrap;
HIFCResults.bootstrap = hifcBootstrap;
MCResults.bootstrap = mcBootstrap;
MCStateResults.bootstrap = mcStateBootstrap;
MPCResults.bootstrap = mpcBootstrap;
RCResults.bootstrap = rcBootstrap;
RVBResults.bootstrap = rvbBootstrap;

const acRoutes = (store) => {
  const appName = 'ac';
  const secondUrl = 'calculate-amortization-schedule';

  const addReducers = addRouteBasedReducers(
    store,
    {
      validations,
      mortgageCalculator,
    },
    appName
  );

  return (
    <Route
      name="amortization-schedule-calculator"
      path="amortization-schedule-calculator"
      component={Container}
    >
      <IndexRoute
        name="default"
        component={ACResults}
        onEnter={handleMainRedirect(store, appName, secondUrl, addReducers)}
      />
      <Route
        name={secondUrl}
        path={secondUrl}
        component={ACResults}
        onEnter={handleResultsRedirect(store, appName, addReducers)}
      />
    </Route>
  );
};

const hacRoutes = (store) => {
  const appName = 'hac';
  const secondUrl = 'calculate-affordability';

  const addReducers = addRouteBasedReducers(
    store,
    {
      validations,
      results,
    },
    appName
  );

  return (
    <Route
      name="home-affordability-calculator"
      path="how-much-house-can-i-afford"
      component={Container}
    >
      <IndexRoute
        name="default"
        component={HACResults}
        onEnter={handleMainRedirect(store, appName, secondUrl, addReducers)}
      />
      <Route
        name={secondUrl}
        path={secondUrl}
        component={HACResults}
        onEnter={handleResultsRedirect(store, appName, addReducers)}
      />
      <Redirect
        path="refinance-calculator"
        to="/mortgages/refinance-calculator"
      />
    </Route>
  );
};

const hifcRoutes = (store) => {
  const appName = 'hifc';

  return (
    <Route
      name="home-improvement-financing-alternatives"
      path="home-improvement-financing-alternatives"
      component={HIFCResults}
      onEnter={addRouteBasedReducers(
        store,
        {
          hifcCalculator,
        },
        appName
      )}
    />
  );
};

const mpcRoutes = (store) => {
  const appName = 'mpc';
  const secondUrl = 'calculate-mortgage-payment';

  const addReducers = addRouteBasedReducers(
    store,
    {
      validations,
      mortgageCalculator,
    },
    appName
  );

  return (
    <Route
      name="mortgage-payment-calculator"
      path="mortgage-payment-calculator"
      component={Container}
    >
      <IndexRoute
        name="default"
        component={MPCResults}
        onEnter={handleMainRedirect(store, appName, secondUrl, addReducers)}
      />
      <Route
        name={secondUrl}
        path={secondUrl}
        component={MPCResults}
        onEnter={handleResultsRedirect(store, appName, addReducers)}
      />
    </Route>
  );
};

const mcRoutes = (store) => {
  const appName = 'mc';
  const secondUrl = 'calculate-mortgage-payment';

  const addReducers = addRouteBasedReducers(
    store,
    {
      validations,
      mortgageCalculator,
    },
    appName
  );

  const addStateReducers = addRouteBasedReducers(
    store,
    {
      fthbPrograms,
      mortgageRates,
      validations,
      mortgageCalculator,
    },
    appName
  );

  return (
    <Route
      name="mortgage-calculator"
      path="mortgage-calculator"
      component={Container}
    >
      <IndexRoute name="default" component={MCResults} onEnter={addReducers} />
      <Route
        name={secondUrl}
        path={secondUrl}
        component={MCResults}
        onEnter={addReducers}
      />
      <Route
        name="mc-state-name"
        path=":stateSlug"
        component={MCStateResults}
        onEnter={addStateReducers}
      />
      <Route
        name="mc-state-name-second"
        path="calculate-mortgage-payment/:stateSlug"
        component={MCStateResults}
        onEnter={addStateReducers}
      />
    </Route>
  );
};

const rcRoutes = (store) => {
  const appName = 'rc';
  const secondUrl = 'calculate-refinance-savings';

  const addReducers = addRouteBasedReducers(
    store,
    {
      validations,
      refinanceCalculator,
    },
    appName
  );

  return (
    <Route
      name="refinance-calculator"
      path="refinance-calculator"
      component={Container}
    >
      <IndexRoute
        name="default"
        component={RCResults}
        onEnter={handleMainRedirect(store, appName, secondUrl, addReducers)}
      />
      <Route
        name={secondUrl}
        path={secondUrl}
        component={RCResults}
        onEnter={handleResultsRedirect(store, appName, addReducers)}
      />
    </Route>
  );
};

const rvbRoutes = (store) => {
  const appName = 'rvb';

  return (
    <Route
      name="rent-vs-buy-calculator"
      path="rent-vs-buy-calculator"
      component={RVBResults}
      onEnter={addRouteBasedReducers(
        store,
        {
          validations,
          rentvsbuyCalculator,
        },
        appName
      )}
    />
  );
};

const clRoutes = (store, req, res) => {
  const onEnter = (nextState, replaceState, callback) => {
    if (!slugRegex.test(nextState?.params?.citySlug)) {
      logger.error(
        `Error: /cost-of-living-calculator/city-life/:citySlug received an invalid city slug: ${nextState.params.citySlug}`
      );
      res.sendStatus(404);
      return null;
    }
    return bootstrapCityLife(store, req, res)(
      nextState,
      replaceState,
      callback
    );
  };

  return (
    <Route
      name="city-life"
      path="city-life/:citySlug"
      component={CLResults}
      onEnter={onEnter}
    />
  );
};

const colRoutes = (store, req, res) => {
  return (
    <IndexRoute
      name="cost-of-living-calculator"
      component={COLResults}
      onEnter={bootstrapCol(store, req, res)}
    />
  );
};

const colCompareRoutes = (store, req, res) => {
  const onEnter = (nextState, replaceState, callback) => {
    if (!slugRegex.test(nextState?.params?.citiesSlug)) {
      logger.error(
        `Error: /cost-of-living-calculator/compare/:citiesSlug received an invalid cities slug: ${nextState.params.citiesSlug} with user agent: ${req?.headers['user-agent']}`
      );
      res.sendStatus(404);
      return null;
    }
    return bootstrapCol(store, req, res)(nextState, replaceState, callback);
  };

  return (
    <Route
      name="compare"
      path="compare/:citiesSlug"
      component={COLCompareResults}
      onEnter={onEnter}
    />
  );
};

export default (store, res, req) => {
  const serverPreloadIdentity = (nextState, replaceState, callback) => {
    if (typeof window === 'undefined') {
      store.dispatch(loadIdentity(req, res, callback));
      store.dispatch(windowResize(req.headers['user-agent']));
    } else {
      callback();
    }
  };

  return (
    <Route path="/" component={Container} onEnter={serverPreloadIdentity}>
      <Route path="cost-of-living-calculator" component={Container}>
        {colRoutes(store, req, res)}
        {colCompareRoutes(store, req, res)}
        {clRoutes(store, req, res)}
        <Redirect path="city-life" to="/cost-of-living-calculator" />
      </Route>
      <Route path="mortgages">
        {acRoutes(store)}
        {hacRoutes(store)}
        {mcRoutes(store, res)}
        {hifcRoutes(store)}
        {mpcRoutes(store)}
        {rcRoutes(store)}
        {rvbRoutes(store, req)}
        <Redirect path="home-affordability" to={process.env.BASE_URI} />
      </Route>
    </Route>
  );
};

import { createReducer } from '../reducers/reducers';

/**
 * Updates the store with all the reducers.
 *
 * @param store The redux store.
 */
function updateStore(store) {
  // Update the reducers on the store.
  store.replaceReducer(createReducer(store.additionalReducers));
}

/**
 * Lib function to lazy load reducers.
 */
export function addAdditionalReducers(store, reducers) {
  const additionalReducerCollection = store.additionalReducers;

  // Add the new reducers to the collection
  // eslint-disable-next-line no-param-reassign
  store.additionalReducers = {
    ...reducers,
    ...additionalReducerCollection,
  };

  // Update the store.
  updateStore(store);
}

/**
 * Lib function remove unused reducers.
 */
export function removeAdditionalReducers(store, key) {
  // Remove the reducer from the collection.
  // eslint-disable-next-line no-param-reassign
  delete store.additionalReducers[key];

  // Update the store.
  updateStore(store);
}

// Utils

// Reducers
import costOfLivingCalculator from '../../../reducers/col/col-calculator';
import validations from '../../../reducers/validations';
import addRouteBasedReducers from '../../add-route-based-reducers';
import pluckFromQuery from '../../pluck-from-query';

// Actions
import colBootstrapApp from '../col-actions';

import handleNewParams from './compare';

export default function bootstrapCol(store, req, res) {
  return (nextState, replaceState, callback) => {
    addRouteBasedReducers(
      store,
      {
        costOfLivingCalculator,
        validations,
      },
      'col'
    )();

    if (req && res) {
      // We are on the server, so bootstrap app
      store.dispatch(
        colBootstrapApp(req.cache, {
          name: 'costOfLivingCalculator',
          callback: () => {
            // pass in the callback here so we don't render the page before fetching all data
            handleNewParams(
              nextState.params,
              store.dispatch,
              store.getState,
              res,
              callback
            );
          },
          update: {
            // TODO: support query params
            inputs: pluckFromQuery(req.query, ['']),
          },
          query: req.query,
          ip: req.ip,
        })
      );
    } else {
      // On the client, so don't do anything
      callback();
    }
  };
}

/** @module feature-flags */
// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import { get } from 'lodash';
import config from '@nerdwallet/app-config';

/**
 * Provides reflected object of feature flags, meant for use with default export
 * @type {Object}
 */
export const FEATURE_FLAGS = Object.keys(
  get(config, 'FEATURE_FLAGS', {})
).reduce(
  (acc, k) => ({
    ...acc,
    [k]: k,
  }),
  {}
);

/**
 * Get an app-config driven feature flag.
 * @example
 * // given config
 * FEATURE_FLAGS:
 *   FOO: true
 * @example
 * import getFeatureFlag, { FEATURE_FLAGS } from '../config/inputs/feature-flags';
 *
 * getFeatureFlag(FEATURE_FLAGS.FOO); // true
 * @see https://www.github.com/NerdWallet/nwjs-feature-flags
 * @deprecated
 * @function
 * @param  {String} key The key
 * @return {Mixed}    The value of the flag
 */
export default (key: string): any => get(config, ['FEATURE_FLAGS', key]);

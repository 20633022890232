import format from 'date-fns/format';

const getDefaultInputs = () => ({
  purchaseAddress: {
    city: 'San Francisco',
    state: 'CA',
  },
  state: 'CA',
  purchasePrice: 300000,
  downPayment: 60000,
  downPaymentPercent: 0.2,
  loanPurpose: 'purchase',
  annualInterestRate: 0.075,
  baseAnnualInterestRate: 0.075,
  loanTerm: 30,
  propertyTaxRate: 0.018,
  homeInsurance: 2800,
  HOADues: 0,
  annualPMIFee: 0.005,
  annualInterestRates: {
    fixed_30: 3.43,
    fixed_15: 2.74,
    arm_5_1: 2.76,
  },
  originationDate: 3,
  originationYear: 1991,
  originationMonth: 12,
  loanStartDate: format(new Date(), 'MM / dd / yyyy'),
});

export default getDefaultInputs;

// Utils
// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import { isEmpty } from 'lodash';

// Actions
import { cityChange } from '../col-actions';

// Constants
const appName = 'costOfLivingCalculator';

export default function handleNewParams(
  newParams,
  dispatch,
  getState,
  res,
  callback
) {
  if (!isEmpty(newParams)) {
    // received url params, so render the results page
    const { citiesSlug } = newParams;
    const [sourceSlug, destinationSlug] = (citiesSlug || '').split('-vs-');
    const args = {
      name: appName,
      inputs: {},
      slugs: {
        sourceSlug,
        destinationSlug,
      },
    };
    dispatch(cityChange(args, callback));
  } else {
    // no url params, so go back to landing page so make sure destination city is not set
    const args = {
      name: appName,
      inputs: {
        destinationCity: undefined,
      },
    };
    dispatch(cityChange(args, callback));
  }
}

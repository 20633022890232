import {
  validateAutocompleteState,
  validateZipCode,
} from '../../lib/validation';

export const address = {
  name: 'address',
  type: 'string',
};

export const aptNumber = {
  name: 'aptNumber',
  type: 'string',
  required: false,
};

export const autocompleteState = {
  name: 'autocompleteState',
  type: 'boolean',
  validators: [validateAutocompleteState],
  viewConfig: {
    viewType: 'custom',
    componentName: 'autocompleteState',
  },
};

export const city = {
  name: 'city',
  type: 'string',
};

export const county = {
  name: 'county',
  type: 'string',
  required: false,
  // TODO: NS - find a way to validate the county...
  // validators: [validateCounty],
};

export const state = {
  name: 'state',
  type: 'string',
  whitelist: [
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
  ],
};

export const zipCode = {
  name: 'zipCode',
  type: 'string',
  maxLength: 5,
  validators: [validateZipCode],
  viewConfig: {
    type: 'tel',
  },
};

// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import { assign, reduce } from 'lodash';

import defaultInputs from '../components/refinance-calculator/default-inputs';

function exponentialSum(costOfCapitalFactor, lower, upper) {
  if (upper <= lower) {
    return 0.0;
  }

  if (lower === 0) {
    if (upper === 1) {
      return 1.0;
    }

    return (
      1 +
      costOfCapitalFactor * exponentialSum(costOfCapitalFactor, 0, upper - 1)
    );
  }

  return (
    Math.pow(costOfCapitalFactor, lower) *
    exponentialSum(costOfCapitalFactor, 0, upper - lower)
  );
}

function edgeSum(costOfCapitalFactor, holdingPastA, holdingPastB) {
  const lower = Math.max(holdingPastA, 0);
  const upper = Math.max(holdingPastA, holdingPastB, 0);

  return exponentialSum(costOfCapitalFactor, lower, upper);
}

function commonSum(
  costOfCapitalFactor,
  holdingPastOld,
  holdingPastNew,
  timeToDisposal
) {
  const lower = Math.max(holdingPastOld, holdingPastNew, 0);
  const upper = timeToDisposal;

  return exponentialSum(costOfCapitalFactor, lower, upper);
}

function Mortgage(term, interestRate, initialBalance) {
  const interestRateFactor = 1.0 + interestRate;
  const payment =
    (initialBalance * Math.pow(interestRateFactor, term)) /
    exponentialSum(interestRateFactor, 0, term);
  const nextBalance = (balance) => balance * interestRateFactor - payment;
  const balance = (period) => {
    if (period >= term) {
      return 0.0;
    }

    let thisPeriodBalance = initialBalance;
    let thisPeriod;

    for (thisPeriod = 0; thisPeriod < period; thisPeriod += 1) {
      thisPeriodBalance = nextBalance(thisPeriodBalance);
    }

    return thisPeriodBalance;
  };

  const cumulativePrincipalReduction = (first, last) => {
    if (first >= term) return 0;
    const limit = Math.min(last, term);
    return balance(first) - balance(limit);
  };

  return {
    initialBalance,
    term,
    payment,
    nextBalance,
    balance,
    cumulativePrincipalReduction,
    cumulativeInterestPaid(first, last) {
      if (first >= term) return 0;
      const limit = Math.min(last, term);
      return (
        (limit - first) * payment - cumulativePrincipalReduction(first, limit)
      );
    },
  };
}

function computeSavings(
  oldTermsRemaining,
  newTerm,
  timeToDisposal,
  oldPayment,
  newPayment,
  currentBalanceDifference,
  finalBalanceDifference,
  closingCosts,
  costOfCapital
) {
  const periodsHeldPastNewEnd = timeToDisposal - newTerm;
  const periodsHeldPastOldEnd = timeToDisposal - oldTermsRemaining;
  const costOfCapitalFactor = Math.exp(costOfCapital);

  return (
    (currentBalanceDifference - closingCosts) *
      Math.pow(costOfCapitalFactor, timeToDisposal) -
    finalBalanceDifference -
    newPayment *
      edgeSum(
        costOfCapitalFactor,
        periodsHeldPastNewEnd,
        periodsHeldPastOldEnd
      ) +
    oldPayment *
      edgeSum(
        costOfCapitalFactor,
        periodsHeldPastOldEnd,
        periodsHeldPastNewEnd
      ) +
    (oldPayment - newPayment) *
      commonSum(
        costOfCapitalFactor,
        periodsHeldPastOldEnd,
        periodsHeldPastNewEnd,
        timeToDisposal
      )
  );
}

function computeBalanceDifferences(oldMortgage, newMortgage, oldPeriodsPast) {
  let newBalance = newMortgage.initialBalance;
  let oldBalance = oldMortgage.balance(oldPeriodsPast);

  const oldPeriodsRemaining = oldMortgage.term - oldPeriodsPast;
  const relevantPeriods = Math.max(oldPeriodsRemaining, newMortgage.term) + 1;
  const balanceDifferences = [];

  for (let period = 0; period < relevantPeriods; period += 1) {
    balanceDifferences.push(newBalance - oldBalance);

    newBalance =
      period < newMortgage.term ? newMortgage.nextBalance(newBalance) : 0.0;
    oldBalance =
      period < oldPeriodsRemaining ? oldMortgage.nextBalance(oldBalance) : 0.0;
  }

  return balanceDifferences;
}

function savingsList(
  oldPeriodsPast,
  oldMortgage,
  newMortgage,
  closingCosts,
  costOfCapital
) {
  const oldPeriodsRemaining = oldMortgage.term - oldPeriodsPast;
  const balanceDifferences = computeBalanceDifferences(
    oldMortgage,
    newMortgage,
    oldPeriodsPast
  );
  const savings = new Array(balanceDifferences.length);

  for (
    let timeToDisposal = 0;
    timeToDisposal < balanceDifferences.length;
    timeToDisposal += 1
  ) {
    savings[timeToDisposal] = computeSavings(
      oldPeriodsRemaining,
      newMortgage.term,
      timeToDisposal,
      oldMortgage.payment,
      newMortgage.payment,
      balanceDifferences[0],
      balanceDifferences[timeToDisposal],
      closingCosts,
      costOfCapital
    );
  }

  return savings;
}

function savingsListPerYear(savings) {
  /*
    savingsListPerYear starts at year 1
    ie savingsListPerYear[0] = year 1 saving

    Assumption here is to display the saving from year 1 onwards,
    because year 0 (aka today) will never be ideal to refinance and
    close out since user lose out on closing cost.
  */
  const savingsPerYear = [];
  const numYears = savings.length / 12;

  for (let i = 1; i <= numYears; i += 1) {
    savingsPerYear.push(savings[i * 12]);
  }

  return savingsPerYear;
}

function periodsBetween(fromYear, fromMonth, toYear, toMonth) {
  // month = 0 - 11 = Jan - Dec
  const then = new Date(fromYear, fromMonth);
  const now = new Date(toYear, toMonth);
  const getMonths = (date) => date.getFullYear() * 12 + date.getMonth() + 1;

  return getMonths(now) - getMonths(then);
}

function findBreakpoints(list) {
  const breakpoints = [];

  // eslint-disable-next-line lodash/collection-method-value -- directive added automatically by Shepherd migration
  reduce(
    list,
    (accumulator, periodValue, i) => {
      const { previousValue } = accumulator;
      if (
        periodValue === 0 ||
        Math.sign(periodValue) !== Math.sign(previousValue)
      ) {
        breakpoints.push(Math.ceil(i / 12));
      }

      return { previousValue: periodValue };
    },
    { previousValue: -1 }
  );

  return breakpoints;
}

export { Mortgage };

export default (inputs = assign({}, defaultInputs)) => {
  const {
    cashout,
    howLongStay,
    originationYear,
    originationMonth,
    currentYear,
    currentMonth,
  } = inputs;

  const howLongStayMonths = howLongStay * 12;

  const oldTerm = inputs.currentLoanTerm * 12;
  const oldInterestRate = inputs.currentMortgageInterestRate / 12.0;
  const oldMortgage = new Mortgage(
    oldTerm,
    oldInterestRate,
    inputs.mortgageBalance
  );
  const oldPeriodsPast = periodsBetween(
    originationYear,
    originationMonth,
    currentYear,
    currentMonth
  );
  const oldBalance = oldMortgage.balance(oldPeriodsPast);

  const newTerm = inputs.refinanceLoanTerm * 12;
  const newInterestRate = inputs.refinanceInterestRate / 12.0;
  const newMortgage = new Mortgage(
    newTerm,
    newInterestRate,
    oldBalance + cashout
  );

  const closingCosts =
    inputs.newMortgageClosingCosts !== undefined &&
    inputs.newMortgageClosingCosts !== null
      ? inputs.newMortgageClosingCosts
      : inputs.closingCostsFraction * newMortgage.initialBalance;

  const savings = savingsList(
    oldPeriodsPast,
    oldMortgage,
    newMortgage,
    closingCosts,
    inputs.costOfCapital
  );
  const savingsPerYear = savingsListPerYear(savings);
  const breakpoints = findBreakpoints(savings);

  const currentCumulativePrincipalReduction =
    oldMortgage.cumulativePrincipalReduction(
      oldPeriodsPast,
      howLongStayMonths + oldPeriodsPast
    );
  const currentCumulativeInterestPaid = oldMortgage.cumulativeInterestPaid(
    oldPeriodsPast,
    howLongStayMonths + oldPeriodsPast
  );

  const newCumulativePrincipalReduction =
    newMortgage.cumulativePrincipalReduction(0, howLongStayMonths);
  const newCumulativeInterestPaid = newMortgage.cumulativeInterestPaid(
    0,
    howLongStayMonths
  );

  const currentCumulativeTotal =
    currentCumulativePrincipalReduction + currentCumulativeInterestPaid;
  const newCumulativeTotal =
    newCumulativePrincipalReduction + newCumulativeInterestPaid + closingCosts;

  const savingsCumulativeTotal = currentCumulativeTotal - newCumulativeTotal;
  const savingsCumulativePrincipal =
    currentCumulativePrincipalReduction - newCumulativePrincipalReduction;

  return {
    breakevenBegin: breakpoints[0],
    breakevenEnd: breakpoints[1],
    dataviz: savingsPerYear,
    details: {
      currentLoan: {
        monthlyPayment: oldMortgage.payment,
        cumulativePrincipal: currentCumulativePrincipalReduction,
        cumulativeInterestPaid: currentCumulativeInterestPaid,
        cumulativeTotal: currentCumulativeTotal,
      },
      refinanceLoan: {
        monthlyPayment: newMortgage.payment,
        cumulativePrincipal: newCumulativePrincipalReduction,
        cumulativeInterestPaid: newCumulativeInterestPaid,
        cumulativeTotal: newCumulativeTotal,
        closingCosts: Math.round(closingCosts),
      },
      savings: {
        monthlyPayment: oldMortgage.payment - newMortgage.payment,
        cumulativeTotal: savingsCumulativeTotal,
        cumulativePrincipal: savingsCumulativePrincipal,
        totalSavings: savingsCumulativeTotal + savingsCumulativePrincipal,
      },
    },
  };
};

import _ from 'lodash';

import {
  SAVE_HOME_CALCULATOR_ADDRESS,
  SAVE_HOME_CALCULATOR_PROFILE,
} from '../lib/actions';

// This is to keep home calc profile in sync with the inputs

const defaultState = { data: {} };

export default (state = defaultState, action = {}) => {
  const newState = _.cloneDeep(state);

  switch (action.type) {
    case SAVE_HOME_CALCULATOR_PROFILE:
      // This is a PATCH
      _.assign(newState.data, action.data);
      break;
    case SAVE_HOME_CALCULATOR_ADDRESS:
      if (action.addressType) {
        newState.data[action.addressType] = action.address;
      }
      break;
    default:
      return state;
  }
  return newState;
};

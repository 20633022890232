/* eslint-disable no-underscore-dangle */
// Utils
import _ from 'lodash';
import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';

import config from '@nerdwallet/app-config';
// Constants
import { Element } from '@nerdwallet/js-auth-tools/dist/constants';
import { createAuthToolsMiddlewares } from '@nerdwallet/js-auth-tools/middleware';
import { TrkPageType } from '@nerdwallet/nw-client-lib/trk-helper';

// Local utils
import rootReducer from '../reducers/reducers';

import nwApiSDK, { identityProfilesToInclude } from './nw-api-sdk';
import UserDataPlatformSDK from './user-data-sdk';

const devInClient =
  process.env.NODE_ENV === 'development' && process.env.IN_BROWSER === true;

/**
 * Configure and return the store.
 *
 * @returns Object The store for the app.
 */
export default (initialState) => {
  const authToolsMiddlewares = createAuthToolsMiddlewares({
    asyncOptions: {
      IdentitySDK: nwApiSDK.IdentitySDK,
      identityProfilesToInclude,
      identityApp: nwApiSDK.IdentitySDK.APPS.NERDWALLET_WEB,
      UserDataPlatformSDK,
      shimUDP: _.get(config, 'IDENTITY.SDK.UDP_PROFILE_SHIM.ENABLED'),
      UDPMemberProfilesList: _.get(
        config,
        'IDENTITY.SDK.UDP_PROFILE_SHIM.PROFILES'
      ),
    },
    metricsOptions: {
      appName: process.env.APP_NAME,
      trk: {
        trk_pagetype: TrkPageType.calculator,
        trk_element: Element.APPLICATION,
        trk_nldt: 'Learn',
      },
    },
  });

  const middlewares = [...authToolsMiddlewares, thunkMiddleware];

  const createStoreWithMiddleware = compose(
    applyMiddleware(...middlewares),

    // Flag to enable redux dev-tools.
    devInClient && window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )(createStore);

  // Create the store with the thunk middleware - which supports async actions.
  const store = createStoreWithMiddleware(rootReducer, initialState);

  // Initialize additional reducers to empty in case we don't use it.
  store.additionalReducers = {};

  // Return the store.
  return store;
};

import Input from '../inputs/input';

import {
  cashout,
  city,
  closingCosts,
  currentLoanTerm,
  currentMortgageInterestRate,
  howLongStay,
  loanTerm,
  mortgageBalance,
  newMortgageClosingCosts,
  originationYear,
  propertyValue,
  refinanceLoanTerm,
  refinanceInterestRate,
} from '../../config/inputs';

const balance = new Input(mortgageBalance);

const refinanceGoal = new Input({
  name: 'refinanceGoal',
  type: 'string',
});
// Tmp override because validateMortgageBalance depends on other things
balance.validators = [
  (data) => {
    if (data.mortgageBalance > 10000000) {
      return {
        valid: false,
        message: 'Value must be less than $10,000,000',
      };
    }
    if (data.mortgageBalance === 0) {
      return {
        valid: false,
        message: 'Value must be greater than 1',
      };
    }
    if (!data.mortgageBalance) {
      return {
        valid: false,
        message: 'This field is required',
      };
    }
    return {
      valid: true,
      message: '',
    };
  },
];

cashout.validators = [
  (data) => {
    if (data.cashout > 10000000) {
      return {
        valid: false,
        message: 'Value must be less than $10,000,000',
      };
    }
    // Not sanitizing zeroes because this has to be greater than zero
    if (data.cashout === '') {
      return {
        valid: false,
        message: 'This field is required',
      };
    }
    return {
      valid: true,
      message: '',
    };
  },
];

const inputLibrary = {
  cashout: new Input(cashout),
  city: new Input(city),
  closingCosts: new Input(closingCosts),
  currentLoanTerm: new Input(currentLoanTerm),
  currentMortgageInterestRate: new Input(currentMortgageInterestRate),
  howLongStay: new Input(howLongStay),
  loanTerm: new Input(loanTerm),
  mortgageBalance: balance,
  newMortgageClosingCosts: new Input(newMortgageClosingCosts),
  originationYear: new Input(originationYear),
  propertyValue: new Input(propertyValue),
  refinanceGoal,
  refinanceLoanTerm: new Input(refinanceLoanTerm),
  refinanceInterestRate: new Input(refinanceInterestRate),
};

export default inputLibrary;

import _ from 'lodash';

const ROUND_ROBIN_LENDERS = [
  'c5edd504-48d1-11e8-9c02-77fe06537299',
  'c5ea02ee-48d1-11e8-9c02-9bce45af5314',
];

const getDefaultState = () => ({
  loanPurpose: 'purchase',
  roundRobin: {
    institutionIds: ROUND_ROBIN_LENDERS,
    lenderId: _.sample(ROUND_ROBIN_LENDERS),
  },
  lenders: [],
});

export default (state = getDefaultState()) => state;

import { getLogger } from '@nerdwallet/logging';

import {
  FETCH_CITY_DATA,
  FETCH_CITY_DATA_SUCCESS,
  FETCH_CITY_DATA_ERROR,
} from '../actions';
import { fetch, HOMEOWNERSHIP_URL } from '../fetch';

export const FETCH_COMPARE_CITY_DATA = 'FETCH_COMPARE_CITY_DATA';
export const FETCH_CITY_LIFE_CITIES = 'FETCH_CITY_LIFE_CITIES';
export const FETCH_CITIES = 'FETCH_CITIES';
export const FETCH_FIELD_DETAILS = 'FETCH_FIELD_DETAILS';
export const FETCH_SUGGESTED_CITIES = 'FETCH_SUGGESTED_CITIES';

// Search for "actions" to look for all actions related log messages
const rootLogger = getLogger('actions');

const fetchColData =
  ({ params, callback = () => {}, url, method }) =>
  (dispatch) => {
    const fetchCallback = (data) => {
      // Dispatch the fetch city data action so that the reducers can update their state.
      dispatch({
        type: FETCH_CITY_DATA_SUCCESS,
      });
      callback(data);
    };

    const fetchCallbackErr = (error) => {
      // search for "actions > fetchColData" to zero in on just this function
      const logger = getLogger('fetchColData', rootLogger);
      logger.error('fetchColData', error);

      dispatch({
        type: FETCH_CITY_DATA_ERROR,
        error,
      });

      callback();
    };

    dispatch({
      type: FETCH_CITY_DATA,
    });

    fetch(
      url,
      params || null,
      null,
      method,
      fetchCallback,
      fetchCallbackErr,
      HOMEOWNERSHIP_URL
    );
  };

export const fetchCityData = ({ ids, callback }) =>
  fetchColData({
    params: {
      body: {
        ids,
      },
    },
    callback,
    url: '/v1/cost-of-living/city-data',
    method: 'POST',
  });

export const fetchCityLifeCities = ({ callback }) =>
  fetchColData({
    callback,
    url: '/v1/cost-of-living/city-life',
    method: 'GET',
  });

export const fetchCities = ({ callback }) =>
  fetchColData({
    callback,
    url: '/v1/cost-of-living/cities',
    method: 'GET',
  });

export const fetchFieldDetails = ({ callback }) =>
  fetchColData({
    callback,
    url: '/v1/cost-of-living/field-details',
    method: 'GET',
  });

export const fetchSuggestedCities = ({ callback }) =>
  fetchColData({
    callback,
    url: '/v1/cost-of-living/suggested-cities',
    method: 'GET',
  });

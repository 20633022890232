// Utils
import { combineReducers } from 'redux';
import { routeReducer as routing } from 'redux-simple-router';

import { getLogger } from '@nerdwallet/logging';

// Reducers
import { Reducer as experiments } from '@nerdwallet/nw-client-lib/ab';
import { reducer as impressions } from '@nerdwallet/nw-client-lib/tracking';

// Local reducers
import app from './app';
import browser from './browser';
import errors from './errors';
import homeCalculatorProfile from './home-calculator-profile';
import identity from './identity';
import lendersConfig from './lenders-config';
import loading from './loading';
import mortgageCalculator from './mortgage-calculator';
import onboardingBypass from './onboarding-bypass';
import rates from './rates';
import refinanceCalculator from './refi-calculator';
import rentvsbuyCalculator from './rentvsbuy-calculator';
import results from './results';
import validations from './validations';

// Search for "reducer" to look for all actions related log messages
const rootLogger = getLogger('reducer');

// Combine the reducers from the sub-parts of the entire store.
export function createReducer(additionalReducers = {}) {
  return combineReducers({
    app,
    browser,
    cityLife: (state = {}) => state,
    costOfLivingCalculator: (state = {}) => state,
    errors,
    experiments,
    hifcCalculator: (state = {}) => state,
    fthbPrograms: (state = {}) => state,
    homeCalculatorProfile,
    identity,
    impressions,
    lendersConfig,
    loading,
    mortgageCalculator,
    mortgageRates: (state = {}) => state,
    onboardingBypass,
    rates,
    refinanceCalculator,
    rentvsbuyCalculator,
    results,
    routing,
    trackingCookies: (state = {}) => state,
    validations,
    ...additionalReducers,
  });
}

// Create the initial collection of reducers.
const combinedReducers = createReducer();

export default (state = {}, action = {}) => {
  try {
    // Invoke all the combined reducers.
    return combinedReducers(state, action);
  } catch (e) {
    // search for "reducers > combinedReducers" to zero in on just this function
    const logger = getLogger('combinedReducers', rootLogger);
    logger.error(e);
    return state;
  }
};

// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import { debounce, get } from 'lodash';

import {
  updateProfile,
  updateVisitorProfile,
} from '@nerdwallet/js-auth-tools/actions';
import { getLogger } from '@nerdwallet/logging';
import { toHomeCalculatorProfile } from '@nerdwallet/mortgage-utils/home-calculator-profile';
import getFeatureFlag, {
  FEATURE_FLAGS,
} from '../../config/inputs/feature-flags';

import nwApiSdk from '../nw-api-sdk';
import getReducerInfo from '../profiles/get-reducer-info';

// Search for "actions" to look for all actions related log messages
const rootLogger = getLogger('actions');

const { IdentitySDK } = nwApiSdk;

const HOME_CALCULATOR_PROFILE = IdentitySDK.PROFILES.HOME_CALCULATOR_PROFILE;

const DEBOUNCE_TIMER = 1000;
const VISITOR_DATA_PREFIX = 'visitor-data-write';

const debouncedSaveToProfile = debounce((dispatch, getState) => {
  const state = getState();
  const app = get(state, 'app');

  // Don't trust actions or components to check validations
  if (!get(state, 'validations.valid')) return;

  const reducerInfo = getReducerInfo(app);

  if (reducerInfo) {
    const profileData = toHomeCalculatorProfile(
      get(state, 'homeCalculatorProfile.data'),
      {}
    );
    if (!get(state, 'identity.isSignedIn')) {
      if (getFeatureFlag(FEATURE_FLAGS.VISITOR_DATA_WRITE) !== true) {
        // search for "actions > save-to-profile" to zero in on just this function
        const logger = getLogger('save-to-profile', rootLogger);
        logger.info(
          VISITOR_DATA_PREFIX,
          'disabled',
          getFeatureFlag(FEATURE_FLAGS.VISITOR_DATA_WRITE)
        );
        return;
      }
      dispatch(
        updateVisitorProfile({
          profileData,
          profileType: HOME_CALCULATOR_PROFILE,
        })
      );
    } else {
      dispatch(
        updateProfile({
          data: profileData,
          profileType: HOME_CALCULATOR_PROFILE,
        })
      );
    }
  }
}, DEBOUNCE_TIMER);

export default () => debouncedSaveToProfile;

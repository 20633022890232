// Utils
// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import { get } from 'lodash';
import numeral from 'numeral';

export default function calculateCompareData(
  sourceCity,
  destinationCity,
  income
) {
  const colMultiplier =
    get(destinationCity, 'composite_index') /
    get(sourceCity, 'composite_index');
  const adjustedIncome = numeral(colMultiplier * income).format('$0,0');
  const colPercentDifference = Math.round(colMultiplier * 100) - 100;

  // these Percent Differences are in decimal form so that they are compatible with the config builder
  const housingMultiplier =
    get(destinationCity, 'housing_index') / get(sourceCity, 'housing_index');
  const housingPercentDifference =
    (Math.round(housingMultiplier * 100) - 100) / 100;

  const transportationMultiplier =
    get(destinationCity, 'transportation_index') /
    get(sourceCity, 'transportation_index');
  const transportationPercentDifference =
    (Math.round(transportationMultiplier * 100) - 100) / 100;

  const foodMultiplier =
    get(destinationCity, 'grocery_index') / get(sourceCity, 'grocery_index');
  const foodPercentDifference = (Math.round(foodMultiplier * 100) - 100) / 100;

  const entertainmentMultiplier =
    get(destinationCity, 'misc_index') / get(sourceCity, 'misc_index');
  const entertainmentPercentDifference =
    (Math.round(entertainmentMultiplier * 100) - 100) / 100;

  const healthcareMultiplier =
    get(destinationCity, 'healthcare_index') /
    get(sourceCity, 'healthcare_index');
  const healthcarePercentDifference =
    (Math.round(healthcareMultiplier * 100) - 100) / 100;

  return {
    colPercentDifference,
    adjustedIncome,
    entertainmentPercentDifference,
    foodPercentDifference,
    healthcarePercentDifference,
    housingPercentDifference,
    transportationPercentDifference,
  };
}

import Input from '../../../inputs/input';
import * as inputDefinitions from '../../../../config/inputs';

const downPaymentPct = new Input(inputDefinitions.downPaymentPercent);

downPaymentPct.validators = [
  (data) => {
    if (data.downPaymentPercent > 1) {
      return {
        valid: false,
        message: 'Value must be less than 100%',
      };
    }
    if (data.downPaymentPercent < 0) {
      return {
        valid: false,
        message: 'Value must be at least 0%',
      };
    }
    return {
      valid: true,
      message: '',
    };
  },
];

const inputLibrary = {
  annualInterestRate: new Input(inputDefinitions.annualInterestRate),
  city: new Input(inputDefinitions.city),
  downPayment: new Input(inputDefinitions.downPayment),
  downPaymentPercent: downPaymentPct,
  loanTerm: new Input(inputDefinitions.loanTerm),
  propertyValue: new Input(inputDefinitions.propertyValue),
  monthlyRent: new Input(inputDefinitions.monthlyRent),
  propertyTaxRate: new Input(inputDefinitions.propertyTaxRate),
  HOADues: new Input(inputDefinitions.HOADues),
  homeInsurance: new Input(inputDefinitions.homeInsurance),
  maintainanceFees: new Input(inputDefinitions.maintainanceFees),
  renovationFees: new Input(inputDefinitions.renovationFees),
  incomeTaxRate: new Input(inputDefinitions.incomeTaxRate),
  rentersInsurance: new Input(inputDefinitions.rentersInsurance),
  buyingCosts: new Input(inputDefinitions.buyingCosts),
  sellingCosts: new Input(inputDefinitions.sellingCosts),
  rentSecurityDeposit: new Input(inputDefinitions.rentSecurityDeposit),
  homeAppreciation: new Input(inputDefinitions.homeAppreciation),
  rentAppreciation: new Input(inputDefinitions.rentAppreciation),
  generalInflation: new Input(inputDefinitions.generalInflation),
  annualReturnCash: new Input(inputDefinitions.annualReturnCash),
  taxFilingStatus: new Input({ name: 'taxFilingStatus', type: 'string' }),
  income: new Input(inputDefinitions.income),
  capitalGainsTaxRate: new Input(inputDefinitions.capitalGainsTaxRate),
  purchasePrice: new Input(inputDefinitions.purchasePrice),
};

export default inputLibrary;

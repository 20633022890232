import { RECEIVE_LAST_MORTGAGE_RATES } from '../lib/actions/fetchLastMortgageRates';

export default (state = { annualInterestRates: {} }, action = {}) => {
  const newState = state;

  switch (action.type) {
    case RECEIVE_LAST_MORTGAGE_RATES:
      newState.annualInterestRates = action.mortgage_rates;
      break;

    default:
      break;
  }

  // Return the new state.
  return newState;
};

// Utils
import _ from 'lodash';

import { fromHomeCalculatorProfile } from '@nerdwallet/mortgage-utils/home-calculator-profile';

// Configs and Data
import defaultInputs from '../components/rentvsbuy-calculator/control-panel/shared/default-inputs';
import inputLibrary from '../components/rentvsbuy-calculator/control-panel/shared/input-library';
import costModel from '../config/data/costs-model.json';
// Actions
import {
  INPUT_CHANGE,
  RESOLVE_CALCULATIONS,
  UPDATE_DEFAULT_CITY,
  UPDATE_DEFAULT_RATE,
  SIGN_IN,
  SAVE_HOME_CALCULATOR_ADDRESS,
} from '../lib/actions';
import { sanitizeInputs } from '../components/inputs/sanitize';
import calculate from '../lib/rentvsbuy-calculator';

export const UPDATE_CHART = 'UPDATE_CHART';

export function getInitialState() {
  const inputs = _.assign({}, defaultInputs);
  inputs.downPaymentPercent = inputs.downPayment / inputs.purchasePrice;
  return {
    inputs,
    manualInputs: {
      taxFilingStatus: defaultInputs.taxFilingStatus,
    },
    outputs: {},
    // Testing as a backup? Scenario is rent is so low we can't calculate a planned stay and the calc crashes
    plannedStay: 3,
    tooltip: {
      showTooltip: false,
      data: null,
    },
  };
}

function resolveCalculations(newState) {
  // For backup send 0 here so the calculator can provide us with good defaults.
  const purchaseState = _.get(newState, 'inputs.purchaseAddress.state');

  const propertyTaxRate = !newState.inputs.propertyTaxRate
    ? _.get(costModel, `${purchaseState}[statePropertyTax]`, 0.05)
    : newState.inputs.propertyTaxRate;

  _.assign(
    newState.outputs,
    calculate(_.assign(newState.inputs, { propertyTaxRate }))
  );
}

export default (state = getInitialState(), action = {}) => {
  let newState = state;

  if (_.isEmpty(state)) {
    return getInitialState();
  }

  switch (action.type) {
    case SIGN_IN: {
      newState = _.cloneDeep(state);

      const profile =
        _.get(action, 'payload.identity.profiles.home_calculator_profile') ||
        _.get(
          action,
          'payload.identity.visitorData.profiles.home_calculator_profile'
        );

      const formattedProfile = fromHomeCalculatorProfile(profile);
      const sanitizedInputs = sanitizeInputs(inputLibrary, formattedProfile);

      _.assign(formattedProfile, sanitizedInputs);

      const useDefaultIfNull = (defaultValue, profileValue) => {
        if (profileValue === null || profileValue === undefined) {
          return defaultValue;
        }
        return profileValue;
      };

      _.assignInWith(newState.inputs, formattedProfile, useDefaultIfNull);
      // Leave manual inputs blank so they use placeholder
      _.assign(newState.manualInputs, sanitizedInputs);
      newState.inputs.downPaymentPercent =
        newState.inputs.downPayment !== null &&
        newState.inputs.purchasePrice !== null
          ? _.round(
              newState.inputs.downPayment / newState.inputs.purchasePrice,
              3
            )
          : defaultInputs.downPaymentPercent;

      if (formattedProfile.purchaseAddress) {
        newState.manualInputs.purchaseAddress =
          formattedProfile.purchaseAddress;
      }

      resolveCalculations(newState);

      break;
    }

    case INPUT_CHANGE: {
      newState = _.cloneDeep(state);

      if (!(_.isEmpty(action.manualInputs) && _.isEmpty(action.inputs))) {
        newState.manualInputs = _.assign(
          {},
          newState.manualInputs,
          action.manualInputs,
          action.inputs
        );
        _.assign(newState.inputs, newState.manualInputs);
        // Duplicates the validation logic
        if (
          // Run calcs if valid percentage
          newState.manualInputs.downPaymentPercent &&
          newState.manualInputs.downPaymentPercent < 1.01
        ) {
          // Run calcs if down payment + purchase price are valid
          if (
            action.manualInputs.downPayment !== undefined ||
            (action.manualInputs.purchasePrice !== undefined &&
              state.inputs.purchasePrice > state.inputs.downPayment)
          ) {
            const downPaymentPercent =
              newState.inputs.downPayment / newState.inputs.purchasePrice;
            newState.inputs.downPaymentPercent = _.round(downPaymentPercent, 3);
            newState.manualInputs.downPaymentPercent = _.round(
              downPaymentPercent,
              3
            );
          } else if (
            // Run calcs if valid and user edited down payment percent
            action.manualInputs.downPaymentPercent !== undefined &&
            state.inputs.purchasePrice > state.inputs.downPayment
          ) {
            const downPayment =
              newState.inputs.purchasePrice *
              newState.inputs.downPaymentPercent;
            newState.inputs.downPayment = downPayment;
            newState.manualInputs.downPayment = downPayment;
          }
        } else if (!newState.manualInputs.downPaymentPercent) {
          // User has not edited downpaymentpercent but has edited down payment or purchase price
          if (newState.inputs.downPayment && newState.inputs.purchasePrice) {
            newState.inputs.downPaymentPercent = _.round(
              newState.inputs.downPayment / newState.inputs.purchasePrice,
              3
            );
          }
        }
      }
      break;
    }

    case RESOLVE_CALCULATIONS: {
      newState = _.cloneDeep(state);
      resolveCalculations(newState);
      newState.plannedStay =
        newState.plannedStay === null
          ? newState.outputs.breakevenYear
          : newState.plannedStay;

      break;
    }

    case SAVE_HOME_CALCULATOR_ADDRESS:
      newState = _.cloneDeep(state);
      if (!_.isEmpty(action.address)) {
        _.assign(newState.inputs.purchaseAddress, action.address);
      }
      break;

    case UPDATE_CHART: {
      newState = _.cloneDeep(state);

      if (action.xValue !== null && action.xValue !== undefined) {
        newState.plannedStay = action.xValue;
      }

      if (action.tooltip !== null && action.tooltip !== undefined) {
        newState.tooltip = action.tooltip;
      }

      break;
    }

    case UPDATE_DEFAULT_CITY: {
      const { city } = action;

      newState = _.cloneDeep(state);
      _.assign(newState.inputs.purchaseAddress, { city, state: action.state });

      break;
    }

    case UPDATE_DEFAULT_RATE: {
      if (action.annualInterestRates.fixed_30) {
        newState = _.cloneDeep(state);

        const { inputs } = newState;
        inputs.annualInterestRate = action.annualInterestRates.fixed_30 / 100;
        inputs.annualInterestRates = action.annualInterestRates;
      }
      break;
    }

    default:
      break;
  }

  return newState;
};

import { SET_APP_NAME } from '../lib/actions';

const defaultState = null;

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case SET_APP_NAME:
      return action.app;

    default:
      return state;
  }
};

import Input from '../../inputs/input';

import {
  annualInterestRate,
  city,
  closingCosts,
  downPayment,
  downPaymentPercent,
  loanTerm,
  monthlyDebt,
  homeownersInsurance,
  mortgageInsurance,
  HOADues,
  propertyTax,
  purchasePrice,
} from '../../../config/inputs';

import truncatedValidatorMessage from '../../../config/truncatedValidatorMessage';

// Overrides
const homeInsurance = new Input(homeownersInsurance);
const tax = new Input(propertyTax);
// For some reason Mortgage Calculator overrides the input name to hoa/mortgageInsurancePayment and consumes the value
// field from the reducer as hoa, so we need to override the default name, If not validation will fail
const hoa = new Input({ ...HOADues, name: 'hoa' });
const mortgageInsurancePayment = new Input({
  ...mortgageInsurance,
  name: 'mortgageInsurancePayment',
});
// Leaving this definition as there are still some scenarios that consume the old values
const baseHOADues = new Input(HOADues);
const baseMortgageInsurance = new Input(mortgageInsurance);

homeInsurance.validators = [truncatedValidatorMessage.bind(homeInsurance)];

tax.validators = [truncatedValidatorMessage.bind(propertyTax)];

hoa.validators = [truncatedValidatorMessage.bind(HOADues)];
baseHOADues.validators = [truncatedValidatorMessage.bind(HOADues)];

baseMortgageInsurance.validators = [
  truncatedValidatorMessage.bind(mortgageInsurance),
];
mortgageInsurancePayment.validators = [
  truncatedValidatorMessage.bind(mortgageInsurance),
];

const inputLibrary = {
  annualInterestRate: new Input(annualInterestRate),
  city: new Input(city),
  closingCosts: new Input(closingCosts),
  downPayment: new Input(downPayment),
  downPaymentPercent: new Input(downPaymentPercent),
  loanTerm: new Input(loanTerm),
  monthlyDebt: new Input(monthlyDebt),
  hoa,
  HOADues: baseHOADues,
  homeownersInsurance: homeInsurance,
  mortgageInsurancePayment,
  mortgageInsurance: baseMortgageInsurance,
  propertyTax: tax,
  purchasePrice: new Input(purchasePrice),
};

export default inputLibrary;

import _ from 'lodash';

// Actions
// eslint-disable-next-line import/no-cycle -- directive added automatically by Shepherd migration
import { INPUT_VALIDATION, RESET_VALIDATIONS } from '../lib/actions';

const defaultState = {
  valid: true,
};

export const hasValidationsToFix = (state) =>
  _.some(state.validations, (value) => value.length > 0);

export default (state = defaultState, action = {}) => {
  if (action.type === INPUT_VALIDATION) {
    const newState = _.cloneDeep(state);

    return {
      ...newState,
      ...action.validations,
      valid: action.valid,
    };
  }

  if (action.type === RESET_VALIDATIONS) {
    return _.assign(
      {},
      {
        valid: true,
      }
    );
  }
  return state;
};

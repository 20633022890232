/**
 * Random helper functions that do not have a good home.
 * @module helpers
 */

import {
  LOAN_PURPOSE_PURCHASE,
  LOAN_PROGRAM_FIXED_30,
  LOAN_PROGRAM_FIXED_20,
  LOAN_PROGRAM_FIXED_15,
  LOAN_PROGRAM_FIXED_10,
  LOAN_PROGRAM_ARM_7,
  LOAN_PROGRAM_ARM_5,
  LOAN_PROGRAM_ARM_3,
  LOAN_PROGRAM_ARM_10,
} from '../../config/inputs/data/constants';

import { creditScoreRange } from '../../config/inputs/data/ranges';

/**
 * Lookup object for human friendly loan program names. Keys are LOAN_PROGRAM constants
 * @see getLoanProgramName
 * @deprecated
 */
export const LOAN_PROGRAM_NAME = {
  [LOAN_PROGRAM_FIXED_30]: '30-year fixed',
  [LOAN_PROGRAM_FIXED_20]: '20-year fixed',
  [LOAN_PROGRAM_FIXED_15]: '15-year fixed',
  [LOAN_PROGRAM_FIXED_10]: '10-year fixed',
  [LOAN_PROGRAM_ARM_7]: '7-year ARM',
  [LOAN_PROGRAM_ARM_5]: '5-year ARM',
  [LOAN_PROGRAM_ARM_3]: '3-year ARM',
  [LOAN_PROGRAM_ARM_10]: '10-year ARM',
};

/**
 * Gets a human friendly description of a loan program
 * @example
 * import { LOAN_PROGRAM_FIXED_15, LOAN_PROGRAM_ARM_5 } from '../../config/constants';
 * import { getLoanProgramName } from '../../config/lib';
 *
 * getLoanProgramName(LOAN_PROGRAM_FIXED_15); // 15-year fixed
 * getLoanProgramName(LOAN_PROGRAM_ARM_5); // 5 ARM
 * @function
 * @param  {external:home-constants.LOAN_PROGRAM} loanProgram
 * @return {String}
 */
export const getLoanProgramName = (loanProgram: string): string =>
  LOAN_PROGRAM_NAME[loanProgram] ?? loanProgram;

/**
 * Is the purpose of this loan purchasing a home
 * @example
 * import { LOAN_PURPOSE_PURCHASE, LOAN_PURPOSE_REFINANCE } from '../../config/constants';
 * import { isPurchase } from '../../config/lib';
 *
 * isPurchase({ loanPurpose: LOAN_PURPOSE_PURCHASE }); // true
 * isPurchase({ loanPurpose: LOAN_PURPOSE_REFINANCE }); // false
 * @function
 * @param  {Object}  data
 * @param  {external:home-constants.LOAN_PURPOSE}  data.loanPurpose  The purpose of the loan, LOAN_PURPOSE_PURCHASE or LOAN_PURPOSE_REFINANCE
 * @return {Boolean}      true if Purchase, false if Refinance
 */
export const isPurchase = (data: any): boolean =>
  data.loanPurpose === LOAN_PURPOSE_PURCHASE;

/**
 * Get the loan amount.  This should only be used for approximating the loan amount, and never
 * for an actual mortgage offer, as offers may roll fees into the loan amount
 * @todo Potentially incorrect.  Where / why is this used?
 * @example // for Refi
 * import { LOAN_PURPOSE_REFINANCE } from '../../config/constants';
 * import { getLoanAmount } from '../../config/lib';
 *
 * getLoanAmount({
 *   loanPurpose: LOAN_PURPOSE_REFINANCE,
 *   mortgageBalance: 50,
 *   cashout: 10,
 * }); // 60
 *
 * getLoanAmount({
 *   loanPurpose: LOAN_PURPOSE_REFINANCE,
 *   mortgageBalance: 50,
 * }); // 50
 *
 * @example // for Purchase
 * import { LOAN_PURPOSE_PURCHASE } from '../../config/constants';
 * import { getLoanAmount } from '../../config/lib';
 *
 * getLoanAmount({
 *   loanPurpose: LOAN_PURPOSE_PURCHASE,
 *   purchasePrice: 50,
 *   downPayment: 10,
 * }); // 40
 * @function
 * @param  {Object} data
 * @param  {external:home-constants.LOAN_PURPOSE} data.loanPurpose  The purpose of the loan, LOAN_PURPOSE_PURCHASE or LOAN_PURPOSE_REFINANCE
 * @param  {Number} [data.purchasePrice]  Required if loanProgram=purchase. The purchase price of the property.
 * @param  {Number} [data.downPayment]  Required if loanProgram=purchase. The down payment for the loan
 * @param  {Number} [data.mortgageBalance]  Required if loanProgram=refinance. The user's current mortgage balance
 * @param  {Number} [data.cashout=0]  Required if loanProgram=refinance. The amount of cashout the user requested
 * @return {Number}      The amount of the loan
 */
export const getLoanAmount = (data: { [key: string]: number }): number => {
  if (isPurchase(data)) {
    return data.purchasePrice - data.downPayment;
  }
  return data.mortgageBalance + (data.cashout ?? 0);
};
/**
 * Print the credit score range for when you need to display the user's credit score but you only have a range value.
 * @example
 * import { getCreditScoreRange } from '../../lib/helpers';
 *
 * getCreditScoreRange(301); // 300 - 579
 * getCreditScoreRange(640); // 640 - 659
 * getCreditScoreRange(760); // 760+
 * getCreditScoreRange('test'); // ''
 * @function
 * @param  {Number}  creditScore
 * @return {String}  string representation of credit score range
 */

export const getCreditScoreRange = (creditScore: number): string => {
  for (let i = 0; i < creditScoreRange.length; i += 1) {
    const range = creditScoreRange[i];
    if (!range[1] && creditScore >= range[0]) {
      return `${range[0]}+`;
    }
    if (range[1] && creditScore <= range[1] && creditScore >= range[0]) {
      return `${range[0]} - ${range[1]}`;
    }
  }

  return '';
};

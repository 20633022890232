// Some of these inputs are very small so we need to jam in a smaller message.
// Ideally move this to home-app-lib

const truncatedValidatorMessage = (data, input) => {
  if (data[input.name] > input.maximum) {
    return {
      valid: false,
      message: 'Value too high',
    };
  }
  return {
    valid: true,
    message: '',
  };
};

export default truncatedValidatorMessage;

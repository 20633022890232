// eslint-disable-next-line lodash/import-scope -- directive added automatically by Shepherd migration
import { get, isEmpty } from 'lodash';

import { bootstrapAppWithPromises, updateLoanPurpose } from './actions';
import fetchLastMortgageRates from './actions/fetchLastMortgageRates';
import getLocation from './actions/get-location';
import inputChange from './actions/inputChange';

export const SAVE_QUERY = 'SAVE_QUERY';

export function saveQuery(query) {
  return {
    type: SAVE_QUERY,
    query,
  };
}

export function bootstrapRC(data) {
  return (dispatch) => {
    const promises = [];
    const inputs = get(data, 'update.inputs');

    dispatch(updateLoanPurpose('refinance'));
    dispatch(saveQuery(data.query));

    if (!isEmpty(inputs)) {
      dispatch(inputChange({ inputs }));
    }

    // no need to fetch rates if we are given one
    if (!inputs.refinanceInterestRate) {
      promises.push(
        new Promise((callback) => {
          dispatch(fetchLastMortgageRates({ callback }));
        })
      );
    }

    promises.push(dispatch(getLocation(data, inputs)));

    dispatch(
      bootstrapAppWithPromises({
        promises,
        ...data,
      })
    );
  };
}
